import React from 'react'
import Layout from '../../components/layout'
import TitleAndDownload from '../../components/ui/TitleAndDownload'
import FirstRow from './FirstRow'
import SecondRow from './SecondRow'
import ThirdRow from './ThirdRow'
import FourRow from './FourRow'

const FairsAndParties = () => {
    return (
        <Layout>
            <TitleAndDownload
                text={<><b>Ferias y fiestas</b></>}
                link='https://docs.google.com/spreadsheets/d/1jYYKUlF630oaZCiLkU2M0q6g-33a1z-4U8NuHgO0HJM/edit'
                titleButton='Consultar el calendario'
            />
            <FirstRow />
            <SecondRow />
            <ThirdRow />
            <FourRow />
        </Layout>
    )
}

export default FairsAndParties