import React from 'react'
import styled from 'styled-components'

const StyledCardContact = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    padding: 20px;
    background: #FDFDFD;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);

    img {
        max-width: 100%;
    }

    p {
        color: #35374A;
        text-align: center;
        margin: 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }
`

const CardContact = () => {
    return (
        <StyledCardContact>
            <div>
                <img src="/static/marketplace/estamos-trabajando.svg" alt="estamos-trabajando" />
            </div>
            <p>
                Estamos buscando <b>nuevos proveedores autorizados</b> para tus clientes.
            </p>
            <p>
                ¿Tienes alguna propuesta? Envíanos un email a <b>marketplace@grocasa.com</b>
            </p>
        </StyledCardContact>
    )
}

export default CardContact