import React from "react";

function LinkedinSvg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="none"
            viewBox="0 0 25 25"
        >
            <g fill="#35374A" fillOpacity="0.35" clipPath="url(#clip0_35_1157)">
                <path d="M12.514 0A12.505 12.505 0 000 12.514C0 19.432 5.595 25 12.514 25 19.432 25 25 19.405 25 12.514 25 5.623 19.405 0 12.514 0zm0 23.622c-6.12 0-11.136-4.99-11.136-11.108 0-6.12 4.99-11.136 11.136-11.136 6.146 0 11.108 4.99 11.108 11.136 0 6.146-4.99 11.108-11.108 11.108z"></path>
                <path d="M9.262 9.4l-2.426.006.023 7.8 2.426-.006-.023-7.8zM8.048 5.568c-.771 0-1.405.634-1.405 1.406 0 .771.634 1.405 1.405 1.405.772 0 1.406-.634 1.406-1.405 0-.772-.634-1.406-1.406-1.406zM15.435 9.234c-1.185 0-1.957.661-2.287 1.268h-.028V9.427h-2.315v7.8h2.453v-3.859c0-1.02.165-2.012 1.433-2.012 1.24 0 1.268 1.158 1.268 2.067v3.804h2.426v-4.272c0-2.095-.469-3.721-2.922-3.721h-.028z"></path>
            </g>
            <defs>
                <clipPath id="clip0_35_1157">
                    <path fill="#fff" d="M0 0H25V25H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default LinkedinSvg;
