import React from 'react'
//COMPONENTS
import Layout from '../../components/layout'
import Email from '../../components/svg/Email'
//STYLES
import { StyledContainer } from './styles'
//DATA
import { arrayContacts } from './data'

const ContactoSede = () => {
    return (
        <Layout>
            <StyledContainer>
                {
                    arrayContacts.map((card, key) => (
                        <div className='div-columns' key={key}>
                            <h2 dangerouslySetInnerHTML={{ __html: card.title }} />
                            <div className='div-contact'>
                                {
                                    card.contacts.map((contact, i) => (
                                        <div key={i}>
                                            <div>
                                                <h2><b>{contact.label}</b></h2>
                                            </div>
                                            <div>
                                                <a
                                                    href={`mailto:${contact.email}`}
                                                    className='link-email'
                                                >
                                                    <Email />
                                                    <h2>{contact.email}</h2>
                                                </a>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
            </StyledContainer>
        </Layout>
    )
}

export default ContactoSede