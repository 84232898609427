import React from 'react'
//COMPONENTS
import Layout from '../../components/layout'
import TitleAndDownload from '../../components/ui/TitleAndDownload'
//STYLES
import styled from "styled-components";

const StyledContainer = styled.div`    
    margin-top: 20px;
    img {
        max-width: 100%;
        width: 100%;
    }   
`

const TrainingPlan = () => {
    return (
        <Layout>
            <TitleAndDownload text={<>Plan de <b>Formación</b></>} link='/static/documents/plan-de-formacion.pdf' />
            <StyledContainer>
                <img src="/static/documents/training-plan.webp" alt='dress code' />
            </StyledContainer>
        </Layout>
    )
}

export default TrainingPlan