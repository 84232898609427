import React, { useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom";


//context
import { UseUser } from '../../hooks/context/user'

//components
import AlertError from "../../components/alert/AlertError"
import AlertSuccess from "../../components/alert/AlertSuccess"
import Loading from "../../components/loading"
import Form from './form'
import { messages } from "../../libs/helpers/message"

//styles
import {
  StyledContainer,
  StyledContent,
  StyledHeader,
} from "../login/styled";

export default function RecoveryPassword() {
  let location = useLocation();  

  const [user, setUser] = useState({ email: '' })
  const [loading, setLoading] = useState(false)
  const [sendEmailSuccess, setSendEmailSuccess] = useState(false)
  const [error, setError] = useState({ email: false })
  const [errorServer, setErrorServer] = useState(false)
  const [errorToken, setErrorToken] = useState((location.state && location.state.errorToken) ? location.state.errorToken : false)
  const path = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const { recoveryPassword } = UseUser()

  const handleOnchange = (e) => {
    const { value, name } = e.target
    setUser({ ...user, [name]: value })
  }

  const handleSendEmail = async (e) => {
    e.preventDefault()
    resetErrors()
    if (!path.test(user.email) || user.email === '') {
      setError({ ...error, email: true })
    } else {
      setLoading(true)
      const result = await recoveryPassword(user.email)
      if (!result) {
        setErrorServer(true)
      } else {
        setSendEmailSuccess(true)
      }
      setLoading(false)
    }
  }

  const resetErrors = () => {
    setSendEmailSuccess(false)
    setError({ email: false })
    setErrorServer(false)
    setErrorToken(false)
  }

  return (
    <StyledContainer>
      {loading && <Loading />}
      <StyledContent>
        <div className="login-media">
          <StyledHeader>
            <Link to="/">
              <img src="/static/Grocasa500.png" />
            </Link>
          </StyledHeader>
          {errorServer && <AlertError text={messages.ERROR_SERVER_LOGIN} />}
          {error.email && <AlertError text={messages.INCORRECT_FORMAT} />}
          {sendEmailSuccess && <AlertSuccess text={messages.SEND_EMAIL_PASSWORD_SUCCESS} />}
          {errorToken && <AlertError text={messages.TOKEN_INVALID} />}
          {
            !sendEmailSuccess &&
            <Form
              email={user.email}
              error={error.email}
              handleSubmit={handleSendEmail}
              handleOnchange={handleOnchange}
            />
          }
        </div>
      </StyledContent>
    </StyledContainer>
  );
}
