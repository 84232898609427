export const arrayContacts = [
    {
        title: 'Contactos <b>sede</b>',
        contacts: [
            {
                label: 'RRHH',
                email: 'rrhh@grocasa.com'
            },
            {
                label: 'Legal',
                email: 'legal@grocasa.com'
            },
            {
                label: 'Grotienda',
                email: 'compras@grocasa.com'
            },
            {
                label: 'CRM',
                email: 'crm@grocasa.com'
            },
            {
                label: 'Portales',
                email: 'portales@grocasa.com'
            },
            {
                label: 'Formación',
                email: 'formacion@grocasa.com'
            },
            {
                label: 'Mentoring',
                email: 'mentoring@grocasa.com'
            },
            {
                label: 'Atención cliente',
                email: 'atencioalcliente@grocasa.com'
            },
            {
                label: 'Comunicación',
                email: 'comunicacion@grocasa.com'
            },
            {
                label: 'Marketing y diseño',
                email: 'redmarketing@grocasa.com'
            },            
            {
                label: 'Imagen oficinas',
                email: 'imagenoficinas@grocasa.com'
            },
            {
                label: 'Recepción',
                email: 'info@grocasa.com'
            }
        ]
    },
    {
        title: 'Otros contactos de <b>interés</b>',
        contacts: [
            {
                label: 'Grocasa Alquileres',
                email: 'alquileres@grocasa.com'
            },
            {
                label: 'Grocasa Hipotecas',
                email: 'adminhipotecas@grocasa.com'
            },
            {
                label: 'Groinversia',
                email: 'rosa@groinversia.com'
            },
        ]
    }
]