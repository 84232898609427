import React, { useState } from 'react'
import classNames from "classnames";
//COMPONENTS
import Layout from '../../components/layout'
import TitleAndDownload from '../../components/ui/TitleAndDownload';
import Add from '../../components/svg/Add';
import Remove from '../../components/svg/Remove';
import PlatformBenefits from './components/PlatformBenefits';
import GroFriend from './components/GroFriend';
import CardCommon from './components/CardCommon';
//STYLES
import styled from "styled-components";
import PayRollSantander from './components/PayRollSantander';

const StyledContainer = styled.div`    
    display: flex;
    flex-direction: column;
    background: #FDFDFD;
    box-shadow: 0px 4px 4px 0px #0000001A;
    margin: 20px 0;

    h2 {        
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        margin: 0;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        border-bottom: 1px solid #00000012;
        cursor: pointer;
    }    

    .component {
        max-height: 0;
        overflow: hidden;    
        margin: 0;
        padding: 0;
        transition: max-height 0.1s ease-out;       

        &.active {
            max-height: 100%;            
            transition: max-height 1s ease-in;                    
        }        
    }
`

const DATA = [
    // {
    //     title: `El mejor <b>horario del sector</b>`,
    //     component: <Schedule />,
    //     active: false,
    // },
    {
        title: `Lidera <b>tu futuro profesional</b>`,
        component: <CardCommon
            background='/static/documents/benefits/career-plan.webp'
            backgroundTablet='/static/documents/benefits/career-plan-tablet.webp'
            backgroundMobile='/static/documents/benefits/career-plan-mobile.webp'
            child='careerPlan'
        />,
        active: false,
    },
    {
        title: `Aprende <b>un oficio en contínuo crecimiento</b>`,
        component: <CardCommon
            background='/static/documents/benefits/training-plan.webp'
            backgroundTablet='/static/documents/benefits/training-plan-tablet.webp'
            backgroundMobile='/static/documents/benefits/training-plan-mobile.webp'
            child='trainingPlan'
        />,
        active: false,
    },
    {
        title: `¿Te gusta <b>ahorrar y los descuentos exclusivos?</b>`,
        component: <PlatformBenefits />,
        active: false,
    },
    {
        title: `Disfruta de <b>momentos out of office</b> con tu equipo `,
        component: <CardCommon
            background='/static/documents/benefits/event-business.webp'
            backgroundTablet='/static/documents/benefits/event-business-tablet.webp'
            backgroundMobile='/static/documents/benefits/event-business-mobile.webp'
            child='eventsBusiness'
        />,
        active: false,
    },
    {
        title: `Incubadora de <b>emprendimiento</b>`,
        component: <CardCommon
            background='/static/documents/benefits/entrepreneurship.webp'
            backgroundTablet='/static/documents/benefits/entrepreneurship-tablet.webp'
            backgroundMobile='/static/documents/benefits/entrepreneurship-mobile.webp'
            child='entrepreneurship'
        />,
        active: false,
    },
    {
        title: `¿Quieres <b>comprar una vivienda</b>&#63;`,
        component: <CardCommon
            background='/static/documents/benefits/buy-housing.webp'
            backgroundTablet='/static/documents/benefits/buy-housing-tablet.webp'
            backgroundMobile='/static/documents/benefits/buy-housing-mobile.webp'
            child='buyHousing'
        />,
        active: false,
    },
    {
        title: `<b>El esfuerzo tiene recompensa </b>`,
        component: <CardCommon
            background='/static/documents/benefits/many-prizes.webp'
            backgroundTablet='/static/documents/benefits/many-prizes-tablet.webp'
            backgroundMobile='/static/documents/benefits/many-prizes-mobile.webp'
            child='manyPrizes'
        />,
        active: false,
    },
    {
        title: `<b>Gana dinero por trabajar con tus Groamigos</b>`,
        component: <GroFriend />,
        active: false,
    },
    {
        title: `<b>Gana ingresando tu n&oacute;mina en Banco Santander</b>`,
        component: <PayRollSantander />,
        active: false,
    },
    {
        title: `<b>Tu futuro financiero, nuestro compromiso de la mano del Deutsche Bank</b>`,
        component: <CardCommon
            background='/static/documents/benefits/deutsche-bank-desktop.webp'
            backgroundTablet='/static/documents/benefits/deutsche-bank-tablet.webp'
            backgroundMobile='/static/documents/benefits/deutsche-bank-mobile.webp'
            child='deutsheBank'
        />,
        active: false,
    },
    {
        title: `<b>Invierte</b> en el sector inmobiliario`,
        component: <CardCommon
            background='/static/documents/benefits/groinversia.webp'
            backgroundTablet='/static/documents/benefits/groinversia-tablet.webp'
            backgroundMobile='/static/documents/benefits/groinversia-mobile.webp'
            child='groinversia'
        />,
        active: false,
    },
    {
        title: `<b>Grosolidarios</b>`,
        component: <CardCommon
            background='/static/documents/benefits/red-cross.webp'
            backgroundTablet='/static/documents/benefits/red-cross-tablet.webp'
            backgroundMobile='/static/documents/benefits/red-cross-mobile.webp'
            child='grosolidarios'
        />,
        active: false,
    }
]

const Benefits = () => {
    const [data, setData] = useState(DATA)

    const openCollapse = (option) => {
        const newData = data.map((item, i) => {
            if (option === i) {
                return {
                    ...item,
                    active: !item.active
                }
            }
            return item
        })
        setData(newData)
    }

    return (
        <Layout>
            <TitleAndDownload
                text={<span>Catálogo de <b>Beneficios</b></span>}
                link='/static/documents/beneficios.pdf'
            />
            {
                data.map((item, i) => (
                    <StyledContainer key={i}>
                        <div className='header' onClick={() => openCollapse(i)}>
                            <h2 dangerouslySetInnerHTML={{ __html: item.title }} />
                            {
                                item.active ?
                                    <Remove /> :
                                    <Add />
                            }
                        </div>
                        <div className={classNames("component", { 'active': item.active })}>
                            {item.component}
                        </div>
                    </StyledContainer>
                ))
            }
        </Layout>
    )
}

export default Benefits