import React from 'react'
//COMPONENTS
import Layout from '../../components/layout'
import TitleAndDownload from '../../components/ui/TitleAndDownload'
//STYLES
import styled from "styled-components";

const StyledContainer = styled.div`    
    margin-top: 20px;
    img {
        max-width: 100%;
        width: 100%;
    }   
`

const CareerPlan = () => {
    return (
        <Layout>
            <TitleAndDownload text={<>Plan de <b>Carrera</b></>} link='/static/documents/plan-de-carrera.pdf' />
            <StyledContainer>
                <img src="/static/documents/career-plan.webp" alt='career plan' />
            </StyledContainer>
        </Layout>
    )
}

export default CareerPlan