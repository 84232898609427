import React from "react";
import { Search } from "@styled-icons/evil/Search";
import styled from "styled-components";

const StyledIcon = styled(Search)`
  width: 20px;
  height: 20px;  
`;

export default function SearchInput() {
    return <StyledIcon />;
}
