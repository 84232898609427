import React from "react";
import styled from "styled-components";
import FacebookSvg from "../svg/FacebookSvg";

const StyledLink = styled.a`
  text-decoration: none;
  margin: 0;
  padding: 0;
`;

export default function IcontFacebook({ color }) {
  return (
    <StyledLink
      href="https://www.facebook.com/GROCASA/"
      target="_blank"
      rel="nofollow"
      aria-label='facebook'
    >
      <FacebookSvg />
    </StyledLink>
  );
}
