import React from 'react';
import Button from '../../components/ui/button';
import { StyledContainer, StyledTextContent, StyledImgContent } from './styles'
import { useNavigate } from 'react-router-dom';

const ErrorServer = () => {
    let navigate = useNavigate();

    const goTo = () => navigate('/')

    return (
        <StyledContainer>
            <StyledTextContent>
                <h1>500</h1>
                <h3>Error de servidor interno</h3>
                <p>Algo salió mal. Por favor, inténtelo de nuevo más tarde.</p>
                <Button action={goTo}> Volver al inicio</Button>
            </StyledTextContent>
            <StyledImgContent>
                <img src="./static/rob.png" alt="notfound" />
            </StyledImgContent>
        </StyledContainer>
    );
};

export default ErrorServer;