import React, { useState } from 'react'

const ModalContext = React.createContext();

export function ModalProvider(props) {
    const [open, setOpen] = useState(false)
    const [component, setComponent] = useState(<></>)
  
    return (
        <ModalContext.Provider value={
            {
                open,
                setOpen,
                component,
                setComponent                
            }
        }
            {...props}
        />
    );
}

export function useModal() {
    const context = React.useContext(ModalContext)
    if (!context) {
        throw new Error('useModal debe estar dentro del proveedor ModalContext')
    }
    return context
}