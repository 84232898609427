export const DATA = {
  rrhh: {
    image: '/static/staff/rrhh/header-desktop.webp',
    information: {
      content: `
                <h2>RRHH; formando equipo</h2>
                <p>
                    Sabemos que nuestro equipo es una pieza clave para conseguir los objetivos empresariales y por ello es
                    imprescindible cuidar los procesos de contratación, fidelización y desarrollo de talento.
                </p>
                <p>
                    Para ayudarte a cumplir este objetivo, el equipo de RRHH se centra en 3 áreas:
                </p>
                <ul>
                    <li>
                        <b>Selección y contratación de personal:</b> Te ofrecerá la formación, los recursos y las
                        herramientas que necesitas para que tu oficina tenga el censo ideal (4 asesores) y trabajará
                        mano a mano contigo para conseguir que cada uno de ellos se integre en la empresa, y emprenda
                        un desarrollo profesional.
                    </li>
                    <li>
                        <b>Administración laboral:</b> Te asesorará en la gestión de los recursos humanos, en procesos de
                        alta/baja de empleados, interpretación del convenio colectivo y aspectos relacionados con la relación
                        laboral.
                    </li>
                    <li>
                        <b>Desarrollo profesional:</b> Con estrategias de fidelización del talento y reconocimiento del éxito,
                        el equipo de RRHH te ayudará a crear un equipo sólido y estable, que te ayudará a conseguir tus objetivos.
                    </li>
                </ul>
            `,
      contactTeam: {
        team: [
          {
            name: 'Thais Yáñez',
            position: 'Técnica RRHH',
            img: '/static/staff/rrhh/team/thais-yanez.webp'
          }
        ],
        contact: [
          {
            email: 'rrhh@grocasa.com'
          }
        ]
      }
    },
    documents: [
      {
        label: 'Manual Operativo 2024',
        value: 'manual-operativo-2024',
        active: true,
        data: [
          {
            label: 'RRHH Manual Operativo 2024',
            route: '/static/staff/rrhh/documents/operating-manual/rrhh-manual-operativo-2024.pdf',
            type: 'pdf'
          }
        ]
      },
      {
        label: 'Bienvenida',
        value: 'welcome',
        active: false,
        data: [
          {
            label: 'Guía incorporacion 2023',
            route: '/static/staff/rrhh/documents/welcome/guia-incorporacion-2023.pdf',
            type: 'pdf'
          },
          {
            label: 'Acuse de recibo material oficina',
            route: '/static/staff/rrhh/documents/welcome/acuse-de-recibo-material-oficina.pdf',
            type: 'pdf'
          },
          {
            label: 'Explicacion registro diario jornada',
            route: '/static/staff/rrhh/documents/welcome/explicacion-registro-diario-jornada.pdf',
            type: 'pdf'
          },
          {
            label: 'Normas Uso Empleados',
            route: '/static/staff/rrhh/documents/welcome/normas-uso-empleados.pdf',
            type: 'pdf'
          },
          {
            label: 'Prevencion Riesgos Laborales',
            route: '/static/staff/rrhh/documents/welcome/prevencion-riesgos-laborales.pdf',
            type: 'pdf'
          },
          {
            label: 'Protección de datos personales',
            route: '/static/staff/rrhh/documents/welcome/proteccion-de-datos-personalesempleados-franquicia.pdf',
            type: 'pdf'
          },
          {
            label: 'Revision medica',
            route: '/static/staff/rrhh/documents/welcome/revision-medica.pdf',
            type: 'pdf'
          }
        ]
      },
      {
        label: 'Relaciones laborales',
        value: 'labor-relations',
        active: false,
        data: [
          {
            label: 'Conceptos Laborales Basicos',
            route: '/static/staff/rrhh/documents/labor-relations/conceptos-laborales-basicos.pdf',
            type: 'pdf'
          },
          {
            label: 'Convenio Colectivo empresas de gestion y mediacion inmobiliaria',
            route: '/static/staff/rrhh/documents/labor-relations/convenio-colectivo-empresas-de-gestion-y-mediacion-inmobiliaria.pdf',
            type: 'pdf'
          },
          {
            label: 'Ley del Estatuto de los Trabajadores',
            route: '/static/staff/rrhh/documents/labor-relations/ley-del-estatuto-de-los-trabajadores.pdf',
            type: 'pdf'
          },
          {
            label: 'Plantilla supervision vacaciones 2024 oficinas',
            route: '/static/staff/rrhh/documents/labor-relations/plantilla-supervision-vacaciones-2024-oficinas.xlsx',
            type: 'excel'
          },
          {
            label: 'Solicitud tipo retencion superior IRPF',
            route: '/static/staff/rrhh/documents/labor-relations/solicitud-tipo-retencion-superior-irpf.pdf',
            type: 'pdf'
          }
        ]
      },
      {
        label: 'Selección de personal',
        value: 'staff-pick',
        active: false,
        data: [
          {
            label: 'Cuestionario de Seleccion',
            route: '/static/staff/rrhh/documents/staff-pick/cuestionario-de-seleccion.pdf',
            type: 'pdf'
          },
          {
            label: 'Job Description Coordinador-a',
            route: '/static/staff/rrhh/documents/staff-pick/job-description-coordinador-a.pdf',
            type: 'pdf'
          },
          {
            label: 'Modelos de ofertas para Infojobs',
            route: '/static/staff/rrhh/documents/staff-pick/modelos-de-ofertas-para-infojobs.pdf',
            type: 'pdf'
          },
          {
            label: 'Preguntas tipo para entrevistas',
            route: '/static/staff/rrhh/documents/staff-pick/preguntas-tipo-para-entrevistas.pdf',
            type: 'pdf'
          },
          {
            label: 'Publicacion ofertas INFOJOBS',
            route: '/static/staff/rrhh/documents/staff-pick/publicacion-ofertas-infojobs.pdf',
            type: 'pdf'
          },
          {
            label: 'Publicacion ofertas JOBTODAY',
            route: '/static/staff/rrhh/documents/staff-pick/publicacion-ofertas-jobtoday.pdf',
            type: 'pdf'
          }
        ]
      },
      {
        label: 'Documentos asesor',
        value: 'documentation',
        active: false,
        data: [
          {
            label: 'Cátalogo de beneficios',
            route: '/static/staff/rrhh/documents/documentation/catalogo-de-beneficios.pdf',
            type: 'pdf'
          },
          {
            label: 'Código de vestimenta',
            route: '/static/staff/rrhh/documents/documentation/codigo-de-vestimenta.pdf',
            type: 'pdf'
          },
          {
            label: 'Plan de carrera',
            route: '/static/documents/plan-de-carrera.pdf',
            type: 'pdf'
          },
          {
            label: 'Plan de formación 2023',
            route: '/static/staff/rrhh/documents/documentation/plan-de-formacion-2023.pdf',
            type: 'pdf'
          },
          {
            label: 'Plan de Honorarios 2023',
            route: '/static/staff/rrhh/documents/documentation/plan-de-honorarios-2023.pdf',
            type: 'pdf'
          }
        ]
      },
    ],
    faqs: [
      {
        question: '¿Cómo inicio un <b>proceso de selección?</b>',
        answer: `<img src='/static/staff/rrhh/faqs/images/selection-process.webp' alt='answer' />`,
        type: 'image',
        active: false
      },
      {
        question: '¿Cómo doy de <b>alta un nuevo asesor?</b>',
        answer: `<p>Deberás hacer una doble comunicación:</p>
                    <ul>
                        <li>
                            <b>ALTA PREVIA EN LA SEGURIDAD SOCIAL;</b> manda un mail a la gestoría antes de la fecha de alta 
                            informando de la nueva incorporación. Deberás comunicar nombre completo, adjuntar DNI/PASAPORTE 
                            escaneado ambas caras y Nº Seguridad Social. (Consulta Manual Operativo para las condiciones de contrato)
                        </li>
                        <li>
                            <b>ESCRIBE A RRHH;</b> El día de la incorporación, manda un mail a rrhh@grocasa.com informando 
                            de la nueva contratación. El Dpto. te solicitará una serie de documentación para crear su perfil 
                            en NOA y darle acceso a su correo electrónico
                        </li>
                    </ul>
                `,
        type: 'text',
        active: false
      },
      {
        question: '¿Cómo doy de <b>baja un asesor?</b>',
        answer: `<p>Deberás hacer una doble comunicación:</p>
                    <ul>
                        <li>
                            <b>BAJA EN LA SEGURIDAD SOCIAL;</b> manda un mail a la gestoría indicando fecha y tipo de baja (baja 
                            voluntaria, no superación periodo de prueba o despido). La gestoría te preguntará si hay 
                            que liquidar vacaciones o abonarle honorarios para preparar su liquidación.

                        </li>
                        <li>
                            <b>ESCRIBE A RRHH;</b>  El día de la baja, manda un mail a rrhh@grocasa.com informando de la baja 
                            del empleado, indicando tipo de baja. El Dpto. dará de baja su correo electrónico y eliminará su 
                            acceso a NOA
                        </li>
                    </ul>
                `,
        type: 'text',
        active: false
      },
      {
        question: '¿Cómo <b>acojo a un nuevo asesor</b> en el equipo?',
        answer: `<p>
                    <b>Accede a la GUIA DE INCORPORACIÓN y consulta todos los documentos que debes preparar para ayudar en la 
                    integración del nuevo asesor.</b> Será necesario que prepares, por un lado, toda la <b>documentación laboral</b>
                    que se requiere (PRL, Registro de jornada, LPD…) y por otro, la <b>documentación para la integración dentro 
                    de nuestra marca</b> (Manual de Conducta Interna, Código de vestimenta..,)
                </p>`,
        type: 'text',
        active: false
      },
      {
        question: '¿Puedo <b>contratar un asesor</b> que ha sido baja en otra oficina?',
        answer: `<p>
                    Cualquier persona que forma parte de un equipo (Asesores y Coordinadores) y causa BAJA VOLUNTARIA en una 
                    franquicia, no podrá ser contratada en otra oficina sin autorización previa de su anterior franquiciado 
                    (durante los 6 primeros meses posteriores a la baja). Si es baja por no superación de periodo de prueba 
                    o despido la norma no aplica.
                </p>
                <p>
                    Independientemente del tipo de baja, si el motivo del cese de contrato es un incumplimiento del código 
                    ético, esta persona no podrá volver a ser contratada en ninguna oficina.
                </p>`,
        type: 'text',
        active: false
      }
    ]
  },
  itcrm: {
    image: '/static/staff/itcrm/header-desktop.webp',
    information: {
      content: `
                <h2>IT; la revolución digital</h2>
                <p>
                    Nuestro departamento de IT mantiene y mejora Grocasa online, y te da apoyo y herramientas para gestionar 
                    mejor y más fácilmente las relaciones con los clientes.
                </p>
                <p>
                    Entre otros proyectos, este Dpto. te prestará apoyo con las siguientes herramientas:
                </p>
                <ul>
                    <li>
                        <b>Web Grocasa:</b> Mantenimiento y mejora continua de su posicionamiento.
                    </li>
                    <li>
                        <b>CRM y APP DE ZONA:</b> Tienes a tu disposición Salesforce, el software más valorado para gestionar 
                        las relaciones con los clientes. Nuestro Dpto. de Formación, de la mano del equipo IT formará a 
                        tu equipo para sacarle el máximo rendimiento a la herramienta.
                    </li>
                    <li>
                        <b>PAD:</b> Esta herramienta te permite controlar la facturación de tu oficina.
                    </li>
                    <li>
                        <b>INTRANET:</b> En colaboración con el Dpto. de Comunicación, el desarrollo de esta herramienta es 
                        clave para la comunidad Grocasa.
                    </li>
                </ul>
            `,
      contactTeam: {
        team: [
          {
            name: 'Mustapha Koumach',
            position: 'Director de IT',
            img: '/static/staff/itcrm/team/mustapha.webp'
          },
          {
            name: 'Sergio Delgado',
            position: 'CRM Manager',
            img: '/static/staff/itcrm/team/sergio-delgado.webp'
          },
          {
            name: 'Pol Lozano',
            position: 'Soporte CRM',
            img: '/static/staff/itcrm/team/pol-lozano.webp'
          }
        ],
        contact: [
          {
            email: 'crm@grocasa.com'
          }
        ]
      }
    },
    documents: [
      {
        label: 'Impresora',
        value: 'impresora',
        active: true,
        data: [
          {
            label: 'Actualizar drivers de impresora en Windows',
            route: '/static/staff/itcrm/documents/printers/actualizar-drivers-de-impresora-en-windows.pdf',
            type: 'pdf'
          },
          {
            label: 'Añadir una impresora en Mac',
            route: '/static/staff/itcrm/documents/printers/anadir-una-impresora-en-mac.pdf',
            type: 'pdf'
          },
          {
            label: 'Añadir una impresora en Windows',
            route: '/static/staff/itcrm/documents/printers/anadir-una-impresora-en-windows.pdf',
            type: 'pdf'
          }
        ]
      },
    ],
    faqs: [
      {
        question: '¿Cómo mantengo <b>actualizados los datos</b> de la web de mi punto de venta?',
        answer: `<p>
                    Puedes mandar un mail a <a href='mailto:redmarketing@grocasa.com'>redmarketing@grocasa.com</a> con los datos para que ellos gestionen la actualización. 
                    Si existe una incidencia, se resolverá por la misma vía.
                </p>`,
        type: 'text',
        active: false
      },
      {
        question: '¿Cómo reporto una <b>incidencia del CRM?</b>',
        answer: `<p>Mándanos un mail al equipo de CRM para solucionarla; <a href='mailto:crm@grocasa.com'>crm@grocasa.com</a></p>`,
        type: 'text',
        active: false
      },
      {
        question: '¿Cómo gestiono una <b>incidencia en la APP de Zona?</b>',
        answer: `<p>Mándanos un mail a <a href='mailto:zona@grocasa.com'>zona@grocasa.com</a></p>`,
        type: 'text',
        active: false
      }
    ]
  },
  marketingDesign: {
    image: '/static/staff/marketing-design/header-desktop.webp',
    information: {
      content: `
                <h2>Marketing y diseño</h2>
                <p>
                    El departamento de Marketing y diseño desempeña un papel esencial en la construcción y el éxito de cada 
                    punto de venta. Ayuda a promover la marca, generar demanda, conectar con los clientes, tomar decisiones 
                    basadas en datos y gestión de nuestra reputación online y offline, lo que se traduce directamente en el 
                    crecimiento y la rentabilidad a largo plazo de la empresa.
                </p>
                <p>
                    Algunos de los servicios que ofrecemos a nuestra red de franquicias inmobiliarias son los siguientes:
                </p>
                <ul>
                    <li>
                        <b>Visibilidad digital:</b> Nos encargamos de maximizar la visibilidad de cada oficina en medios 
                        digitales, incluyendo la página web de Grocasa. Cada oficina tiene su propia landing page, donde 
                        se muestran todos los datos de contacto. Esto facilitará que los clientes potenciales se contacten 
                        de manera rápida y sencilla.
                    </li>
                    <li>
                        <b>Creando comunidad:</b> Creamos una comunidad sólida en redes sociales. Compartimos publicaciones 
                        relacionadas con el día a día de las oficinas, así como datos de interés para vendedores y compradores. 
                        Esto nos permite establecer una conexión más personal con nuestro público objetivo y fortalecer el 
                        vínculo con ellos.
                    </li>
                    <li>
                        <b>Campañas de captación:</b> Gestionamos estrategias y actividades diseñadas específicamente para 
                        atraer y obtener nuevos clientes no solo online sino también offline.
                    </li>         
                    <li>
                        <b>Análisis de resultados:</b> Medimos y analizamos los resultados obtenidos de las campañas de 
                        marketing, esto implica recopilar datos relevantes (KPIS), como ventas generadas, tráfico web, 
                        interacciones en RRSS y otros indicadores clave de rendimiento. Por ello es importante que la 
                        información subida al NOA sea exacta y verídica. 
                    </li>           
                    <li>
                        <b>Gestión de reseñas:</b> Las opiniones de los clientes pueden influir en su decisión de trabajar con nosotros o no. 
                        El equipo de marketing se encarga de crear y administrar nuestra ficha en Google My Business, ofreciendo una atención 
                        personalizada y respondiendo a cada una de las reseñas.
                    </li>           
                    <li>
                        <b>Grotienda:</b> E-commerce de Grocasa que brinda a nuestros franquiciados acceso a una variedad de 
                        productos diseñados para mantener y fortalecer nuestra imagen corporativa, así como para facilitar las 
                        herramientas de venta necesarias.
                    </li>           
                    <li>
                        <b>Revista de Grocasa:</b> Es la revista que se publica mensualmente y se convierte en una puerta de 
                        entrada hacia nuestros clientes. En ella, se encuentran los inmuebles en cartera, noticias del sector 
                        y los gropasatiempos. La revista es una herramienta valiosa para atraer a nuevos clientes. 
                    </li>           
                    <li>
                        <b>Posicionamiento en portales inmobiliarios:</b> Nos encargamos de destacar los inmuebles en los mejores portales 
                        inmobiliarios, optimizando las búsquedas y aumentando la visibilidad de los inmuebles captados. Esto nos permite 
                        alcanzar a un público más amplio de compradores potenciales, generando mayor demanda y oportunidades de venta.
                    </li>           
                    <li>
                        <b>Imagen punto de venta:</b> Cumpliendo los estándares de calidad de la franquicia, te ayudamos a 
                        construir una imagen sólida y profesional en tu oficina Esto será fundamental para transmitir confianza 
                        a los clientes, diferenciarte de la competencia, atraer y fidelizar clientes y establecer una reputación 
                        sólida en el mercado inmobiliario.
                    </li>           
                </ul>
            `,
      contactTeam: {
        team: [
          {
            name: 'Ruth Carreño',
            position: 'Marketing Assistant',
            img: '/static/staff/marketing-design/team/ruth-carreno.webp'
          },
          {
            name: 'Mar Portoles',
            position: 'Comunicación corporativa',
            img: '/static/staff/marketing-design/team/mar-portales.webp'
          },
          {
            name: 'Iune Moya',
            position: 'Diseñadora gráfica',
            img: '/static/staff/marketing-design/team/iune-moya.webp'
          },
          {
            name: 'Guillem Pujol',
            position: 'Diseñador gráfico',
            img: '/static/staff/marketing-design/team/guillem-pujol.webp'
          }
        ],
        contact: [
          {
            email: 'redmarketing@grocasa.com'
          }
        ]
      }
    },
    faqs: [
      {
        question: '¿Cómo accedo a la <b>grotienda?</b>',
        answer: `<p>VIDEO</p>`,
        type: 'text',
        active: false
      },
      {
        question: '¿Cómo hago una <b>solicitud de material gráfico?</b>',
        answer: `<p>Para solicitar materiales gráficos, deberás enviar un correo a <a href='mailto:redmarketing@grocasa.com'>redmarketing@grocasa.com</a>
                con los siguientes datos:</p>
                <ol>
                    <li>Tipo de material gráfico.</li>
                    <li>Plataforma específica a la que se dirigirá (whatsapp, facebook, etc)</li>
                    <li>Fecha máxima de entrega.</li>
                </ol>
                <p>Ten en cuenta que los materiales gráficos se basarán en nuestras campañas actuales.</p>`,
        type: 'text',
        active: false
      },
      {
        question: '¿Cómo <b>recibo los leads</b> de las campañas de captación?',
        answer: `<p>
                    Recibirás estos leads a través de correo electrónico o teléfono desde 
                    <a href='mailto: info@grocasa.com'>info@grocasa.com</a> el mismo día o máximo al día siguiente de su recepción.
                </p>`,
        type: 'text',
        active: false
      },
      {
        question: '¿Cómo procedo para que mi oficina aparezca en la <b>revista grocasa?</b>',
        answer: `<p>
                    EN PROCESO. Mándanos un mail a <a href='mailto:redmarketing@grocasa.com'>redmarketing@grocasa.com</a> para atender tu solicitud.
                </p>`,
        type: 'text',
        active: false
      },
      {
        question: '¿Cómo se <b>gestionan las reseñas</b> en Google MyBusiness?',
        answer: `<p>
                    Desde el Dpto. de Marketing nos encargamos de tu visibilidad en Google My Business y el área de 
                    comunicación te dará el soporte necesario para dar respuesta a tus reseñas garantizando la mejor 
                    imagen de marca según los estándares de Grocasa. Si tienes alguna duda o sugerencia sobre alguna 
                    reseña, envía un correo a <a href='mailto:redmarketing@grocasa.com'>redmarketing@grocasa.com</a>.
                </p>`,
        type: 'text',
        active: false
      },
      {
        question: '¿Cómo accedo a los <b>productos de posicionamiento en portales inmobiliarios?</b>',
        answer: `<p>
                    Los inmuebles se suben automáticamente a los portales inmobiliarios cuando son ingresados al NOA. 
                    Si deseas destacar un inmueble, deberás escribir un correo a <a href='mailto:portales@grocasa.com'>portales@grocasa.com</a>. Recuerda que 
                    solicitar un destacado tiene un coste adicional. Sin embargo, esta opción aumentará las oportunidades 
                    de captación de compradores y acelerará el proceso de venta del inmueble. Puedes revisar el catálogo 
                    de portales inmobiliarios en el apartado de documentos de esta página.
                </p>`,
        type: 'text',
        active: false
      },
      {
        question: '¿Cómo gestiono <b>el escaparate de mi oficina?</b>',
        answer: `/static/staff/marketing-design/tutorial_scaparates.mp4`,
        type: 'video',
        active: false
      },
      {
        question: '¿Cómo accedo al <b>contenido para publicar en redes sociales?</b>',
        answer: `<p>
                    Dentro de MIS HERRAMIENTAS del menú principal de la Intranet, encuentra el contenido necesario, 
                    homologado por la marca, según el objetivo que quieras transmitir en tus redes.
                </p>`,
        type: 'text',
        active: false
      },
      {
        question: '¿Puedo crear <b>contenido y/o material propio para mi oficina?</b>',
        answer: `<p>
                    Si has accedido a la Grotienda y no encuentras el producto o servicio que buscabas, puedes mandarnos 
                    un mail a <a href='mailto: redmarketing@grocasa.com'>redmarketing@grocasa.com</a> para hacernos llegar 
                    tu solicitud y valorar su implantación. Recuerda que NO puedes generar contenido/material no homologado 
                    por la marca ya que no puedes disponer de nuestros identificativos corporativos (logo, imagen de marca) 
                    sin previa autorización de Grocasa.
                </p>`,
        type: 'text',
        active: false
      },
      {
        question: 'Quiero participar en una <b>feria en mi población o hacer una campaña en radio, prensa, etc. ¿Cómo debo acceder?</b>',
        answer: `<p>
                    Recuerda que el uso de nuestra marca siempre debe estar homologado por Grocasa. En ambos casos, 
                    escribe a <a href='mailto: redmarketing@grocasa.com'>redmarketing@grocasa.com</a> para que podamos ofrecerte el mayor apoyo posible y así alcanzar 
                    la máxima rentabilidad de tu acción.
                </p>`,
        type: 'text',
        active: false
      }
    ]
  },
  formation: {
    image: '/static/staff/formation/header-desktop.webp',
    information: {
      content: `
                <h2>Formación</h2>
                <p>
                    En Grocasa, nuestra red se fortalece con formación personalizada en cada etapa de la carrera inmobiliaria. 
                    Nuestro equipo de expertos te guiará hacia el éxito. ¡Únete ahora y alcanza tus metas!
                </p>                
                <ul>
                    <li>
                        <b>Rookie:</b> 32 horas formativas orientadas a introducir a los asesores en el método Grocasa y en 
                        el sector inmobiliario
                    </li>
                    <li>
                        <b>Trainee:</b> 24 horas formativas enfocadas a consolidar conocimientos
                    </li>
                    <li>
                        <b>Expert:</b> 24 horas formativas centradas en profesionalizar el rol del asesor inmobiliario.
                    </li>
                    <li>
                        <b>Franquiciado:</b> Plan de formación orientado a la gestión propiamente de negocio y el crecimiento 
                        empresarial en el punto de venta.
                    </li>
                </ul>
            `,
      contactTeam: {
        team: [
          {
            name: 'Santiago Malagrida',
            position: 'Director de formación y mentoring',
            img: '/static/staff/formation/team/santiago-malagrida.webp'
          },
          {
            name: 'Víctor Blanco',
            position: 'Formador Inmobiliario',
            img: '/static/staff/formation/team/victor-blanco.webp'
          }
        ],
        contact: [
          {
            email: 'formacion@grocasa.com'
          }
        ]
      }
    },
    documents: [
      {
        label: 'APA’s Rookie',
        value: 'apa-s-rookie',
        active: true,
        data: [
          {
            label: 'APA 1 Captación',
            route: '/static/staff/formation/documents/apa-s-rookie/apa-1-captacion.pdf',
            type: 'pdf'
          },
          {
            label: 'APA 2 Glosario',
            route: '/static/staff/formation/documents/apa-s-rookie/apa-2-glosario.pdf',
            type: 'pdf'
          },
          {
            label: 'APA 3 Desarollo',
            route: '/static/staff/formation/documents/apa-s-rookie/apa-3-desarollo.pdf',
            type: 'pdf'
          },
          {
            label: 'APA 4 La llamada',
            route: '/static/staff/formation/documents/apa-s-rookie/apa-4-la-llamada.pdf',
            type: 'pdf'
          },
          {
            label: 'APA 5 Legal y fiscal',
            route: '/static/staff/formation/documents/apa-s-rookie/apa-5-legal-y-fiscal.pdf',
            type: 'pdf'
          },
          {
            label: 'APA 6 Escuchar y empatizar',
            route: '/static/staff/formation/documents/apa-s-rookie/apa-6-escuchar-y-empatizar.pdf',
            type: 'pdf'
          },
          {
            label: 'APA 7 Medición y adquisición',
            route: '/static/staff/formation/documents/apa-s-rookie/apa-7-medicion-y-adquisicion.pdf',
            type: 'pdf'
          },
          {
            label: 'APA 8 Portfolio',
            route: '/static/staff/formation/documents/apa-s-rookie/apa-8-portfolio.pdf',
            type: 'pdf'
          },
          {
            label: 'APA 9 El encargo de venta',
            route: '/static/staff/formation/documents/apa-s-rookie/apa-9-el-encargo-de-venta.pdf',
            type: 'pdf'
          },
          {
            label: 'APA 10 Gestión',
            route: '/static/staff/formation/documents/apa-s-rookie/apa-10-gestion.pdf',
            type: 'pdf'
          },
          {
            label: 'APA 11 La Venta',
            route: '/static/staff/formation/documents/apa-s-rookie/apa-11-la-venta.pdf',
            type: 'pdf'
          },
          {
            label: 'APA 12 La cita de gestion',
            route: '/static/staff/formation/documents/apa-s-rookie/apa-12-la-cita-de-gestion.pdf',
            type: 'pdf'
          },
          {
            label: 'APA 13 Seguimiento hasta la firma',
            route: '/static/staff/formation/documents/apa-s-rookie/apa-13-seguimiento-hasta-la-firma.pdf',
            type: 'pdf'
          },
          {
            label: 'APA 14 Como mejorar nuestras llamadas de captacion',
            route: '/static/staff/formation/documents/apa-s-rookie/apa-14-como-mejorar-nuestras-llamadas-de-captacion.pdf',
            type: 'pdf'
          },
          {
            label: 'APA 15 Objeciones en la llamada',
            route: '/static/staff/formation/documents/apa-s-rookie/apa-15-objeciones-en-la-llamada.pdf',
            type: 'pdf'
          },
          {
            label: 'APA 16 Objeciones y cierres en la Gestión',
            route: '/static/staff/formation/documents/apa-s-rookie/apa-16-objeciones-y-cierres-en-la-gestion.pdf',
            type: 'pdf'
          },
          {
            label: 'APA 17 Objeciones y cierres Venta',
            route: '/static/staff/formation/documents/apa-s-rookie/apa-17-objeciones-y-cierres-venta.pdf',
            type: 'pdf'
          },
          {
            label: 'APA 18 Speech Puerta Fría',
            route: '/static/staff/formation/documents/apa-s-rookie/apa-18-speech-puerta-fria.pdf',
            type: 'pdf'
          },
          {
            label: 'APA 19 Publicar y enseñar el inmueble',
            route: '/static/staff/formation/documents/apa-s-rookie/apa-19-publicar-y-ensenar-el-inmueble.pdf',
            type: 'pdf'
          },
          {
            label: 'APA 20 Documentos e impuestos a tener en cuenta antes de la firma',
            route: '/static/staff/formation/documents/apa-s-rookie/apa-20-documentos-e-impuestos-a-tener-en-cuenta-antes-de-la-firma.pdf',
            type: 'pdf'
          }
        ]
      },
    ],
    faqs: [
      {
        question: '¿Cómo <b>inscribo a mis asesores</b> en el calendario formativo?',
        answer: `<p>
                    Desde el Dpto. de formación se envía a los franquiciados convocatorias de cursos junto el temario 
                    detallado. La forma de inscripción será contestar este correo electrónico con los datos del asesor 
                    que debe acudir a dicha formación.
                </p>`,
        type: 'text',
        active: false
      },
      {
        question: '¿Cómo puedo <b>saber qué cursos</b> han hecho mis asesores/as?',
        answer: `<p>
                    Debes enviar un mail a <a href='mailto:formacion@grocasa.com'>formacion@grocasa.com</a> y el Dpto. te enviará la información solicitada.
                </p>`,
        type: 'text',
        active: false
      },
    ]
  },
  mentoring: {
    image: '/static/staff/mentoring/header-desktop.webp',
    information: {
      content: `
                <h2>Mentoring</h2>
                <p>
                    Nuestro compromiso es apoyar a nuestros franquiciados para que alcancen su máximo potencial y no dejen 
                    de crecer. A través de un enfoque personalizado, brindamos orientación y asesoramiento continuo para 
                    asegurar que nunca bajen la guardia. Puedes contratar este servicio para tener asignado un Mentor en 
                    tu punto de venta centrado en aumentar tu facturación supliendo las debilidades concretas de tu oficina.
                </p>
                <p>
                    ¡Juntos, impulsaremos tu carrera a nuevas alturas!
                </p>                
            `,
      contactTeam: {
        team: [
          {
            name: 'Santiago Malagrida',
            position: 'Director de formación y mentoring',
            img: '/static/staff/formation/team/santiago-malagrida.webp'
          },
          {
            name: 'Sergio Gutiérrez',
            position: 'Mentor Inmobiliario',
            img: '/static/staff/formation/team/sergio-g.webp'
          }
        ],
        contact: [
          {
            email: 'mentoring@grocasa.com'
          }
        ]
      }
    },
    faqs: [
      {
        question: '¿Cómo <b>contratar el servicio de mentoring y tarifas?</b>',
        answer: `<p>
                    Para contratar el servicio debes enviar un mail a <a href='mailto:mentoring@grocasa.com'>mentoring@grocasa.com</a> y nos pondremos en contacto 
                    contigo para buscar la mejor opción y así poder ayudarte de la mejor manera posible.
                </p>`,
        type: 'text',
        active: false
      },
    ]
  },
  officeImage: {
    image: '/static/staff/office-image/header-desktop.webp',
    information: {
      content: `
                <h2>Imagen de oficinas</h2>
                <p>
                    El principal objetivo del departamento de diseño de oficinas es maximizar la eficiencia y la productividad 
                    de tu punto de venta, al tiempo que crearemos un entorno agradable, tanto para ti y tu equipo sin olvidarnos 
                    de tus clientes, mediante los criterios de imagen estipulados.
                </p>
                <p>
                    Para conseguirlo realizaremos:
                </p>
                <ul>
                    <li>
                        <b>Análisis de requisitos:</b> Comprender tus necesidades, metas y preferencias. También realizaremos 
                        un análisis del espacio físico disponible y las restricciones existentes.
                    </li>
                    <li>
                        <b>Planificación del espacio:</b> Con base a toda la información que nos facilites crearemos un plan 
                        detallado que optimizará la distribución del espacio. Esto implicara determinar la ubicación de las 
                        áreas de trabajo, salas de reuniones y otros elementos necesarios.
                    </li>
                    <li>
                        <b>Diseño interior:</b> Utilizaremos los materiales, colores, mobiliario y accesorios que están 
                        estipulados por GROCASA. Esto incluye la selección de sistemas de iluminación, revestimientos de 
                        suelos, pintura, cortinas, muebles y otros elementos decorativos. El objetivo es crearte un entorno 
                        atractivo, funcional y coherente con la identidad y la cultura de la empresa.
                    </li>
                    <li>
                        <b>Ergonomía y confort:</b> Se ha tenido en cuenta en la selección de mobiliario la ergonomía y 
                        calidad para asegurar la comodidad de tus empleados y clientes. Considerando los aspectos como la 
                        altura de los escritorios, las sillas ergonómicas, la disposición de éstos y la iluminación adecuada.
                    </li>
                    <li>
                        <b>Optimización del flujo de trabajo:</b> Te aseguraremos que el diseño de tu oficina facilite la circulación 
                        eficiente de las personas y el flujo de trabajo. Consideraremos la ubicación de tus diferentes áreas de trabajo, 
                        así como las necesidades de colaboración y comunicación de tus empleados y clientes.
                    </li>
                    <li>
                        <b>Presupuesto y gestión del proyecto:</b> El departamento colabora estrechamente con los contratistas, 
                        proveedores y otros profesionales involucrados para asegurar que tu proyecto se ajuste a la imagen de 
                        marca y se complete dentro de los plazos establecidos. Supervisaremos la ejecución del diseño y 
                        realizaremos ajustes si es necesario.
                    </li>
                </ul>
            `,
      contactTeam: {
        team: [
          {
            name: 'Laia Torres',
            position: 'Responsable Imagen Oficinas'
          }
        ],
        contact: [
          {
            email: 'aperturas@grocasa.com'
          }
        ]
      }
    },
    faqs: [
      {
        question: '¿Puedo <b>comprar otros muebles?</b>',
        answer: `<p>
                    No, todas las franquicias han de tener los mismos muebles con el proveedor establecido por la imagen 
                    de marca, en nuestro caso se trata de Experline .
                </p>`,
        type: 'text',
        active: false
      },
      {
        question: '¿Puedo <b>poner otro suelo?</b>',
        answer: `<p>
                    Hay dos modelos de suelo, el cerámico combinando tres tipos diferente de un coste mas elevado y la 
                    segunda opción es parquet de la marca finfloor color roble Cartago y rodapié de color blanco
                </p>`,
        type: 'text',
        active: false
      },
      {
        question: '¿Dónde se <b>compra el corpóreo?</b>',
        answer: `<p>
                    En la grotienda dispones tanto del revestimiento de madera como las letras corpóreas
                </p>`,
        type: 'text',
        active: false
      },
      {
        question: '¿Que <b>tardan en traer los muebles?</b>',
        answer: `<p>
                    6 semanas desde la realización del primer pago
                </p>`,
        type: 'text',
        active: false
      },
      {
        question: '¿Que <b>tardan en instalar el rótulo?</b>',
        answer: `<p>
                    Unos 15 días desde la realización del primer pago.
                </p>`,
        type: 'text',
        active: false
      },
      {
        question: 'Si quiero <b>cambiar vinilos</b> ¿los compro en la Grotienda?',
        answer: `<p>
                    Únicamente se compra en la grotienda los vinilos de campañas y la puntuación de Google El resto te 
                    has de poner en contacto con el departamento de imagen de oficinas para realizar un proyecto.
                </p>`,
        type: 'text',
        active: false
      },
      {
        question: '¿Puedo <b>realizar la reforma con otro reformista?</b>',
        answer: `<p>
                    Si, siempre y cuando sigan el proyecto y la línea de marca siempre con el seguimiento del departamento 
                    de imagen de oficinas
                </p>`,
        type: 'text',
        active: false
      }
    ]
  },
  legal: {
    image: '/static/staff/legal/header-desktop.webp',
    information: {
      content: `
                <h2>Departamento legal:<br />Camina siempre sobre un terreno seguro</h2>
                <p>
                    El Departamento legal se presenta frente a sus franquiciados como un apoyo constante en el desarrollo de la 
                    actividad inmobiliaria, garantizando que ésta se ajuste a la normativa estatal y autonómica propia del sector. 
                    En este sentido proporcionamos un asesoramiento en el desempeño de las gestiones a realizar por el franquiciado 
                    siempre velando porque ésta sea ajustada a derecho.
                </p>
                <p>
                    Su actividad se centra en el asesoramiento relativo a las operaciones inmobiliarias, tanto desde el punto 
                    de vista de los aspectos legales que puedan surgir en la parte del comprador como en la del vendedor. 
                    Dicha labor incluye la visualización y redacción de documentos adaptados al caso concreto que sean 
                    consecuencia de los encargos, propuestas o compraventas.
                </p>
                <p>
                    Los servicios incluidos para el franquiciado por el departamento son los siguientes:
                </p>
                <ul>
                    <li>
                        <b>Formación:</b> De forma periódica se realizan cursos en materia legal específicos del sector 
                        inmobiliario para asesores y franquiciados. También, se imparten formaciones específicas sobre 
                        materias de actualidad. La formación legal del asesor es la mejor garantía para generar negocio seguro.
                    </li>
                    <li>
                        <b>Asistencia jurídica;</b> de forma específica, el Dpto. atiende a las consultas concretas de perfil 
                        jurídico que surgen de la actividad de las oficinas y de la relación con los clientes.
                    </li>
                    <li>
                        <b>Actualización de documentación contractual;</b> en constante evolución, el mundo jurídico exige 
                        la adaptación de la normativa y modulística al marco legal cambiante que altera los contratos marco 
                        que utilizan las oficinas.
                    </li>
                    <li>
                        <b>Presentación de demandas:</b> Con un coste adicional (CONSULTAR TARIFARIO)
                    </li>
                    <li>
                        <b>Demandas y reclamaciones a terceros:</b> Con un coste adicional (CONSULTAR TARIFARIO) 
                    </li>                    
                </ul>
            `,
      contactTeam: {
        team: [
          {
            name: 'Juan Antonio Cano',
            position: 'Senior Associate'
          },
          {
            name: 'Javier Rodríguez',
            position: 'Senior Associate',
          },
          {
            name: 'Ricardo Da Silva',
            position: 'Associate',
            img: '/static/staff/legal/team/ricardo-da-silva.webp'
          },
          {
            name: 'Nadia Cebamanos',
            position: 'Responsable Dpto. Extrajudicial'
          }
        ],
        contact: [
          {
            email: 'legal@grocasa.com'
          }
        ]
      }
    },
    faqs: [
      {
        question: '¿Cómo puedo <b>contactar con el Dpto. Legal?</b>',
        answer: `<p>
                    Tanto por vía telefónica 938 25 68 68 como vía mail <a href='mailto: legal@grocasa.com'>legal@grocasa.com</a>
                </p>`,
        type: 'text',
        active: false
      },
      {
        question: '¿Los <b>clientes externos pueden contratar</b> al Dpto. Legal?',
        answer: `<p>
                    Sí, consulta nuestro tarifario, en el apartado “DOCUMENTOS” de esta misma página.
                </p>`,
        type: 'text',
        active: false
      },
      {
        question: '¿Cuánto tardan en <b>resolver una duda planteada</b> al departamento?',
        answer: `<p>
                    Las consultas y peticiones tienen una respuesta ordinaria de máximo 24 horas, aunque dependiendo de la 
                    complejidad este plazo puede ampliarse.
                </p>`,
        type: 'text',
        active: false
      },
      {
        question: '¿La <b>redacción de contratos se incluye</b> dentro de la función de asesoramiento que ofrece el departamento a sus franquiciados?',
        answer: `<p>
                    La norma general es que los pequeños documentos consecuencia de los normativos no generan un coste 
                    adicional para el franquiciado, pero sí aquellos que determinen la adaptación o generación de uno 
                    nuevo que impliquen destinar un tiempo a su elaboración.
                </p>`,
        type: 'text',
        active: false
      },
      {
        question: '¿Cómo <b>accedo a los documentos normativos</b> (modulística)?',
        answer: `<p>
                    En el apartado “DOCUMENTOS” de esta misma página.
                </p>`,
        type: 'text',
        active: false
      },
      {
        question: '¿Se pueden <b>modificar los documentos normalizados?</b>',
        answer: `<p>
                    Por un tema de seguridad jurídica, los documentos sólo se modifican desde el Dpto. legal, pues la 
                    alteración de una simple “coma” puede cambiar antagónicamente el sentido de un contrato legal. Para 
                    proceder en este caso se envía un correo al departamento legal para que lo modifique adaptándolo a 
                    las necesidades de las partes.
                </p>`,
        type: 'text',
        active: false
      }
    ],
    documents: [
      {
        label: 'Tarifario',
        value: 'tarifario',
        active: true,
        data: [
          {
            label: 'Tarifario legal 2023',
            route: '/static/staff/legal/documents/tariff/tarifario-legal-2023.pdf',
            type: 'pdf'
          }
        ]
      },
      {
        label: 'Acuerdos de colaboración',
        value: 'acuerdos-de-colaboración',
        active: false,
        data: [
          {
            label: 'Acuerdo de colaboración franquicias',
            route: '/static/staff/legal/documents/collaboration-agreements/acuerdo-de-colaboración-franquicias.pdf',
            type: 'pdf'
          },
          {
            label: 'Formalización de colaboración',
            route: '/static/staff/legal/documents/collaboration-agreements/formalización-de-colaboración.pdf',
            type: 'pdf'
          },
          {
            label: 'Notificación de DEX',
            route: '/static/staff/legal/documents/collaboration-agreements/notificación-de-dex.pdf',
            type: 'pdf'
          },
          {
            label: 'Notificación de OPC',
            route: '/static/staff/legal/documents/collaboration-agreements/notificación-de-opc.pdf',
            type: 'pdf'
          }
        ]
      },
      {
        label: 'Modulística Cast',
        value: 'modulistica-cast',
        active: false,
        data: [
          {
            label: 'T1 - Encargo de venta exclusiva_FAMILY',
            route: '/static/staff/legal/documents/modulistica-cast/t1-encargo-de-venta-exclusivafamily.pdf',
            type: 'pdf'
          },
          {
            label: 'T2 - Encargo de venta_BASIC',
            route: '/static/staff/legal/documents/modulistica-cast/t2-encargo-de-ventabasic.pdf',
            type: 'pdf'
          },
          {
            label: 'T4 - Propuesta de compra',
            route: '/static/staff/legal/documents/modulistica-cast/t4-propuesta-de-compra.pdf',
            type: 'pdf'
          },
          {
            label: 'T5 - Contrato CV con arras sin condicionamiento',
            route: '/static/staff/legal/documents/modulistica-cast/t5-contrato-cv-con-arras-sin-condicionamiento.doc',
            type: 'word'
          },
          {
            label: 'T6 - Contrato de CV con arras condicionadas Grocasa Hipotecas',
            route: '/static/staff/legal/documents/modulistica-cast/t6-contrato-de-cv-con-arras-condicionadas-grocasa-hipotecas.doc',
            type: 'word'
          },
          {
            label: 'T6.b - Contrato de CV con arras confirmatorias condicionadas Grocasa Hipotecas',
            route: '/static/staff/legal/documents/modulistica-cast/t6b-contrato-de-cv-con-arras-confirmatorias-condicionadas-grocasa-hipotecas.docx',
            type: 'word'
          },
          {
            label: 'T9 - Hoja de visita',
            route: '/static/staff/legal/documents/modulistica-cast/t9-hoja-de-visita.pdf',
            type: 'pdf'
          },
          {
            label: 'T10 - ADDENDA',
            route: '/static/staff/legal/documents/modulistica-cast/t10-addenda.pdf',
            type: 'pdf'
          },
          {
            label: 'T11 - Rec. honorarios',
            route: '/static/staff/legal/documents/modulistica-cast/t11-rec-honorarios.pdf',
            type: 'pdf'
          }
        ]
      },
      {
        label: 'Modulística Cat',
        value: 'modulistica-cat',
        active: false,
        data: [
          {
            label: 'T1_CAT - Encarrec de venda exclusiva_FAMILY',
            route: '/static/staff/legal/documents/modulistica-cat/t1cat-encarrec-de-venda-exclusivafamily.pdf',
            type: 'pdf'
          },
          {
            label: 'T2_CAT - Encarrec de venda_BASIC',
            route: '/static/staff/legal/documents/modulistica-cat/t2cat-encarrec-de-vendabasic.pdf',
            type: 'pdf'
          },
          {
            label: 'T4_CAT - Proposta de compra',
            route: '/static/staff/legal/documents/modulistica-cat/t4cat-proposta-de-compra.pdf',
            type: 'pdf'
          },
          {
            label: 'T9_CAT - Full de visita',
            route: '/static/staff/legal/documents/modulistica-cat/t9cat-full-de-visita.pdf',
            type: 'pdf'
          },
          {
            label: 'T10_CAT - ADDENDA',
            route: '/static/staff/legal/documents/modulistica-cat/t10cat-addenda.pdf',
            type: 'pdf'
          },
          {
            label: 'T11_CAT - Rec. honoraris',
            route: '/static/staff/legal/documents/modulistica-cat/t11cat-rec-honoraris.pdf',
            type: 'pdf'
          }
        ]
      },
      {
        label: 'Modulística Resto España',
        value: 'modulística-resto-espana',
        active: false,
        data: [
          {
            label: 'Z0_Contrato de colaboración',
            route: '/static/staff/legal/documents/modulistica-resto-espana/z0contrato-de-colaboración.pdf',
            type: 'pdf'
          },
          {
            label: 'Z3_Encargo de venta exclusiva_FAMILY',
            route: '/static/staff/legal/documents/modulistica-resto-espana/z3encargo-de-venta-exclusivafamily.pdf',
            type: 'pdf'
          },
          {
            label: 'Z4_Encargo de venta_ BASIC',
            route: '/static/staff/legal/documents/modulistica-resto-espana/z4encargo-de-venta-basic.pdf',
            type: 'pdf'
          },
          {
            label: 'Z5_Contrato CV con arras sin condicionamiento marco catalán',
            route: '/static/staff/legal/documents/modulistica-resto-espana/z5contrato-cv-con-arras-sin-condicionamiento-marco-catalan.docx',
            type: 'word'
          },
          {
            label: 'Z6_Contrato CV con arras sin condicionamiento',
            route: '/static/staff/legal/documents/modulistica-resto-espana/z6contrato-cv-con-arras-sin-condicionamiento.docx',
            type: 'word'
          },
          {
            label: 'Z8_Hoja de visita',
            route: '/static/staff/legal/documents/modulistica-resto-espana/z8hoja-de-visita.pdf',
            type: 'pdf'
          },
          {
            label: 'Z9_ADDENDA',
            route: '/static/staff/legal/documents/modulistica-resto-espana/z9addenda.pdf',
            type: 'pdf'
          },
        ]
      },
      {
        label: 'Comunicaciones cliente',
        value: 'comunicaciones-cliente',
        active: false,
        data: [
          {
            label: 'CE-01 CORREO ACEPTANDO OFERTA PROPIETARIO',
            route: '/static/staff/legal/documents/comunicaciones-cliente/ce-01-correo-aceptando-oferta-propietario.odt',
            type: 'word'
          },
          {
            label: 'CE-02 CORREO COMUNICANDO PERDIDA CANTIDADES',
            route: '/static/staff/legal/documents/comunicaciones-cliente/ce-02-correo-comunicando-perdida-cantidades.odt',
            type: 'word'
          },
          {
            label: 'CE-03 CORREO PERDIDA CANTIDADES NO FINANCIACION',
            route: '/static/staff/legal/documents/comunicaciones-cliente/ce-03-correo-perdida-cantidades-no-financiacion.odt',
            type: 'word'
          }
        ]
      },
    ]
  },
  contentRrss: {
    documents: [
      {
        label: 'Negocio',
        value: 'negocio',
        active: true,
        data: [
          {
            label: 'Vende tu piso sin preocupaciones - Stories - Vertical',
            route: '/static/rrss/videos/business/verano-2-somos-especialistas.mp4',
            type: 'mp4'
          },
          {
            label: 'Portales inmobiliarios - Stories - Vertical',
            route: '/static/rrss/videos/business/verano-4-portales-inmobiliarios.mp4',
            type: 'mp4'
          },
          {
            label: 'Valoración gratuita - Stories - Vertical',
            route: '/static/rrss/videos/business/verano-5-valoramos.mp4',
            type: 'mp4'
          },
          {
            label: 'Así trabajamos - Stories - Vertical',
            route: '/static/rrss/videos/business/verano-3-como-trabajamos.mp4',
            type: 'mp4'
          }
        ]
      },
      {
        label: 'Captación de personal',
        value: 'captacion_personal',
        active: false,
        data: [
          {
            label: 'Capt. Talento Ext._Jobtoday-02',
            route: '/static/rrss/images/recruitment-of-personnel/capt-talento-extjobtoday-02.webp',
            type: 'img'
          },
          {
            label: 'Capt. Talento Ext._Jobtoday-04',
            route: '/static/rrss/images/recruitment-of-personnel/capt-talento-extjobtoday-04.webp',
            type: 'img'
          },
          {
            label: 'Capt. Talento Ext._Jobtoday-05',
            route: '/static/rrss/images/recruitment-of-personnel/capt-talento-extjobtoday-05.webp',
            type: 'img'
          },
          {
            label: 'Capt. Talento Ext._Jobtoday-06',
            route: '/static/rrss/images/recruitment-of-personnel/capt-talento-extjobtoday-06.webp',
            type: 'img'
          },
          {
            label: 'Capt. Talento Ext._Jobtoday-07',
            route: '/static/rrss/images/recruitment-of-personnel/capt-talento-extjobtoday-07.webp',
            type: 'img'
          },
          {
            label: 'Capt. Talento Ext._Jobtoday-20',
            route: '/static/rrss/images/recruitment-of-personnel/capt-talento-extjobtoday-20.webp',
            type: 'img'
          },
          {
            label: 'Capt. Talento Ext._Jobtoday-26',
            route: '/static/rrss/images/recruitment-of-personnel/capt-talento-extjobtoday-26.webp',
            type: 'img'
          },
          {
            label: 'Capt. Talento Ext._Jobtoday-27',
            route: '/static/rrss/images/recruitment-of-personnel/capt-talento-extjobtoday-27.webp',
            type: 'img'
          },
          {
            label: 'Capt. Talento Ext._Jobtoday-28',
            route: '/static/rrss/images/recruitment-of-personnel/capt-talento-extjobtoday-28.webp',
            type: 'img'
          },
          {
            label: 'LinkedIn 23 Cabecera',
            route: '/static/rrss/images/recruitment-of-personnel/linkedin-23-cabecera.webp',
            type: 'img'
          },
          {
            label: 'RRHH Captacion Talento V1',
            route: '/static/rrss/videos/recruitment-of-personnel/rrhh-captacion-talento-v1.mp4',
            type: 'mp4'
          },
          {
            label: 'RRHH Captacion Talento V2',
            route: '/static/rrss/videos/recruitment-of-personnel/rrhh-captacion-talento-v2.mp4',
            type: 'mp4'
          },
          {
            label: 'RRHH Captacion Talento V3',
            route: '/static/rrss/videos/recruitment-of-personnel/rrhh-captacion-talento-v3.mp4',
            type: 'mp4'
          },
          {
            label: 'RRSS Ser Asesor inmobiliario',
            route: '/static/rrss/videos/recruitment-of-personnel/rrss-ser-asesor-inmobiliario.mp4',
            type: 'mp4'
          }
        ]
      },
      {
        label: 'Campaña Avestruz',
        value: 'campana-avestruz',
        active: false,
        data: [
          {
            label: 'Escaparate A3 - Avestruz',
            route: '/static/rrss/pdf/ostrich-campaign/escaparate-a3-avestruz.pdf',
            type: 'pdf'
          },
          {
            label: 'Escaparate A4 - Avestruz',
            route: '/static/rrss/pdf/ostrich-campaign/escaparate-a4-avestruz.pdf',
            type: 'pdf'
          },
          {
            label: 'RRSS - Avestruz 1',
            route: '/static/rrss/images/ostrich-campaign/rrss-avestruz-1.webp',
            type: 'img'
          },
          {
            label: 'RRSS - Avestruz 2 - especialistas',
            route: '/static/rrss/images/ostrich-campaign/rrss-avestruz-2-especialistas.webp',
            type: 'img'
          },
          {
            label: 'RRSS - Avestruz 3 - publicamos',
            route: '/static/rrss/images/ostrich-campaign/rrss-avestruz-3-publicamos.webp',
            type: 'img'
          },
          {
            label: 'RRSS - Avestruz 4 - valoramos',
            route: '/static/rrss/images/ostrich-campaign/rrss-avestruz-4-valoramos.webp',
            type: 'img'
          },
          {
            label: 'Stories - Avestruz 1',
            route: '/static/rrss/videos/ostrich-campaign/stories-avestruz-1.mp4',
            type: 'mp4'
          },
          {
            label: 'Stories - Avestruz 2',
            route: '/static/rrss/videos/ostrich-campaign/stories-avestruz-2.mp4',
            type: 'mp4'
          },
          {
            label: 'Stories - Avestruz 3',
            route: '/static/rrss/videos/ostrich-campaign/stories-avestruz-3.mp4',
            type: 'mp4'
          },
          {
            label: 'Stories - Avestruz 4',
            route: '/static/rrss/videos/ostrich-campaign/stories-avestruz-4.mp4',
            type: 'mp4'
          },

        ]
      },
    ]
  },
  customerLetters: {
    documents: [
      {
        label: 'Cartas CAST',
        value: 'cartas_cast',
        active: true,
        data: [
          {
            label: 'C1 - Piso vendido finca',
            route: '/static/customers-letters/documents/cast/c1-piso-vendido-finca.pdf',
            type: 'pdf'
          },
          {
            label: 'C1 - Oportunidad de venta',
            route: '/static/customers-letters/documents/cast/c1-oportunidad-de-venta.pdf',
            type: 'pdf'
          },
          {
            label: 'C1 - Presentación',
            route: '/static/customers-letters/documents/cast/c1-presentacion.pdf',
            type: 'pdf'
          },
          {
            label: 'C1 - Piso vacío',
            route: '/static/customers-letters/documents/cast/c1cat-pis-buit.pdf',
            type: 'pdf'
          }
        ]
      },
      {
        label: 'Cartas CAT',
        value: 'cartas_cat',
        active: false,
        data: [
          {
            label: 'C1_CAT - Pis venut finca',
            route: '/static/customers-letters/documents/cat/c1cat-pis-venut-finca.pdf',
            type: 'pdf'
          },
          {
            label: 'C1_CAT - Oportunitat de venda',
            route: '/static/customers-letters/documents/cat/c1cat-oportunitat-de-venda.pdf',
            type: 'pdf'
          },
          {
            label: 'C1_CAT - Presentació',
            route: '/static/customers-letters/documents/cat/c1cat-presentacio.pdf',
            type: 'pdf'
          },
          {
            label: 'C1_CAT - Pis buit',
            route: '/static/customers-letters/documents/cat/c1cat-v.pdf',
            type: 'pdf'
          }
        ]
      },
    ]
  },
  docsOfInterest: {
    documents: [
      {
        label: 'Documentos',
        value: 'docs-interest',
        active: true,
        data: [
          {
            label: 'Anapis',
            route: '/static/tools/documents-of-interest/anapis.pdf',
            type: 'pdf'
          },
          {
            label: 'Ficha cliente',
            route: '/static/tools/documents-of-interest/ficha-cliente.pdf',
            type: 'pdf'
          },
          {
            label: 'Hoja de reclamaciones',
            route: '/static/tools/documents-of-interest/hoja-de-reclamacionesgencat.pdf',
            type: 'pdf'
          },
          {
            label: 'Hoja membretada',
            route: '/static/tools/documents-of-interest/marca-grocasa.docx',
            type: 'word'
          },
          {
            label: 'Hoja de valoraciones',
            route: '/static/tools/documents-of-interest/hoja-de-valoraciones.pdf',
            type: 'pdf'
          }
        ]
      }
    ]
  },
  customerService: {
    image: '/static/staff/customer-service/header-desktop.webp',
    information: {
      content: `
                <h2>Atención al cliente</h2>
                <p>
                    Desde nuestro Dpto. de Atención al Cliente estamos comprometidos con la satisfacción y la excelencia de 
                    servicio al cliente y, gracias a nuestro conocimiento sobre la empresa y sus servicios, nos encargamos de 
                    centralizar todas las consultas y llamadas de la red de franquicias ofreciendo una imagen positiva de la 
                    empresa y proporcionando una respuesta ágil según la necesidad de nuestro cliente. Es un departamento que 
                    abarca diferentes responsabilidades, entre las que encontramos principalmente:
                </p>
                <ul>
                    <li>
                        <b>Centralita telefónica;</b> Atención de llamadas entrantes de la red de franquicias. Somos el primer 
                        punto de contacto del cliente con la marca y los responsables de crearles la mejor impresión. 
                        <br />Si es necesario, se encarga del desvío de las llamadas dentro de la organización, asegurándose 
                        de que los clientes sean atendidos por la persona adecuada para resolver sus necesidades. 
                        <br />Proporciona información sobre preguntas básicas de los clientes sobre servicios, horarios, 
                        ubicaciones de oficina y otros detalles relacionados con la red.
                    </li>
                    <li>
                        <b>Atención incidencias clientes:</b> Ayuda a los clientes a resolver problemas menores por teléfono 
                        y les proporciona las instrucciones necesarias sobre cómo proceder en el caso de querer transmitir 
                        una queja. En este punto , toma nota de los detalles y redirige el mensaje al departamento apropiado 
                        para su resolución
                    </li>
                    <li>
                        <b>Almacén y Mensajería:</b> Envío y recogida de documentos, paquetes y material dentro de la empresa 
                        de manera segura. Apoyo en compras y organización de stock.
                    </li>
                    <li>
                        <b>Visibilidad Portales Inmobiliarios:</b> Colabora con los Franquiciados para posicionar su cartera dentro 
                        de los portales inmobiliarios ofreciéndoles orientación para la contratación, gestión y facturación 
                        de estos productos publicitarios.
                    </li>
                </ul>
            `,
      contactTeam: {
        team: [
          {
            name: 'Zoraida Guadarrama',
            position: 'Atención al cliente',
            img: '/static/staff/customer-service/team/zoraida-guadarrama.webp'
          }
        ],
        contact: [
          {
            email: 'zoraida@grocasa.com'
          }
        ]
      }
    },
    documents: [
      {
        label: 'Portalesan',
        value: 'portales',
        active: true,
        data: [
          {
            label: 'Decálogo de la publicación',
            route: '/static/staff/customer-service/documents/decalogo-de-la-publicacion-2022.pdf',
            type: 'pdf'
          }
        ]
      },
    ],
    faqs: [
      {
        question: '¿Cómo debo proceder para la <b>contratación de productos publicitarios</b> dentro de los portales inmobiliarios?',
        answer: `<p>
                    Descárgate esta guía o escríbenos a <a href='mailto:portales@grocasa.com'>portales@grocasa.com</a>
                </p>                    
                `,
        type: 'text',
        active: false
      },
      {
        question: 'Si considero que <b>no estoy recibiendo suficientes solicitudes de clientes</b> y quiero ver si existe alguna incidencia, ¿cómo debo proceder?',
        answer: `<p>
                    Escribe un mail a <a href='mailto:crm@grocasa.com'>crm@grocasa.com</a> para que revisen la publicación de tus inmuebles a los portales. 
                    También, puedes poner en copia a <a href='mailto:portales@grocasa.com'>portales@grocasa.com</a>
                </p>                    
                `,
        type: 'text',
        active: false
      },
      {
        question: 'Si <b>un cliente me solicita poner una reclamación,</b> ¿cómo debo proceder?',
        answer: `<p>
                    Puedes ofrecerle el contacto <a href='mailto:atencionalcliente@grocasa.com'>atencionalcliente@grocasa.com</a> 
                    para que la traslade o facilitarte la hoja de reclamaciones dentro de los documentos que encontrarás en el Staff Legal
                </p>                    
                `,
        type: 'text',
        active: false
      },
      {
        question: 'Si <b>un cliente llama a la Sede solicitando información de un piso de mi oficina,</b> ¿cómo se procede?',
        answer: `<p>
                    Desde la centralita siempre dirigiremos la llamada a la oficina propietaria del inmueble
                </p>                    
                `,
        type: 'text',
        active: false
      },
      {
        question: 'Si <b>quiero dirigirme a la Sede pero no sé quién es la persona</b> a la que debo dirigirme, ¿cómo debo proceder?',
        answer: `<p>
                    Puedes escribir a <a href='mailto:holafranquiciado@grocasa.com'>holafranquiciado@grocasa.com</a> y se derivará tu consulta al departamento correspondiente
                </p>                    
                `,
        type: 'text',
        active: false
      }
    ]
  }
}