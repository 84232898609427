import React from 'react'
//COMPONENTS
import WhatsappSvg from '../../components/svg/WhatsappSvg'
import Phone from '../../components/svg/Phone'
import Email from '../../components/svg/Email'
//STYLES
import { StyledCard } from './styles'
//HELPERS
import { formatShowPhone } from '../../libs/helpers/methods'

const Card = ({ user }) => {
    const showImgStage = (stage) => {
        if (stage === 'Expert') {
            return '/static/stages/expert-mini.webp'
        }
        if (stage === 'Rookie') {
            return '/static/stages/rookie-mini.webp'
        }
        if (stage === 'Trainee') {
            return '/static/stages/trainee-mini.webp'
        }
        return ''
    }

    return (
        <StyledCard franchisee={user.title === 'Franquiciado'} background={user.picture}>
            {
                showImgStage(user.stage) &&
                <div className='stages'>
                    <img src={showImgStage(user.stage)} alt="stage" />
                </div>
            }
            <div className='header'>
                <div className='div-image' />
            </div>
            <div className='div-content'>
                <h2 className='name'><b>{`${user.first_name} ${user.last_name}`}</b></h2>
                <h2
                    className={user.title === 'Franquiciado' ? 'title-franchisee' : 'title-no-franchisee'}
                >
                    {user.title}
                </h2>
                <h2 className='team'>
                    {user.team.name ? user.team.name : ''}
                </h2>
            </div>
            <hr />
            <div className='div-email-phone' style={{ minHeight: '80px' }}>
                {
                    user.phone && (<h2><b>{formatShowPhone(user.phone)}</b></h2>)
                }                
                <h2>{user.email}</h2>
            </div>
            <hr />
            <div className='div-contact lineal'>
                {
                    user.phone &&
                    <a
                        href={`https://api.whatsapp.com/send?phone=34${user.phone}&text=¡Hola!`}
                        target='_blank'
                        rel="noopener noreferrer"
                    >
                        <WhatsappSvg />
                    </a>
                }
                {
                    user.phone &&
                    <a href={`tel:${user.phone}`} target='_blank' rel="noopener noreferrer">
                        <Phone />
                    </a>
                }
                {
                    user.email &&
                    <a href={`mailto:${user.email}`} target='_blank' rel="noopener noreferrer">
                        <Email />
                    </a>
                }
            </div>
        </StyledCard>
    )
}

export default Card