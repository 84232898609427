import React from "react";
import Layout from "../../components/layout";
import Advisers from "./ advisers";
import AdvisersMortgage from "./advisersMortgage";
import Teams from "./teams";
import { useLocation } from "react-router-dom";

const Dispatcher = () => {
  const location = useLocation();

  return (
    <Layout>
      {location.pathname === "/dispatcher/equipos-hipotecas" && (
        <Teams type="mortgages" typeName="hipotecas" />
      )}
      {location.pathname === "/dispatcher/equipos-inmuebles" && (
        <Teams type="properties" typeName="inmuebles" />
      )}
      {location.pathname === "/dispatcher/asesores-hipotecas" && (
        <AdvisersMortgage type="mortgages" typeName="hipotecas" />
      )}
      {location.pathname === "/dispatcher/asesores-inmuebles" && (
        <Advisers type="properties" typeName="inmuebles" />
      )}
    </Layout>
  );
};

export default Dispatcher;
