import React, { useState } from 'react';

//components
import Done from '../../components/icons/done';
import NotDone from '../../components/icons/error';
import Eyes from '../../components/icons/eye';
import Table from '../../components/table';
import Loading from '../../components/loading';
import NotData from '../../components/notData';
import { Tooltip as ReactTooltip } from "react-tooltip";

//styles
import { StyledContainer } from './styled'
import Button from '../../components/ui/button';

const Container = ({ loading, leads, openMessage, modalAssignLeads }) => {
    const [text, setText] = useState('');
    const tableTitles = [
        'Fecha',
        'Nombre',
        'Teléfono',
        'Email',
        'Dirección',
        'Procedencia',
        'Responsable',
        'Equipo',
        'Tipo',
        'Oportunidad',
        'Mensaje',
        'Candidato creado',
        'Notas'
    ]

    const renderResponsible = (data) => {
        if (data.opportunity_type === 'OPE-OPC' && !data.owner && !data.out_of_area) {
            return <Button action={() => modalAssignLeads(data)} padding='0 15px'>Asignar</Button>
        }
        return <strong>{data.owner}</strong>
    }

    const candidateCreated = (data) => {
        if (data.crm.created) {

            return (
                <span data-tooltip-id="tool-tip" onMouseEnter={() => setText('Enviado al CRM')}>
                    <Done />
                </span>
            )
        }

        if (data.out_of_area) {
            return (
                <span data-tooltip-id="tool-tip" onMouseEnter={() => setText('Fuera de área')}>
                    <NotDone />
                </span>
            )
        }

        if (!data.crm.created && data.owner) {
            return (
                <span data-tooltip-id="tool-tip" onMouseEnter={() => setText('No enviado el CRM')}>
                    <NotDone />
                </span>
            )
        }

        if (!data.crm.created && !data.owner && !data.out_of_area) {
            return (
                <span data-tooltip-id="tool-tip" onMouseEnter={() => setText('No asignado')}>
                    <NotDone />
                </span>
            )
        }
    }

    return (
        <StyledContainer>
            {
                loading ? <Loading /> :
                    <>
                        {
                            leads.leads.length <= 0 ? <NotData /> :
                                <Table titles={tableTitles}>
                                    {
                                        leads.leads.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.created_at}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.phone}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.address}</td>
                                                    <td>{item.provenance}</td>
                                                    <td>{renderResponsible(item)}</td>
                                                    <td><strong>{item.team}</strong></td>
                                                    <td>{item.type}</td>
                                                    <td>{item.opportunity_type} {item.property_reference}</td>
                                                    <td>{item.message && <Eyes action={openMessage} item={item.message} />}</td>
                                                    <td>{candidateCreated(item)}</td>
                                                    <td>{item.crm.errors.map(item => item)}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </Table>
                        }
                    </>
            }
            <ReactTooltip
                id="tool-tip"
                place="right"
                content={text}
                style={{ backgroundColor: "#595959", color: "#fff" }}
            />
        </StyledContainer>
    );
};

export default Container;