import React from 'react'
import { Link } from "react-router-dom";
//COMPONENTS
import Button from '../../../components/ui/button'

const ManyPrizes = () => {
    return (
        <>
            <h2>
                Nos encanta <b>reconocer y premiar el trabajo bien hecho,</b> por eso, en Grocasa tenemos un sistema de 
                recompensas para que los buenos resultados siempre tengan premio.
            </h2>
            <div className='top'>
                <Link to='/grogames/catalogo-premios'>
                    <Button>
                        Consulta el catálogo de premios
                    </Button>
                </Link>
            </div>
        </>
    )
}

export default ManyPrizes