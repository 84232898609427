import React from "react";

function Remove() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="4"
            fill="none"
            viewBox="0 0 20 4"
        >
            <path stroke="#FF671B" strokeWidth="2.5" d="M0 2h20"></path>
        </svg>
    );
}

export default Remove;
