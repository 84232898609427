import React from "react";
//COMPONENTS
import RatingCard from "./RatingCard";
import CardFriendChampion from "./CardFriendChampion";
//styles
import { StyledRatingContainer } from "./styles";

const Rating = ({ permitions }) => {
  const dateObj = new Date();
  const year = dateObj.getUTCFullYear();

  return (
    <StyledRatingContainer>
      <div className="rating-data-container">
        {permitions.length > 0 && (
          <>
            {permitions.slice(0, 2).map((item, index) => {
              return <RatingCard key={index} type={item} fullData={year} />;
            })}
           <CardFriendChampion />
          </>
        )}
      </div>
    </StyledRatingContainer>
  );
};

export default Rating;
