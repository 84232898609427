import React from "react";

function GrogamesSvg({ color }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="18"
            fill="none"
            viewBox="0 0 21 18"
        >
            <path
                stroke={color}
                strokeLinecap="round"
                strokeMiterlimit="10"
                d="M10.5 12.664c.087 0 .173-.01.258-.03a4.136 4.136 0 001.3-.553m-1.558.583c-.087 0-.173-.01-.258-.03a4.135 4.135 0 01-1.3-.553m1.558.583V17m1.558-4.919c.563-.367.928-.81 1.246-1.295m-1.246 1.295c.563-.367.927-.81 1.246-1.295m0 0c.251-.383.87-1.395 1.527-4.065m-1.527 4.065c.252-.383.87-1.396 1.527-4.065m0 0c.273-1.107.498-2.04.591-3.343m-.591 3.343c.271-1.107.497-2.04.591-3.343m0 0c.041-.556.049-1.064.04-1.51m-.04 1.51c.04-.555.049-1.064.04-1.51m0 0c-.01-.483-.42-.868-.918-.868m.918.867c-.009-.482-.42-.867-.918-.867m0 0H6.456m0 0c-.499 0-.91.385-.918.867M6.456 1c-.499 0-.908.385-.918.867m0 0c-.009.447 0 .956.04 1.511m-.04-1.51c-.009.446-.001.954.04 1.51m0 0c.094 1.304.32 2.236.591 3.343m-.591-3.343c.093 1.304.318 2.236.591 3.343m0 0c.657 2.67 1.274 3.681 1.527 4.065M6.17 6.721c.658 2.67 1.276 3.681 1.527 4.065m0 0c.32.486.683.928 1.246 1.295m-1.246-1.295c.318.486.683.928 1.246 1.295M6.595 17h7.81M5.555 3.027H2.143c-.724 0-1.263.641-1.121 1.328.056.277.126.57.213.881.214.765.466 1.647 1.08 2.65.126.206.705 1.133 1.786 2.088.735.65 1.733 1.54 3.324 1.967.377.102.948.22 1.668.235h.001m2.81 0h0a6.972 6.972 0 001.67-.235c1.59-.427 2.588-1.318 3.323-1.967 1.081-.955 1.66-1.882 1.786-2.087.614-1.004.866-1.886 1.08-2.65.087-.311.157-.605.213-.882.142-.687-.397-1.328-1.12-1.328h-3.411"
            ></path>
        </svg>
    );
}

export default GrogamesSvg;
