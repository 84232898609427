import React from 'react'
//COMPONENTS
import Card from './Card'

const Users = ({ data }) => {
    return (
        <div className='row mt-4 mb-0' style={{ padding: '0 10px' }}>
            {
                data.map((user, key) => {
                    return (
                        <div
                            style={{ padding: '0 5px' }}
                            className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4"
                            key={key}
                        >
                            <Card user={user} />
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Users