export const dispatcher = [
    {
        key: 'dispatcher.mortgages.teams',
        name: 'Equipo Hipotecas',
        domine: 'E.H',
        route: '/dispatcher/equipos-hipotecas'
    },
    {
        key: 'dispatcher.mortgages.users',
        name: 'Asesores Hipotecas',
        domine: 'A.H',
        route: '/dispatcher/asesores-hipotecas'
    },
    {
        key: 'dispatcher.properties.teams',
        name: 'Equipo Inmuebles',
        domine: 'E.I',
        route: '/dispatcher/equipos-inmuebles'
    },
    {
        key: 'dispatcher.properties.users',
        name: 'Asesores Inmuebles',
        domine: 'A.I',
        route: '/dispatcher/asesores-inmuebles'
    }
]

export const users = [
    {
        key: 'users.me',
        name: 'Mi perfil',
        route: '/dispatcher/equipos-inmuebles'
    }
]