import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
//components
import Login from "./pages/login";
import Home from "./pages/home";
import Profile from "./pages/profile";
import RecoveryPassword from "./pages/recoveryPassword";
import ResetPassword from "./pages/resetPassword";
import Dispatcher from "./pages/dispatcher";
import Lead from "./pages/lead";
import Casafari from "./pages/casafari";
import NotFound from "./pages/errorPages/404";
import NotAuthorized from "./pages/errorPages/401";
import ErrorServer from "./pages/errorPages/500";
import Notification from "./pages/notification";
import HearBeat from "./pages/hearbeat";
import League from "./pages/groGames/league";
import Modal from "./components/modal/index"
import GroAgenda from "./pages/groagenda";
import GroChampions from "./pages/grochampions";
import ContactoSede from "./pages/contacto-sede";
import CareerPlan from "./pages/career-plan";
import DressCode from "./pages/dress-code";
import TrainingPlan from "./pages/training-plan";
import AwardCatalog from "./pages/award-catalog";
import Benefits from "./pages/benefits";
import GalleryPhotos from "./pages/gallery-photos";
import Rrhh from "./pages/staff/Rrhh";
import ItCrm from "./pages/staff/ItCrm";
import MarketingDesign from "./pages/staff/MarketingDesign";
import Formation from "./pages/staff/Formation";
import Mentoring from "./pages/staff/Mentoring";
import OfficeImage from "./pages/staff/OfficeImage";
import Legal from "./pages/staff/Legal";
import ContentRRSS from "./pages/tools/ContentRRSS";
import CustomerLetters from "./pages/tools/CustomerLetters";
import DossierServices from "./pages/tools/DossierServices";
import DocsOfInterest from "./pages/tools/DocsOfInterest";
//context
import { UserProvider } from "./hooks/context/user";
import { NotificationProvider } from "./hooks/context/notifaction";
import { AlertProvider } from "./hooks/context/alert";
//hooks
import { useModal } from "./hooks/context/modal";
import CustomerSupport from "./pages/staff/CustomerSupport";
import Marketplace from "./pages/marketplace";
import FairsAndParties from "./pages/fairs-and-parties";
import { getToken } from "./libs/cookies";
import FeePlan from "./pages/fee-plan";
// import { pushDataGAInit } from "./analytics/dataLeyer";

function App() {
  const { open } = useModal()
  const token = getToken();

  const NoProtectedRoute = ({ children }) => {
    if (token) {
      return <Navigate to='/' />
    }
    return children
  }

  return (
    <>
      <UserProvider>
        <NotificationProvider>
          <AlertProvider>
            <BrowserRouter>
              {open && <Modal />}
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<NoProtectedRoute><Login /></NoProtectedRoute>} />
                <Route path="/recovery_password" element={<NoProtectedRoute><RecoveryPassword /></NoProtectedRoute>} />
                <Route path="/password_reset/:token" element={<NoProtectedRoute><ResetPassword /></NoProtectedRoute>} />
                {/* <Route path="/notification" element={<Notification />} /> */}
                <Route path="/grogames/ligas" element={<League />} />
                <Route path="/grogames/grochampions" element={<GroChampions />} />
                <Route path="/grogames/catalogo-premios" element={<AwardCatalog />} />
                <Route path="/perfil" element={<Profile />} />
                <Route path="/leads" element={<Lead />} />
                <Route path="/groagenda" element={<GroAgenda />} />
                <Route path="/documentos/beneficios" element={<Benefits />} />
                <Route path="/documentos/plan-carrera" element={<CareerPlan />} />
                <Route path="/documentos/plan-honorarios" element={<FeePlan />} />
                <Route path="/documentos/codigo-vestimenta" element={<DressCode />} />
                <Route path="/documentos/plan-formacion" element={<TrainingPlan />} />
                <Route path="/staff/rrhh" element={<Rrhh />} />
                <Route path="/staff/it-crm" element={<ItCrm />} />
                <Route path="/staff/marketing-diseno" element={<MarketingDesign />} />
                <Route path="/staff/formacion" element={<Formation />} />
                <Route path="/staff/mentoring" element={<Mentoring />} />
                <Route path="/staff/imagen-oficinas" element={<OfficeImage />} />
                <Route path="/staff/atencion-cliente" element={<CustomerSupport />} />
                <Route path="/mis-herramientas/contenido-rrss" element={<ContentRRSS />} />
                <Route path="/mis-herramientas/cartas-clientes" element={<CustomerLetters />} />
                <Route path="/mis-herramientas/dossier-servicios" element={<DossierServices />} />
                <Route path="/mis-herramientas/documentos-interes" element={<DocsOfInterest />} />
                <Route path="/staff/legal" element={<Legal />} />
                <Route path="/mis-recursos/ferias-y-fiestas" element={<FairsAndParties />} />
                <Route path="/contactos-sede" element={<ContactoSede />} />
                <Route path="/galeria-fotos" element={<GalleryPhotos />} />
                <Route path="/marketplace" element={<Marketplace />} />
                <Route path="/casafari" element={<Casafari />} />
                <Route path="/hearbeat" element={<HearBeat />} />
                <Route path="/dispatcher/:type" element={<Dispatcher />} />
                <Route path="/500" element={<ErrorServer />} />
                <Route path="/401" element={<NotAuthorized />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </BrowserRouter>

          </AlertProvider>
        </NotificationProvider>
      </UserProvider>
    </>
  );
}

export default App;
