import React, { useState } from "react";
//COMPONENTS
import GroagendaSvg from "../svg/GroagendaSvg";
import GrogamesSvg from "../svg/GrogamesSvg";
import DocumentsSvg from "../svg/DocumentsSvg";
import BlogInternoSvg from "../svg/BlogInternoSvg";
import CasafariSvg from "../svg/CasafariSvg";
import HomeMenu from "../svg/HomeMenu";
import GalleryPhotosSvg from "../svg/GalleryPhotosSvg";
import SettingsSvg from "../svg/SettingsSvg";
import MarketPlaceSvg from "../svg/MarketPlaceSvg";
import SettingsFranchisee from "../svg/SettingsFranchisee";
import GroAcademyFranchiseeSvg from "../svg/GroAcademyFranchiseeSvg";
import GroAcademySvg from "../svg/GroAcademySvg";
import classNames from "classnames";
import { UseUser } from "../../hooks/context/user";
import { dispatcher } from "./menu";
import {
  StyledContainer,
  StyledUl,
  IconDispatcher,
  StyledLinkLogo,
  IconRatings,
  IconCandict,
  IconOpen,
  StyledLink,
  StyledLinkCustom,
  StyledBotton,
} from "./styled";
import { useNavigate, useLocation } from "react-router-dom";
import { apiURL } from "../../libs/helpers/methods";

function Sidebar({ menu }) {
  const { user } = UseUser();
  let location = useLocation();
  let navigate = useNavigate();

  const [open, setOpen] = useState({
    dispatcher: location.pathname.includes("/dispatcher") ? true : false,
    grogames: location.pathname.includes("/grogames") ? true : false,
    documentos: location.pathname.includes("/documentos/") ? true : false,
    staff: location.pathname.includes("/staff") ? true : false,
    tools: (location.pathname.includes("/casafari") || location.pathname.includes("/mis-herramientas")) ? true : false,
    resources: location.pathname.includes("/mis-recursos") ? true : false,
  });
  const franchisee = user.menu.menu_franchisee ? 1 : 0
  const colorIcons = franchisee ? "#C79D46" : "#FF671B"

  const [openSub, setOpenSub] = useState({ grogamesseries: false });

  const openSubMenu = (value) => {
    const result = {
      dispatcher: value === "dispatcher" ? !open.dispatcher : false,
      grogames: value === "grogames" ? !open.grogames : false,
      documentos: value === "documentos" ? !open.documentos : false,
      staff: value === "staff" ? !open.staff : false,
      tools: value === "tools" ? !open.tools : false,
      resources: value === "resources" ? !open.resources : false,
    };
    setOpen(result);
  };
  const openSubNivel2Menu = (value) => {
    const result = {
      grogamesseries:
        value === "grogamesseries" ? !openSub.grogamesseries : false,
    };
    setOpenSub(result);
  };

  const goTo = (route) => {
    navigate(route);
  };

  return (
    <StyledContainer
      id="sidebar"
      className={menu}
      franchisee={franchisee}
    >
      <StyledLinkLogo to="/" franchisee={franchisee}>
        {menu === "desktop-menu-close" && (
          <img src={`/static/${franchisee ? 'grocasa-phone-franchisee.png' : 'grocasa_logo_g_50.png'}`} alt="Grocasa" />
        )}
        {(menu === "desktop-menu-open" || menu === "phone-menu-open") && (
          <img src={`/static/${franchisee ? 'grointranetFranchisee.png' : 'grointranet.png'}`} alt="Grocasa" />
        )}
      </StyledLinkLogo>
      <StyledUl franchisee={franchisee} menuclose={(menu === 'desktop-menu-close') ? 1 : 0}>
        <li
          className={classNames({
            minimize: menu === "desktop-menu-close",
            'active': location.pathname === '/'
          })}
        >
          <StyledBotton onClick={() => goTo("/")} franchisee={franchisee}>
            <HomeMenu color={colorIcons} />
            <span>Inicio</span>
          </StyledBotton>
        </li>
        {user.menu["dispatcher"] !== undefined && (
          <li
            className={classNames({
              minimize: menu === "desktop-menu-close",
              active: location.pathname.includes("dispatcher")
            })}
          >
            <StyledBotton onClick={() => openSubMenu("dispatcher")} franchisee={franchisee}>
              <IconDispatcher franchisee={franchisee} />
              <span>Dispatcher</span>
              {menu === "desktop-menu-open" && <IconOpen franchisee={franchisee} />}
            </StyledBotton>
            {user.menu["dispatcher"].length > 0 && (
              <ul className={classNames("submenu", { open: open.dispatcher })}>
                {dispatcher.map((item) => {
                  return user.menu["dispatcher"].map((res, index) => {
                    return (
                      res === item.key && (
                        <li
                          key={index}
                          className={classNames({ "active": location.pathname === item.route })}
                        >
                          <StyledLink to={item.route}>
                            <span>
                              {
                                (menu === "desktop-menu-open" || menu === "phone-menu-open")
                                  ? item.name
                                  : item.domine
                              }
                            </span>
                          </StyledLink>
                        </li>
                      )
                    );
                  });
                })}
              </ul>
            )}
          </li>
        )}
        {user.menu["leads"] && (
          <li
            className={classNames({
              minimize: menu === "desktop-menu-close",
              'active': location.pathname === '/leads'
            })}
          >
            <StyledBotton onClick={() => goTo("/leads")} franchisee={franchisee}>
              <IconCandict franchisee={franchisee} />
              <span>Candidatos</span>
            </StyledBotton>
          </li>
        )}
        {
          user.menu["groagenda"] && (
            <li
              className={classNames({
                minimize: menu === "desktop-menu-close",
                'active': location.pathname === '/groagenda'
              })}
            >
              <StyledBotton onClick={() => goTo("/groagenda")} franchisee={franchisee}>
                <GroagendaSvg color={colorIcons} />
                <span>Groagenda</span>
              </StyledBotton>
            </li>
          )
        }
        {
          user.menu["groacademy"] && (
            <li>
              <StyledLinkCustom
                href='https://groacademy.grocasa.com/'
                target="_blank"
                franchisee={franchisee}
                menuclose={!(menu === "desktop-menu-open" || menu === "phone-menu-open") ? 1 : 0}
              >
                {
                  franchisee ?
                    <GroAcademyFranchiseeSvg />
                    :
                    <GroAcademySvg />
                }
                {(menu === "desktop-menu-open" || menu === "phone-menu-open") && (<span>Groacademy</span>)}
              </StyledLinkCustom>
            </li>
          )
        }
        {
          user.menu["marketplace"] && (
            <li
              className={classNames({
                minimize: menu === "desktop-menu-close",
                'active': location.pathname === '/marketplace'
              })}
            >
              <StyledBotton onClick={() => goTo("/marketplace")} franchisee={franchisee}>
                <MarketPlaceSvg color={colorIcons} />
                <span>Marketplace</span>
              </StyledBotton>
            </li>
          )
        }
        {/* {
          user.menu["contacts_headquarters"] && (
            <li
              className={classNames({
                minimize: menu === "desktop-menu-close",
                'active': location.pathname === '/contactos-sede'
              })}
            >
              <StyledBotton onClick={() => goTo("/contactos-sede")} franchisee={franchisee}>
                <GroagendaSvg color={colorIcons} />
                <span>Otros contactos</span>
              </StyledBotton>
            </li>
          )
        } */}
        {
          user.menu["my_tools"] !== undefined && (
            <li
              className={classNames({
                minimize: menu === "desktop-menu-close",
                active: (location.pathname.includes("/casafari") || location.pathname.includes("mis-herramientas"))
              })}
            >
              <StyledBotton onClick={() => openSubMenu("tools")} franchisee={franchisee}>
                <SettingsSvg color={colorIcons} />
                <span>Mis herramientas</span>
                {menu === "desktop-menu-open" && <IconOpen franchisee={franchisee} />}
              </StyledBotton>
              <ul className={classNames("submenu", { open: open.tools })}>
                {
                  user.menu["my_tools"].includes('casafari') && (
                    <li className={classNames({ "active": location.pathname === "/casafari" })}>
                      <StyledLink to="/casafari">
                        <span>
                          {
                            (menu === "desktop-menu-open" || menu === "phone-menu-open")
                              ? 'Casafari'
                              : 'C.I'
                          }
                        </span>
                      </StyledLink>
                    </li>
                  )
                }
                {
                  user.menu["my_tools"].includes('app_zona') && (
                    <li>
                      <StyledLinkCustom
                        href="https://zona.grocasa.com/"
                        target="_blank"
                        franchisee={franchisee}
                        menuclose={!(menu === "desktop-menu-open" || menu === "phone-menu-open") ? 1 : 0}
                      >
                        <span>
                          {
                            (menu === "desktop-menu-open" || menu === "phone-menu-open")
                              ? 'APP de Zona'
                              : 'APP'
                          }
                        </span>
                      </StyledLinkCustom>
                    </li>
                  )
                }
                {
                  user.menu["my_tools"].includes('escaparates') && (
                    <li>
                      <StyledLinkCustom
                        href="https://escaparates.grocasa.com/"
                        target="_blank"
                        franchisee={franchisee}
                        menuclose={!(menu === "desktop-menu-open" || menu === "phone-menu-open") ? 1 : 0}
                      >
                        <span>
                          {
                            (menu === "desktop-menu-open" || menu === "phone-menu-open")
                              ? 'Escaparates'
                              : 'E.S'
                          }
                        </span>
                      </StyledLinkCustom>
                    </li>
                  )
                }
                {
                  user.menu["my_tools"].includes('grotienda') && (
                    <li>
                      <StyledLinkCustom
                        href="https://grotienda.myshopify.com/"
                        target="_blank"
                        franchisee={franchisee}
                        menuclose={!(menu === "desktop-menu-open" || menu === "phone-menu-open") ? 1 : 0}
                      >
                        <span>
                          {
                            (menu === "desktop-menu-open" || menu === "phone-menu-open")
                              ? 'Grotienda'
                              : 'G.T'
                          }
                        </span>
                      </StyledLinkCustom>
                    </li>
                  )
                }
                {
                  user.menu["my_tools"].includes('pad') && (
                    <li>
                      <StyledLinkCustom
                        href="https://pad.grocasalab.com/"
                        target="_blank"
                        franchisee={franchisee}
                        menuclose={!(menu === "desktop-menu-open" || menu === "phone-menu-open") ? 1 : 0}
                      >
                        <span>
                          {
                            (menu === "desktop-menu-open" || menu === "phone-menu-open")
                              ? 'PAD'
                              : 'PAD'
                          }
                        </span>
                      </StyledLinkCustom>
                    </li>
                  )
                }
                {
                  user.menu["my_tools"].includes('social_media_content') && (
                    <li className={classNames({ "active": location.pathname === "/mis-herramientas/contenido-rrss" })}>
                      <StyledLink to="/mis-herramientas/contenido-rrss">
                        <span>
                          {
                            (menu === "desktop-menu-open" || menu === "phone-menu-open")
                              ? 'Recursos gráficos'
                              : 'RRSS'
                          }
                        </span>
                      </StyledLink>
                    </li>
                  )
                }
                {
                  user.menu["my_tools"].includes('services-dossier') && (
                    <li className={classNames({ "active": location.pathname === "/mis-herramientas/dossier-servicios" })}>
                      <StyledLink to="/mis-herramientas/dossier-servicios">
                        <span>
                          {
                            (menu === "desktop-menu-open" || menu === "phone-menu-open")
                              ? 'Dossier de servicios'
                              : 'D.S'
                          }
                        </span>
                      </StyledLink>
                    </li>
                  )
                }
                {
                  user.menu["my_tools"].includes('documents_interest') && (
                    <li className={classNames({ "active": location.pathname === "/mis-herramientas/documentos-interes" })}>
                      <StyledLink to="/mis-herramientas/documentos-interes">
                        <span>
                          {
                            (menu === "desktop-menu-open" || menu === "phone-menu-open")
                              ? 'Docs de interes'
                              : 'D.I'
                          }
                        </span>
                      </StyledLink>
                    </li>
                  )
                }
                {
                  user.menu["my_tools"].includes('cartas_clientes') && (
                    <li className={classNames({ "active": location.pathname === "/mis-herramientas/cartas-clientes" })}>
                      <StyledLink to="/mis-herramientas/cartas-clientes">
                        <span>
                          {
                            (menu === "desktop-menu-open" || menu === "phone-menu-open")
                              ? 'Cartas clientes'
                              : 'C.C'
                          }
                        </span>
                      </StyledLink>
                    </li>
                  )
                }
              </ul>
            </li>
          )
        }
        {
          user.menu["my_resources"] !== undefined && (
            <li
              className={classNames({
                minimize: menu === "desktop-menu-close",
                active: location.pathname.includes("mis-recursos")
              })}
            >
              <StyledBotton onClick={() => openSubMenu("resources")} franchisee={franchisee}>
                <SettingsSvg color={colorIcons} />
                <span>Mis recursos</span>
                {menu === "desktop-menu-open" && <IconOpen franchisee={franchisee} />}
              </StyledBotton>
              <ul className={classNames("submenu", { open: open.resources })}>
                {user.menu["my_resources"].includes('festivals-and-fairs') && (
                  <li className={classNames({ "active": location.pathname === "/mis-recursos/ferias-y-fiestas" })}>
                    <StyledLink to="/mis-recursos/ferias-y-fiestas">
                      <span>
                        {
                          (menu === "desktop-menu-open" || menu === "phone-menu-open")
                            ? 'Ferias y fiestas'
                            : 'FF'
                        }
                      </span>
                    </StyledLink>
                  </li>
                )}
              </ul>
            </li>
          )
        }
        {
          user.menu["staff"] !== undefined && (
            <li
              className={classNames({
                minimize: menu === "desktop-menu-close",
                active: location.pathname.includes("staff")
              })}
            >
              <StyledBotton onClick={() => openSubMenu("staff")} franchisee={franchisee}>
                {
                  franchisee ?
                    <SettingsFranchisee />
                    :
                    <SettingsSvg color={colorIcons} />
                }
                <span>Mi Staff</span>
                {menu === "desktop-menu-open" && <IconOpen franchisee={franchisee} />}
              </StyledBotton>
              <ul className={classNames("submenu", { open: open.staff })}>
                {user.menu["staff"].includes('rrhh') && (
                  <li className={classNames({ "active": location.pathname === "/staff/rrhh" })}>
                    <StyledLink to="/staff/rrhh">
                      <span>
                        {
                          (menu === "desktop-menu-open" || menu === "phone-menu-open")
                            ? 'RRHH'
                            : 'RRHH'
                        }
                      </span>
                    </StyledLink>
                  </li>
                )}
                {user.menu["staff"].includes('legal') && (
                  <li className={classNames({ "active": location.pathname === "/staff/legal" })}>
                    <StyledLink to="/staff/legal">
                      <span>
                        {
                          (menu === "desktop-menu-open" || menu === "phone-menu-open")
                            ? 'Legal'
                            : 'Legal'
                        }
                      </span>
                    </StyledLink>
                  </li>
                )}
                {user.menu["staff"].includes('marketing-design') && (
                  <li className={classNames({ "active": location.pathname === "/staff/marketing-diseno" })}>
                    <StyledLink to="/staff/marketing-diseno">
                      <span>
                        {
                          (menu === "desktop-menu-open" || menu === "phone-menu-open")
                            ? 'Marketing y diseño'
                            : 'M.D'
                        }
                      </span>
                    </StyledLink>
                  </li>
                )}
                {user.menu["staff"].includes('office-image') && (
                  <li className={classNames({ "active": location.pathname === "/staff/imagen-oficinas" })}>
                    <StyledLink to="/staff/imagen-oficinas">
                      <span>
                        {
                          (menu === "desktop-menu-open" || menu === "phone-menu-open")
                            ? 'Imagen oficinas'
                            : 'I.O'
                        }
                      </span>
                    </StyledLink>
                  </li>
                )}
                {user.menu["staff"].includes('it-crm') && (
                  <li className={classNames({ "active": location.pathname === "/staff/it-crm" })}>
                    <StyledLink to="/staff/it-crm">
                      <span>
                        {
                          (menu === "desktop-menu-open" || menu === "phone-menu-open")
                            ? 'IT / CRM'
                            : 'ITCRM'
                        }
                      </span>
                    </StyledLink>
                  </li>
                )}
                {user.menu["staff"].includes('formation') && (
                  <li className={classNames({ "active": location.pathname === "/staff/formacion" })}>
                    <StyledLink to="/staff/formacion">
                      <span>
                        {
                          (menu === "desktop-menu-open" || menu === "phone-menu-open")
                            ? 'Formación'
                            : 'F.T'
                        }
                      </span>
                    </StyledLink>
                  </li>
                )}
                {user.menu["staff"].includes('mentoring') && (
                  <li className={classNames({ "active": location.pathname === "/staff/mentoring" })}>
                    <StyledLink to="/staff/mentoring">
                      <span>
                        {
                          (menu === "desktop-menu-open" || menu === "phone-menu-open")
                            ? 'Mentoring'
                            : 'M.T'
                        }
                      </span>
                    </StyledLink>
                  </li>
                )}
                {user.menu["staff"].includes('customer-service') && (
                  <li className={classNames({ "active": location.pathname === "/staff/atencion-cliente" })}>
                    <StyledLink to="/staff/atencion-cliente">
                      <span>
                        {
                          (menu === "desktop-menu-open" || menu === "phone-menu-open")
                            ? 'Atención al cliente'
                            : 'A.C'
                        }
                      </span>
                    </StyledLink>
                  </li>
                )}
              </ul>
            </li>
          )
        }
        {
          user.menu["grogames"] !== undefined && (
            <li
              className={classNames({
                minimize: menu === "desktop-menu-close",
                active: location.pathname.includes("grogames")
              })}
            >
              <StyledBotton onClick={() => openSubMenu("grogames")} franchisee={franchisee}>
                <GrogamesSvg color={colorIcons} />
                <span>GroGames</span>
                {menu === "desktop-menu-open" && <IconOpen franchisee={franchisee} />}
              </StyledBotton>
              <ul className={classNames("submenu", { open: open.grogames })}>
                {user.menu["grogames"].champions && (
                  <li className={classNames({ "active": location.pathname === "/grogames/grochampions" })}>
                    <StyledLink to="/grogames/grochampions">
                      <span>
                        {
                          (menu === "desktop-menu-open" || menu === "phone-menu-open")
                            ? 'Grochampions'
                            : 'G.C'
                        }
                      </span>
                    </StyledLink>
                  </li>
                )}
                {user.menu["grogames"].leagues && (
                  <li className={classNames({ "active": location.pathname === "/grogames/ligas" })}>
                    <StyledLink to="/grogames/ligas">
                      <span>
                        {
                          (menu === "desktop-menu-open" || menu === "phone-menu-open")
                            ? 'Groleagues'
                            : 'G.L'
                        }
                      </span>
                    </StyledLink>
                  </li>
                )}
                {user.menu["grogames"].awards_catalog && (
                  <li className={classNames({ "active": location.pathname === "/grogames/catalogo-premios" })}>
                    <StyledLink to="/grogames/catalogo-premios">
                      <span>
                        {
                          (menu === "desktop-menu-open" || menu === "phone-menu-open")
                            ? 'Catálogo de Premios'
                            : 'C.P'
                        }
                      </span>
                    </StyledLink>
                  </li>
                )}
              </ul>
            </li>
          )
        }
        {
          user.menu["documents_offline"] !== undefined && (
            <li
              className={classNames({
                minimize: menu === "desktop-menu-close",
                active: location.pathname.includes("/documentos/")
              })}
            >
              <StyledBotton onClick={() => openSubMenu("documentos")} franchisee={franchisee}>
                <DocumentsSvg color={colorIcons} />
                <span>Documentos</span>
                {menu === "desktop-menu-open" && <IconOpen franchisee={franchisee} />}
              </StyledBotton>
              <ul className={classNames("submenu", { open: open.documentos })}>
                {user.menu["documents_offline"].includes('benefits') && (
                  <li className={classNames({ "active": location.pathname === "/documentos/beneficios" })}>
                    <StyledLink to="/documentos/beneficios">
                      <span>
                        {
                          (menu === "desktop-menu-open" || menu === "phone-menu-open")
                            ? 'Beneficios'
                            : 'B.C'
                        }
                      </span>
                    </StyledLink>
                  </li>
                )}
                {user.menu["documents_offline"].includes('career_plan') && (
                  <li className={classNames({ "active": location.pathname === "/documentos/plan-carrera" })}>
                    <StyledLink to="/documentos/plan-carrera">
                      <span>
                        {
                          (menu === "desktop-menu-open" || menu === "phone-menu-open")
                            ? 'Plan de Carrera'
                            : 'P.C'
                        }
                      </span>
                    </StyledLink>
                  </li>
                )}
                {user.menu["documents_offline"].includes('fee_plan') && (
                  <li className={classNames({ "active": location.pathname === "/documentos/plan-honorarios" })}>
                    <StyledLink to="/documentos/plan-honorarios">
                      <span>
                        {
                          (menu === "desktop-menu-open" || menu === "phone-menu-open")
                            ? 'Plan de honorarios'
                            : 'P.H'
                        }
                      </span>
                    </StyledLink>
                  </li>
                )}
                {user.menu["documents_offline"].includes('dress_code') && (
                  <li className={classNames({ "active": location.pathname === "/documentos/codigo-vestimenta" })}>
                    <StyledLink to="/documentos/codigo-vestimenta">
                      <span>
                        {
                          (menu === "desktop-menu-open" || menu === "phone-menu-open")
                            ? 'Código de Vestimenta'
                            : 'C.V'
                        }
                      </span>
                    </StyledLink>
                  </li>
                )}
                {/*{user.menu["documents_offline"].includes('training_plan') && (*/}
                {/*  <li className={classNames({ "active": location.pathname === "/documentos/plan-formacion" })}>*/}
                {/*    <StyledLink to="/documentos/plan-formacion">*/}
                {/*      <span>*/}
                {/*        {*/}
                {/*          (menu === "desktop-menu-open" || menu === "phone-menu-open")*/}
                {/*            ? 'Plan de Formación'*/}
                {/*            : 'P.F'*/}
                {/*        }*/}
                {/*      </span>*/}
                {/*    </StyledLink>*/}
                {/*  </li>*/}
                {/*)}*/}
              </ul>
            </li>
          )
        }
        {
          user.menu["internal_blog"] && (
            <li>
              <StyledLinkCustom
                href={process.env.REACT_APP_INTERNAL_BLOG}
                target="_blank"
                franchisee={franchisee}
                menuclose={!(menu === "desktop-menu-open" || menu === "phone-menu-open") ? 1 : 0}
              >
                <BlogInternoSvg color={colorIcons} />
                {(menu === "desktop-menu-open" || menu === "phone-menu-open") && (<span>Universo Grocasa</span>)}
              </StyledLinkCustom>
            </li>
          )
        }
        {
          user.menu["photos_gallery"] && (
            <li
              className={classNames({
                minimize: menu === "desktop-menu-close",
                'active': location.pathname === '/galeria-fotos'
              })}
            >
              <StyledBotton onClick={() => goTo("/galeria-fotos")} franchisee={franchisee}>
                <GalleryPhotosSvg color={colorIcons} />
                <span>Galería de fotos</span>
              </StyledBotton>
            </li>
          )
        }
      </StyledUl>
    </StyledContainer>
  );
}

export default Sidebar;
