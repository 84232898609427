import styled from 'styled-components';

export const StyledContainer = styled.div`
    position: fixed;
    width: 0px;
    overflow: hidden;
    z-index: 50;
    top: 70px;
    right: 20px;
    overflow: hidden;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    padding: 0;
    margin: 0;
    line-height: 1.5715;
    transition: all 0.6s;
    &.active { 
        width: 250px;
        margin-bottom: 16px;
        margin-left: auto;
        padding: 16px 24px;
    }
`

export const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    div { 
        display: flex;
        h1 {
            margin: 0;
            margin-left: 15px;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
        }
    }
`
export const StyledText = styled.div`
    margin: 10px 0px 0px 40px;
    overflow: hidden;
    p {
        font-size: 14px;
        margin: 0;
    }
`