import React from "react";
import TwitterSvg from "../svg/TwitterSvg";
import styled from "styled-components";

const StyledLink = styled.a`
  text-decoration: none;
  margin: 0;
  padding: 0;
`;

export default function IcontTwitter({ color, link }) {
  return (
    <StyledLink
      href={link ? link : "https://twitter.com/grocasa"}
      target="_blank"
      rel="nofollow"
      aria-label='twitter'
    >
      <TwitterSvg />
    </StyledLink>
  );
}
