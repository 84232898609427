import React from "react";

function HomeMenu({color}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="15"
            fill="none"
            viewBox="0 0 19 17"
        >
            <path
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.145 6.86v9.13H2.855V6.86"
            ></path>
            <path
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.682 9.924H7.316v6.067h4.366V9.924zM18 8.496L9.5 1 5.25 4.748 1 8.496"
            ></path>
        </svg>
    );
}

export default HomeMenu;
