import React from "react";

function Technology() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="46"
            height="36"
            fill="none"
            viewBox="0 0 46 36"
        >
            <path
                stroke="#FF671B"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M36.812 1.946H9.189c-1.35 0-2.443 1.067-2.443 2.382v16.636c0 1.315 1.094 2.382 2.443 2.382h27.623c1.35 0 2.443-1.067 2.443-2.382V4.328c0-1.315-1.094-2.382-2.443-2.382zM26.256 27.694h17.768v4.878c0 .821-.678 1.482-1.514 1.482H3.49c-.841 0-1.52-.66-1.52-1.482v-4.878H19.74"
            ></path>
            <path
                stroke="#FF671B"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M26.256 27.694v1.676c0 .319-.263.581-.596.581h-5.325a.591.591 0 01-.596-.581v-1.676"
            ></path>
        </svg>
    );
}

export default Technology;
