import React from "react";

function InstagramSvg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="none"
            viewBox="0 0 25 25"
        >
            <g fill="#35374A" fillOpacity="0.35" clipPath="url(#clip0_35_1160)">
                <path d="M12.514 0C5.595 0 0 5.595 0 12.486 0 19.377 5.595 25 12.514 25 19.432 25 25 19.405 25 12.486 25 5.568 19.405 0 12.514 0zm0 23.622c-6.12 0-11.136-4.99-11.136-11.136 0-6.146 4.99-11.108 11.136-11.108 6.146 0 11.108 4.99 11.108 11.108 0 6.12-4.99 11.136-11.108 11.136z"></path>
                <path d="M16.152 8.021a.8.8 0 10.002 1.6.8.8 0 00-.002-1.6zM12.569 9.068a3.417 3.417 0 100 6.836 3.435 3.435 0 003.418-3.418 3.417 3.417 0 00-3.418-3.418zm0 5.596a2.182 2.182 0 01-2.178-2.178 2.2 2.2 0 012.178-2.177 2.2 2.2 0 012.177 2.177 2.2 2.2 0 01-2.177 2.178z"></path>
                <path d="M15.27 5.568H9.73A4.176 4.176 0 005.568 9.73v5.54a4.176 4.176 0 004.162 4.162h5.54a4.176 4.176 0 004.162-4.162V9.73a4.176 4.176 0 00-4.162-4.162zm2.867 9.675a2.864 2.864 0 01-2.867 2.866H9.73a2.864 2.864 0 01-2.867-2.866v-5.54A2.864 2.864 0 019.73 6.835h5.54a2.864 2.864 0 012.867 2.866v5.54z"></path>
            </g>
            <defs>
                <clipPath id="clip0_35_1160">
                    <path fill="#fff" d="M0 0H25V25H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default InstagramSvg;
