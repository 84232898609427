import React from 'react'
//COMPONENTS
import Store from '../../../components/svg/Store'
import Travel from '../../../components/svg/Travel'
import Home from '../../../components/svg/Home'
import Technology from '../../../components/svg/Technology'
import ButtonLink from '../../../components/ui/ButtonLink'
//STYLES
import styled from 'styled-components'
import { pColor } from '../../../styles/variables'

const StyledContent = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 20px;   
    align-items: flex-end;
    background-image: url(/static/documents/benefits/platform-benefits.webp);
    background-repeat: no-repeat;    
    background-position: center center;
    background-size: cover;       

    > div { 
        display: flex;
        flex-direction: column;           
        padding: 20px;
  
        > h2 {    
            font-size: 12px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            margin: 0 0 20px;
            color: #35374A;
          
        }        
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px;
        height: fit-content;
        width: 80%;        

        p {            
            font-size: 12px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: center;
            margin: 0;
            color: ${pColor};         
            
            &.spacing {
                margin: 10px 0;
            }
        }        

        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #F2F2F2;         
            padding: 20px;   
            box-shadow: 0px 4px 4px 0px #0000001A;
        }      
    }

    @media (max-width: 1100px) {
        grid-template-columns: 1fr;

        .cards {            
            width: auto;        
        }
    }

    @media (max-width: 768px) {
        background-image: url(/static/documents/benefits/platform-benefits-tablet.webp);
    }

    @media (max-width: 600px) {
        background-image: url(/static/documents/benefits/platform-benefits-mobile.webp);
        .cards {            
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (max-width: 375px) {
        a {
            white-space: pre-wrap;
            padding: 30px;
        }
        .cards {            
            grid-template-columns: repeat(1, 1fr);
        }
    }
`

const PlatformBenefits = () => {
    return (
        <StyledContent>
            <div>
                <h2>
                    Accede a nuestro <b>Club Privado de Beneficios</b> donde encontrarás descuentos y promociones en cientos de
                    marcas destacadas para utilizar todos los días del año.
                </h2>
                <h2 style={{ marginBottom: '95px' }}>¡Date de alta y comienza a disfrutar!</h2>
                <ButtonLink href='https://grocasa.clubdebenefits.com/pages/index' target='_blank'>
                    Darme de alta en Inspiring Benefits
                </ButtonLink>
            </div>
            <div className='cards'>
                <div>
                    <Store />
                    <p className='spacing'><b>Compras</b></p>
                    <p>+90 ofertas</p>
                </div>
                <div>
                    <Travel />
                    <p className='spacing'><b>Viajes</b></p>
                    <p>+60 ofertas</p>
                </div>
                <div>
                    <Home />
                    <p className='spacing'><b>Hogar</b></p>
                    <p>+50 ofertas</p>
                </div>
                <div>
                    <Technology />
                    <p className='spacing'><b>Tecnología</b></p>
                    <p>+30 ofertas</p>
                </div>
            </div>
        </StyledContent>
    )
}

export default PlatformBenefits