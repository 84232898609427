import React from "react";

function WhatsappSvg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="none"
            viewBox="0 0 20 20"
        >
            <g fill="#35374A" fillOpacity="0.35" clipPath="url(#clip0_40_1017)">
                <path d="M0 20l1.024-3.05c.219-.65.446-1.297.65-1.952a.578.578 0 00-.041-.409C-1.727 8.657 1.737 1.338 8.47.151c5.326-.94 10.423 2.609 11.382 7.923.917 5.08-2.508 10.121-7.582 11.163-2.318.477-4.53.194-6.649-.866a.61.61 0 00-.411-.03C3.49 18.88 1.774 19.433 0 20m2.453-2.414c.147-.043.235-.067.321-.093.876-.28 1.75-.567 2.63-.834a.56.56 0 01.404.043c1.901 1.128 3.933 1.474 6.098 1.035 4.482-.907 7.392-5.473 6.306-9.882-1.083-4.39-5.399-7.062-9.864-6.106C2.708 2.957.053 9.532 3.294 14.283c.135.199.163.36.083.584-.311.883-.603 1.772-.923 2.72"></path>
                <path d="M7.254 5.266c.24-.03.407.079.497.283.296.669.587 1.34.865 2.019.104.256-.058.47-.198.664-.13.18-.281.346-.435.507-.148.157-.177.313-.061.501.8 1.326 1.91 2.283 3.366 2.837.185.07.325.027.445-.12.24-.295.492-.583.72-.887.137-.184.301-.261.504-.168.685.31 1.369.626 2.039.966.096.048.165.245.164.373-.014.991-.721 1.776-1.708 1.983-.548.116-1.058.046-1.59-.093-2.308-.602-4.051-1.965-5.39-3.896-.446-.644-.884-1.289-1.041-2.072-.196-.982.043-1.84.762-2.549.294-.291.685-.303 1.06-.348z"></path>
            </g>
            <defs>
                <clipPath id="clip0_40_1017">
                    <path fill="#fff" d="M0 0H20V20H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default WhatsappSvg;
