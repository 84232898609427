import React from 'react'
import DeutsheBankSvg from '../../../components/svg/DeutsheBank'
import Grocasa from '../../../components/svg/Grocasa'
import ButtonLink from '../../../components/ui/ButtonLink'

const DeutsheBank = () => {
    return (
        <>
            <div className='icons bottom'>
                <div><DeutsheBankSvg /></div>
                <div><Grocasa /></div>
            </div>
            <h2>
                Descubre qué beneficios obtendrás como empleado Grocasa en los siguientes productos:
            </h2>
            <h2><b>Depósito a plazo fijo a 12 meses, préstamo personal a tipo fijo y préstamo hipotecario a tipo mixto</b></h2>
            <h2 className='top bottom'>Para aprovechar estas ventajas exclusivas para empleados</h2>
            <div className='top'>
                <ButtonLink
                    href='https://www.deutsche-bank.es/es/banca-asociada.html#grocasa'
                    target='_blank'
                >
                    Haz click para saber más
                </ButtonLink>
            </div>
        </>
    )
}

export default DeutsheBank