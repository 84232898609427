import React, { useState } from "react";
import { useNotification } from "../../hooks/context/notifaction";
import { UseUser } from "../../hooks/context/user";
import { getToken } from "../../libs/cookies";
import Hamburguer from "../icons/hamburger";

//component
import Notification from "../notification";
import LinkCustom from "../ui/link";
import User from "../user";

//styles
import {
  StyledHeader,
  StyledNavbarContainer,
  StyledRight,
  StyledProfile,
  StyledNotification,
  StyledNotContent,
  StyledNotHeader,
  StyledTop,
  StyledBody,
  StyledNotBody,
  StyledNotFooter,
} from "./styles";

function Header({ menu, actionMenu }) {
  const [userMenu, setUserMenu] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const { notification } = useNotification();
  const { logout } = UseUser();
  const showMenu = () => {
    setUserMenu(!userMenu);
    if (showNotifications) setShowNotifications(false);
  };
  const showNotify = () => {
    setShowNotifications(!showNotifications);
    if (userMenu) setUserMenu(false);
  };
  return (
    <StyledHeader className={menu}>
      <StyledNavbarContainer>
        <Hamburguer open={() => actionMenu(menu)} />
        <StyledRight>
          <li>
            <Notification
              showNotify={showNotify}
              number={notification.news.number}
            />
          </li>
          <li className="isoUser">
            <User showMenu={showMenu} />
          </li>
        </StyledRight>
      </StyledNavbarContainer>
      {userMenu && (
        <StyledProfile>
          <StyledTop top="6px" />
          <StyledBody>
            <ul>
              <li>
                <LinkCustom route="/perfil" text="Perfil" />
              </li>
              {/* <li><StyledLink to="/setting">Ajustes</StyledLink></li> */}
              <li>
                <span onClick={() => logout(getToken())}>Logout</span>
              </li>
            </ul>
          </StyledBody>
        </StyledProfile>
      )}
      {showNotifications && (
        <StyledNotification>
          <StyledTop top="-4px" />
          <StyledNotContent>
            <StyledNotHeader>
              <h3>Notificaciones</h3>
            </StyledNotHeader>
            <StyledNotBody>
              {notification.news.notifications.map((item, index) => {
                return (
                  <a key={index} href={item.url}>
                    <div className="info">
                      <h5>{item.title}</h5>
                      <span>{item.time_ago}</span>
                    </div>
                    <p>{item.body}</p>
                  </a>
                );
              })}
            </StyledNotBody>
            <StyledNotFooter to="/notification">Ver todas</StyledNotFooter>
          </StyledNotContent>
        </StyledNotification>
      )}
    </StyledHeader>
  );
}

export default React.memo(Header);
