import React from "react";
import TikTokSvg from "../svg/TikTokSvg";
import styled from "styled-components";

const StyledLink = styled.a`
  text-decoration: none;
  margin: 0;
  padding: 0;
`;

export default function IconTikTok({ color, link }) {
  return (
    <StyledLink
      href={link ? link : 'https://www.tiktok.com/@grocasa'}
      target="_blank"
      rel="nofollow"
      aria-label='youtube'
    >
      <TikTokSvg />
    </StyledLink>
  );
}
