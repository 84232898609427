import React from 'react'
import { Link } from "react-router-dom";
//COMPONENTS
import Button from '../../../components/ui/button'

const CareerPlan = () => {
    return (
        <>
            <h2>
                En Grocasa puedes evolucionar hacia posiciones directivas gracias a nuestro Plan de Carrera.
            </h2>
            <h2 className='top bottom'>
                Etapa tras etapa, alcanza los objetivos marcados, cumple el plan formativo, crece profesionalmente e
                incrementa tu paquete retributivo conforme avances.
            </h2>
            <div className='top'>
                <Link to='/documentos/plan-carrera'>
                    <Button>
                        Conoce el plan de carrera
                    </Button>
                </Link>
            </div>
        </>
    )
}

export default CareerPlan