import React from "react";
import styled from "styled-components";
import { pColor, sColor } from "../../styles/variables";

const StyledButton = styled.button`
  width: ${({ fullweight }) => (fullweight ? "100%" : "fit-content")};
  height: 35px;
  border: 0;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;
  touch-action: manipulation;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  line-height: 1.5;
  padding: ${({ padding }) => (padding ? padding : "0 25px")};
  font-size: 14px;
  border-radius: 4px;
  height: 36px;
  user-select: none;
  position: relative;
  color: #fff;
  background-color: ${({ disabled }) => (disabled ? sColor : pColor)};
  border-color: #fe5000;
  outline: none;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  &:hover {
    background-color: ${sColor};
  }
  &.card-gro {
    background-color: #ffffff;
    color: ${pColor};
    font-weight: 600;
    border: none;

    &:hover {
      background-color: #FF8A38;
      color: #ffffff;
    }
  }
  &.dark {
    background-color: #595959;
    border-color: #595959;

    &:hover {
      background-color: #595959;
      color: #ffffff;
    }
  }
`;

const Button = ({ action, children, fullweight, disabled, styles, padding }) => {
  return (
    <StyledButton disabled={disabled} onClick={action} fullweight={fullweight ? 1 : 0} className={styles} padding={padding}>
      {children}
    </StyledButton>
  );
};

export default Button;
