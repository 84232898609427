import React from 'react';
import Button from '../../components/ui/button';
import { StyledContainer, StyledTextContent, StyledImgContent } from './styles'
import { useNavigate } from 'react-router-dom';

const NotAuthorized = () => {
    let navigate = useNavigate();

    const goTo = () => navigate('/')

    return (
        <StyledContainer>
            <StyledTextContent>
                <h1>401</h1>
                <h3>Acceso no autorizado</h3>
                <p>No tienes permisos para acceder a esta página</p>
                <Button action={goTo}> Volver al inicio</Button>
            </StyledTextContent>
            <StyledImgContent>
                <img src="/static/rob.png" alt="NotAuthorized" />
            </StyledImgContent>
        </StyledContainer>
    );
};

export default NotAuthorized;