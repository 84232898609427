import styled from 'styled-components'
import { pColor } from '../../styles/variables'

export const StyledFooter = styled.footer`
    background: #fdfdfd;
    display: flex;
    justify-content: center;
    padding: 20px 0;

    svg {
        background: #ffffff;
        border-radius: 40px;
        margin-right: 10px;
        padding: 2px;
        width: 30px;
        height: 30px;
        color: rgba(53, 55, 74, 0.35);        
        border-radius: 40px;

        &:hover {
            g, path {
                fill: ${pColor};
                fill-opacity: 1;
            }            
        }
    }

    @media (min-width: 701px) {
         padding-left: ${({ menu }) => menu ? '223px' : '0'};        
    }
`