export const DATA_MARKETPLACE = [
  {
    id: 'papernest',
    active: false,
    background: '#5A52FF',
    logo: '/static/marketplace/papernest-logo.webp',
    img: '/static/marketplace/papernest.webp',
    textShow: `
        <p><b>Información</b></p>
        <p class='margin-y'>
            Ayuda a tus clientes <b>ahorrando tiempo en la gestión de los suministros</b> en el momento de la mudanza.
            Papernest les facilitará en pocos clicks un espacio para centralizar, <b>dar de alta, cambiar y cancelar
                libremente todos los contratos y suscripciones</b> (luz, gas, internet....) Empieza a generar ingresos dándote
            de alta en la <a href='https://app.papernest.es/' target='_blank'>App de Papernest</a>
        </p>
    `,
    textHide: `
        <p><b>Convenio</b></p>
        <p class='margin-y'>
            Con Papernest <b>gana 5€ por el envío de lead y 15€ extras si éste gestiona al menos 1 contrato</b> con ellos.
            Haz llegar los datos de tu cliente a través del formulario envío cliente, agenda una llamada con ellos y
            Papernest pondrá en marcha el proceso.
        </p>
        <p><b>Seguimiento</b></p>
        <p class='margin-y'>
            Haz el seguimiento de la situación de los contratos de tus clientes directamente desde la <b>App de Papernest</b>
        </p>
        <p class='margin-y'><b>Contacto</b></p>
        <p class='font-600'>Cyrille Torres</p>
        <p>623 299 477</p>
        <p>cyrille.torres@papernest.com</p>
        <p class='font-600 margin-top'>Sara Schulczewski</p>
        <p>+34 633 410 975 / +33 659 369 935</p>
        <p>sara.schulczewski@papernest.com</p>
    `,
    link: 'https://app.papernest.es/home/',
    position: 'first'
  },
  {
    id: 'pintorist',
    active: false,
    background: '#94D60A',
    logo: '/static/marketplace/pintorist-logo.webp',
    img: '/static/marketplace/pintorist.webp',
    textShow: `
        <p><b>Información</b></p>
        <p class='margin-y'>
            Tanto si es para poner el inmueble a la venta como si es para entrar a vivir, <b>una buena mano de pintura 
            mejorará la estancia exponencialmente.</b>
        </p>
        <p class='margin-y'>
            En Pintorist cuentan con una red de + de 3500 pintores y podrán ayudar a tus clientes ofreciéndoles los 
            presupuestos más ajustados y el mejor seguimiento especializado.
        </p>
    `,
    textHide: `
        <p><b>Convenio</b></p>
        <p class='margin-y'>
            Envía un cliente interesado en recibir presupuestos de Pintorist mediante el formulario “envío cliente”. 
            Pintorist contactará con él y, <b>si finalmente contrata su servicio, recibirás el 50% de la comisión de 
            Pintorist</b> al finalizar el proyecto.
        </p>
        <p><b>Seguimiento</b></p>
        <p class='margin-y'>
            Haz el seguimiento de tus envíos mensualmente con el proveedor <b>mandando un mail a cpatlle@pintorist.es o 
            por whatapps al 687 702 912</b>
        </p>
        <p class='margin-y'><b>Contacto</b></p>
        <p class='font-600'>Carles Patlle</p>
        <p>689 425 283</p>            
    `,
    link: '',
    position: 'first'
  },
  {
    id: 'anyvan',
    active: false,
    background: '#41A5DD',
    logo: '/static/marketplace/anyvan-logo.webp',
    img: '/static/marketplace/anyvan.webp',
    textShow: `
        <p><b>Información</b></p>
        <p class='margin-y'>
            Ponemos al alcance de nuestros clientes <b>la compañía de mudanzas, envíos y transporte</b> más utilizada en Europa.
        </p>
        <p class='margin-y'>
            Anyvan ayudará a tu cliente proporcionando <b>comodidad, eficiencia y seguridad</b> durante el proceso de 
            cambio de hogar.
        </p>
    `,
    textHide: `
        <p><b>Convenio</b></p>
        <p class='margin-y'>
            Envía un cliente interesado en recibir presupuestos de mudanzas mediante el formulario “envío cliente”. 
            Anyvan contactará con él y, <b>si finalmente contrata su servicio, recibirás el 40% del márgen generado</b> por 
            AnyVan en cada transacción
        </p>
        <p><b>Seguimiento</b></p>
        <p class='margin-y'>
            Haz seguimiento de tus envíos mensualmente, el proveedor enviará un reporte mensual con detalles de conversión 
            y comisiones. También tendrás un punto de <b>contacto directo en AnyVan para tus consultas; partnerships@anyvan.es</b>
        </p>
        <p class='margin-y'><b>Contacto</b></p>
        <p class='font-600'>Estefania Di Stola</p>
        <p>+44 (0) 623 299 477</p>
        <p>cyrille.torres@papernest.com</p>
    `,
    link: 'https://prices.anyvan.com/grocasa',
    position: 'second'
  },
  {
    id: 'lateua',
    active: false,
    background: '#BD7D60',
    logo: '/static/marketplace/lateua-logo.webp',
    img: '/static/marketplace/lateua.webp',
    textShow: `
        <p><b>Información</b></p>
        <p class='margin-y'>
            Ayuda a tus clientes a hacer de un inmueble su hogar. En Lateua son especialistas en <b>diseño de interiores 
            y con su e-commerce ofrecerán a tu cliente un proyecto de decoración,</b> venta de artículos para el hogar y 
            mobiliario adaptado a sus necesidades.
        </p>
        <p>
            Además, tus clientes, por ser clientes Grocasa, <b>recibirán un descuento de 100€ por estancia</b> (con compras 
            superiores a 1000€)
        </p>
        <p class='margin-y'>
            Empieza a generar ingresos dándote de alta en su plataforma <b>Lateua Pro</b>
        </p>            
    `,
    textHide: `
        <p><b>Convenio</b></p>
        <p class='margin-y'>
            Comparte con tus clientes el código promocional que te facilitarán al registrarte en <b>Lateua Pro.</b> Una vez los 
            clientes ejecuten sus compras en Lateua, recibirás un % sobre su inversión en este e-commerce: <b>3% hasta 5000€ 
            de compra, 5% hasta 15.000€ y 10% para más de 15.000€</b>
        </p>
        <p><b>Seguimiento</b></p>
        <p class='margin-y'>
            Haz el seguimiento de tus envíos en el <b>Lateua Pro</b>
        </p>
        <p class='margin-y'><b>Contacto</b></p>
        <p class='font-600'>Iria Sanz</p>
        <p>649 617 501</p>
        <p>ria@lateua.es</p>
    `,
    link: 'http://alb-lateua-api-master-240193675.eu-west-1.elb.amazonaws.com/signup/affiliate/',
    position: 'second'
  },
  {
    id: 'securitas',
    active: false,
    background: '#FFF',
    logo: '/static/marketplace/securitas-logo.webp',
    img: '/static/marketplace/securitas.webp',
    textShow: `
        <p><b>Información</b></p>
        <p class='margin-y'>
            En Securitas Direct, igual que en Grocasa, saben que cada hogar es diferente y sus necesidades de protección 
            también. Por eso, cuentan con <b>la tecnología más avanzada para adaptarse a cada uno de nuestros clientes.</b>
        </p>
        <p class='margin-y'>
            Empieza a generar ingresos dándote de <a href='https://forms.office.com/pages/responsepage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAANAAWHDYQBURTRHSzBSOEFDV1dSNFlXOTFCN05XWlFIVS4u' target='_blank'>alta como prescriptor</a>
        </p>            
    `,
    textHide: `
        <p><b>Convenio</b></p>
        <p class='margin-y'>
            Envía un cliente interesado en un sistema de seguridad mediante el formulario “envío cliente”. Securitas 
            contactará con él y, <b>si finalmente contrata su servicio, recibirás hasta 100€.</b>
        </p>
        <p><b>Seguimiento</b></p>
        <p class='margin-y'>
            Haz el seguimiento de tus envíos en <a href='https://ventas.securitasdirect.es/colaboradores' target='_blank'>su canal prescriptor</a>
        </p>
        <p class='margin-y'><b>Contacto</b></p>
        <p class='font-600'>Santiago Douglas</p>
        <p>689 425 283</p>
        <p>s.ferreira@securitasdirect.es</p>

        <p class='font-600 margin-top'>Romina Bearzi</p>
        <p>romina.bearzi@securitasdirect.es</p>            
    `,
    link: 'https://forms.office.com/pages/responsepage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAANAAWHDYQBUQVlJS1k4UzJZUjZJSTFTOTA3S0dMNzRWRS4u',
    position: 'three'
  },
  {
    id: 'pedra',
    active: false,
    background: '#FFF',
    logo: '/static/marketplace/pedra-logo.webp',
    img: '/static/marketplace/pedra.webp',
    textShow: `
        <p><b>Información</b></p>
        <p class='margin-y'>
            Ayuda a tus clientes a mejorar la presentación de sus inmuebles con facilidad e Inteligencia Artificial. Pedra es una aplicación web que cuenta con AI, que te ayudará a generar home staging virtuales y mejoras de fotografía. Con ella podrás generar propuestas de renovación, vacía espacios, amuéblalos y más en pocos clicks.</b>
        </p>            
    `,
    textHide: `
        <p><b>Convenio</b></p>
        <p class='margin-y'>Gracias al acuerdo único con Grocasa, podrás utilizar <a href='https://pedra.so/es' target='_blank'>Pedra.so</a> con un descuento del 15%. Empieza a ofrecer valor añadido a tus clientes aprovechando el acuerdo firmado con la App de <a href='https://pedra.so/es' target='_blank'>Pedra.so</a>.</p>
        <p><b>Utiliza el cupón:</b></p>
        <p class='margin-y'>
            GROCASA15
        </p>
        <p class='margin-y'><b>Contacto</b></p>
        <p class='font-600'>Felix Ingla</p>
        <p>646 811 068</p>
        <p>felix@pedra.so</p>          
    `,
    link: 'https://pedra.so/es',
    position: 'three'
  }
]