import React from 'react'
//COMPONENTS
import Layout from '../../components/layout'
import TitleAndDownload from '../../components/ui/TitleAndDownload'
//STYLES
import styled from "styled-components";

const StyledContainer = styled.div`    
    margin-top: 20px;
    img {
        max-width: 100%;
        width: 100%;
    }   
`

const DressCode = () => {
    return (
        <Layout>
            <TitleAndDownload text={<>Código de <b>Vestimenta</b></>} link='/static/documents/codigo-de-vestimenta.pdf' />
            <StyledContainer>
                <img src="/static/documents/dress-code.webp" alt='dress code' />
            </StyledContainer>
        </Layout>
    )
}

export default DressCode