import React from "react";

function TwitterSvg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="none"
            viewBox="0 0 25 25"
        >
            <g fill="#35374A" fillOpacity="0.35" clipPath="url(#clip0_35_1159)">
                <path d="M12.514 0A12.505 12.505 0 000 12.514C0 19.432 5.595 25 12.514 25 19.432 25 25 19.405 25 12.514 25 5.623 19.405 0 12.514 0zm0 23.622c-6.12 0-11.136-4.99-11.136-11.108 0-6.12 4.99-11.136 11.136-11.136 6.146 0 11.108 4.99 11.108 11.136 0 6.146-4.99 11.108-11.108 11.108z"></path>
                <path d="M17.723 8.82c.22-.027.524-.413.634-.579.193-.22.33-.496.441-.799v-.055h-.083a5.233 5.233 0 01-1.543.579c-.055 0-.083 0-.11-.028-.056-.055-.083-.11-.138-.138a2.614 2.614 0 00-.744-.468 2.698 2.698 0 00-1.158-.193c-.386 0-.744.138-1.075.303-.33.165-.634.413-.882.717a2.507 2.507 0 00-.524 1.047 2.969 2.969 0 00-.027 1.103c0 .055 0 .082-.055.055-2.123-.304-3.887-1.075-5.32-2.701-.055-.083-.083-.083-.138 0-.634.937-.33 2.453.469 3.17.11.11.22.192.33.275-.055 0-.551-.055-1.02-.276-.055-.027-.082 0-.11.055v.304c.11.937.772 1.819 1.682 2.177.11.055.22.083.33.11-.193.056-.413.083-.992.028-.083 0-.11 0-.083.083.441 1.213 1.406 1.57 2.123 1.764.082 0 .193 0 .275.027-.248.359-1.047.634-1.433.772-.689.248-1.46.358-2.177.276h-.166c-.027 0 0 .055.028.082.138.11.303.193.468.276.469.248.965.441 1.461.579 2.619.716 5.596.193 7.553-1.764 1.543-1.544 2.094-3.666 2.094-5.789 0-.082.11-.137.166-.165.413-.303.716-.661 1.02-1.075.055-.083.055-.165.055-.193 0 0 0-.027-.055 0a4.322 4.322 0 01-1.296.359v.082z"></path>
            </g>
            <defs>
                <clipPath id="clip0_35_1159">
                    <path fill="#fff" d="M0 0H25V25H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default TwitterSvg;
