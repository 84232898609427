import React from "react";
import styled from "styled-components";
import LinkedinSvg from "../svg/LinkedinSvg";

const StyledLink = styled.a`
  text-decoration: none;
  margin: 0;
  padding: 0;    
`;

export default function IcontLinkedin() {
  return (
    <StyledLink
      href="https://www.linkedin.com/company/grocasa"
      target="_blank"
      rel="nofollow"
      aria-label='linkedin'
    >
      <LinkedinSvg />
    </StyledLink>
  );
}
