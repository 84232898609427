import React from 'react'
import { Link } from "react-router-dom";
//COMPONENTS
import Button from '../../../components/ui/button'

const EventsBusiness = () => {
    return (
        <>
            <h2>
                Compartimos juntos momentos únicos en diferentes eventos a lo largo del año para que disfrutes, 
                te relajes y compartas experiencias increíbles con tus compañeros.
            </h2>
            <h2 className='top bottom'>
                No te pierdas nuestros <b>eventos de empresa</b> donde podrás, a parte de pasártelo genial, aprender y 
                celebrar los éxitos de la red.
            </h2>
            <div className='top'>
                <Link to='/galeria-fotos'>
                    <Button>
                        Galería fotográfica
                    </Button>
                </Link>
            </div>
        </>
    )
}

export default EventsBusiness