import React from 'react';

//components
import Button from '../../components/ui/button';
import Input from '../../components/ui/input';

//styled
import { StyledInputContent } from "../login/styled";

const Form = ({ password, repeatPassword, error, handleOnchange, handleSubmit, errorServer }) => {
    return (
        <form>
            <h1 className="title-reset">Introducir contraseñas</h1>
            <StyledInputContent>
                <Input
                    error={error.password || errorServer}
                    type="password"
                    name="password"
                    placeholder="Nueva contraseña"
                    value={password}
                    onChange={handleOnchange}
                    fullwidth
                />
            </StyledInputContent>
            <StyledInputContent>
                <Input
                    error={error.repeatPassword || errorServer}
                    type="password"
                    name="repeatPassword"
                    placeholder="Repetir contraseña"
                    value={repeatPassword}
                    onChange={handleOnchange}
                    fullwidth
                />
            </StyledInputContent>
            <Button action={handleSubmit} fullweight>
                Guardar
            </Button>
        </form>
    );
};

export default Form;