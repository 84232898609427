import React from 'react'

const BuyHousing = () => {
    return (
        <>
            <h2>
                Para ti tenemos las mejores <b>condiciones hipotecarias</b> gracias a 
                los acuerdos exclusivos que tiene Grocasa Hipotecas con diferentes 
                entidades financieras para que compres tu casa con las mejores condiciones.
            </h2>
            <h2 className='top'>
                ¿Quieres más información? escribe a                
            </h2>
            <a className='new-a' href='mailto:administracionhipotecas@grocasa.com'>administracionhipotecas@grocasa.com</a>
        </>
    )
}

export default BuyHousing