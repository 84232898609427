import React from "react";

function GalleryPhotosSvg({ color }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="16"
            fill="none"
            viewBox="0 0 19 16"
        >
            <path
                fill={color}
                d="M16.124 15.5v.5h.5v-.5h-.5zM7.451 2.114l-.28-.414.28.414zM9.651.63l.279.415.001-.001-.28-.414zM16.123 15.5v-.5h-.5v.5h.5zM3.208 0a.5.5 0 000 1V0zm2.368 1a.5.5 0 000-1v1zM18 7.851a.5.5 0 000-1v1zm-4.951-1a.5.5 0 000 1v-1zm-7.098 1a.5.5 0 100-1v1zM1 6.851a.5.5 0 000 1v-1zM16.124 15H2.876v1h13.248v-1zM2.876 15c-.751 0-1.375-.622-1.375-1.41h-1c0 1.322 1.054 2.41 2.375 2.41v-1zM1.5 13.59V4.154h-1v9.436h1zm0-9.436c0-.788.624-1.41 1.375-1.41v-1C1.556 1.744.5 2.832.5 4.154h1zm1.375-1.41h4.15v-1h-4.15v1zm4.15 0c.25 0 .497-.074.706-.216L7.17 1.7a.256.256 0 01-.144.044v1zm.705-.215L9.93 1.045l-.56-.83L7.173 1.7l.559.829zm2.2-1.485A.255.255 0 0110.074 1V0c-.25 0-.495.075-.705.217l.562.827zM10.074 1h6.05V0h-6.05v1zm6.05 0c.751 0 1.375.622 1.375 1.41h1c0-1.322-1.054-2.41-2.375-2.41v1zM17.5 2.41V13.59h1V2.41h-1zm0 11.179c0 .788-.624 1.41-1.375 1.41v1c1.32 0 2.375-1.088 2.375-2.41h-1zm-1.875 1.91v.001h1v-.001h-1zM3.208 1h2.368V0H3.208v1zM18 6.851h-4.951v1H18v-1zm-12.049 0H1v1h4.951v-1zm6.994 2.257c0 1.953-1.551 3.52-3.445 3.52v1c2.464 0 4.445-2.033 4.445-4.52h-1zM9.5 12.628c-1.894 0-3.445-1.567-3.445-3.52h-1c0 2.487 1.981 4.52 4.445 4.52v-1zm-3.445-3.52c0-1.952 1.551-3.52 3.445-3.52v-1c-2.464 0-4.445 2.033-4.445 4.52h1zM9.5 5.588c1.894 0 3.445 1.568 3.445 3.52h1c0-2.487-1.981-4.52-4.445-4.52v1z"
            ></path>
        </svg>
    );
}

export default GalleryPhotosSvg;
