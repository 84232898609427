import React from "react";

function Home() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="49"
            height="30"
            fill="none"
            viewBox="0 0 49 30"
        >
            <path
                stroke="#FF671B"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10.431 17.702V29H2.917v-7.487A4.732 4.732 0 011 17.703c0-2.618 2.106-4.74 4.712-4.74s4.72 2.122 4.72 4.74zm0 0H38.57m0 0c0-2.617 2.12-4.74 4.719-4.74S48 15.085 48 17.702c0 1.567-.756 2.95-1.917 3.811V29h-7.514V17.702zm-32.85-4.74V6.86C5.719 3.618 8.325 1 11.545 1h25.903c3.22 0 5.833 2.618 5.833 5.859v6.103M10.431 26.64c.358.075.736.109 1.114.109h25.903a5.51 5.51 0 001.12-.109"
            ></path>
        </svg>
    );
}

export default Home;
