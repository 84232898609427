import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAlert } from "../../hooks/context/alert";

//components
import Input from "../../components/ui/input";
import Button from "../../components/ui/button";
import Card from "../../components/card";
import Loading from "../../components/loading";

//libs
import { getToken } from "../../libs/cookies";
import { apiURL, provinces } from "../../libs/helpers/methods";

//text
import { messages } from "../../libs/helpers/message";

//styled
import {
  StyledModalContainer,
  StyledFormContent,
  StyledButtonContent,
} from "./styled";
import Textarea from "../../components/ui/textarea";
import { getProvences, validareRefencie } from "../../actions";
import { isEmpty } from "lodash";
import Select from "../../components/ui/Select";
import FormElement from "../../components/ui/FormElement";

export default function Form({ modal, getLeads }) {
  const { openAlert } = useAlert();
  const [provenances, setProvenances] = useState([]);
  const [loadingProvenance, setLoadingProvenance] = useState(false);
  const [loadingReferent, setLoadingReferent] = useState(false);
  const [lead, setLead] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    type: "mortgage",
    property_reference: "",
    type_rent: "owner",
    opportunity_type: "opf",
    interest: "Contratación Hipoteca",
    home_recruitment: "Todavía no",
    provenance: "",
    message: ""
  });
  const [error, setError] = useState({
    name: "",
    email: "",
    phone: "",
    type: "mortgage",
    property_reference: "",
    provenance: "",
  });
  const [loading, setLoading] = useState(false);
  const token = getToken();

  const save = async (e) => {
    e.preventDefault();
    if (validate()) {
      if (lead.phone || lead.email) {
        setError({
          ...error,
          phone: false,
          email: false,
          name: false,
          property_reference: false,
          provenance: false,
        });
        setLoading(true);
        await axios
          .post(`${apiURL()}/api/leads/lead`, { ...lead, token })
          .then((res) => {
            if (res.data.meta.success) {
              openAlert("success", "Candidatos", messages.LEAD_ADD_SUCCESS);
              modal();
              getLeads(25, 0, "");
            }
          })
          .catch((err) => {
            openAlert("error", "Candidatos", messages.LEAD_ADD_ERROR);
            console.log("Error", err);
          });
      } else {
        setError({
          ...error,
          phone: true,
          email: true,
          name: false,
          property_reference: false,
          provenance: false,
        });
      }
    }
    setLoading(false);
  };

  const validate = () => {
    const {
      name,
      property_reference,
      type,
      provenance,
      opportunity_type,
    } = lead;
    if (name === "") {
      setError({
        ...error,
        name: true,
        property_reference: false,
        provenance: false,
      });
      return false;
    } else if (provenance === "") {
      setError({
        ...error,
        name: false,
        property_reference: false,
        provenance: true,
      });
      return false;
    } else if (
      (type === "property" &&
        opportunity_type !== "ope-opc" &&
        property_reference === "") ||
      error.property_reference
    ) {
      setError({
        ...error,
        name: false,
        property_reference: true,
        provenance: false,
      });
      return false;
    } else {
      setError({
        ...error,
        name: false,
        property_reference: false,
        provenance: false,
      });
      return true;
    }
  };

  const handleOnchange = (e) => {
    const { value, name } = e.target;
    setLead({ ...lead, [name]: value });
  };

  const handleOnchangeSelect = (e) => {
    const { value, name } = e.target;
    if (name === "type" && value === "property") {
      setLead({ ...lead, [name]: value, opportunity_type: "oca" });
      getDataProvenances({ type: value, opportunity_type: "oca" });
    } else if (name === "type" && value === "mortgage") {
      setLead({
        ...lead,
        [name]: value,
        opportunity_type: "opf",
        property_reference: "",
      });
      setError({
        ...error,
        property_reference: false,
      });
      getDataProvenances({ type: value, opportunity_type: "opf" });
    } else if (name === "type" && value === "rent") {
      setLead({
        ...lead,
        [name]: value,
        opportunity_type: "opr",
        property_reference: "",
      });
      setError({
        ...error,
        property_reference: false,
      });
      getDataProvenances({ type: value, opportunity_type: "opr" });
    } else if (name === "opportunity_type") {
      setLead({
        ...lead,
        [name]: value,
      });
      getDataProvenances({ type: lead.type, opportunity_type: value });
    }
  };

  async function getDataProvenances(value) {
    setLoadingProvenance(true);
    const data = await getProvences(value);
    if (data !== undefined && !isEmpty(data.provenances))
      setProvenances(data.provenances);
    setLoadingProvenance(false);
  }

  async function validateRef() {
    if (lead.property_reference) {
      setLoadingReferent(true);
      const data = await validareRefencie(lead.property_reference);
      if (!data.meta.success) {
        setError({
          ...error,
          property_reference: true,
        });
      } else {
        setError({
          ...error,
          property_reference: false,
        });
      }
      setLoadingReferent(false);
    }
  }

  useEffect(() => {
    getDataProvenances({
      type: lead.type,
      opportunity_type: lead.opportunity_type,
    });
  }, []);

  return (
    <StyledModalContainer>
      <div className="form-container">
        <Card
          title="Nuevo candidato"
          isClosed
          size="20px"
          close={modal}
          fullweight
          margin
          heigth='fit-content'
          modal
        >
          {loading && <Loading />}
          <StyledFormContent>
            <div className="input-content">
              <FormElement
                label="Nombre *"
                error={error.name}
                errorMessage={messages.REQUIRED_FIELD}
              >
                <Input
                  type="text"
                  name="name"
                  value={lead.name}
                  onChange={handleOnchange}
                  error={error.name}
                  required
                />
              </FormElement>
              <FormElement
                label="Email"
                error={error.email}
                errorMessage={"Introducir al menos el teléfono"}
              >
                <Input
                  type="text"
                  name="email"
                  value={lead.email}
                  onChange={handleOnchange}
                  error={error.email}
                />
              </FormElement>
              <FormElement
                label="Teléfono"
                error={error.phone}
                errorMessage={"Introducir al menos el email"}
              >
                <Input
                  type="number"
                  name="phone"
                  value={lead.phone}
                  onChange={handleOnchange}
                  error={error.phone}
                />
              </FormElement>
              <FormElement label="Tipo *">
                <select onChange={handleOnchangeSelect} name="type">
                  <option value="mortgage">Hipoteca</option>
                  <option value="property">Inmueble</option>
                  <option value="rent">Alquiler</option>
                </select>
              </FormElement>
              {lead.type === "property" && (
                <FormElement label="Tipo de oportunidad *">
                  <select
                    onChange={handleOnchangeSelect}
                    name="opportunity_type"
                  >
                    <option value="oca">Compra</option>
                    <option value="ope-opc">Captación</option>
                  </select>
                </FormElement>
              )}
              {lead.type === "rent" && (
                <FormElement label="Tipo alquiler">
                  <select onChange={handleOnchange} name="type_rent">
                    <option value="owner">Propietario</option>
                    <option value="tenant">Inquilino</option>
                  </select>
                </FormElement>
              )}
              <FormElement
                label="Procedencia *"
                error={error.provenance}
                errorMessage={messages.REQUIRED_FIELD}
              >
                <Select
                  name="provenance"
                  placeholder="Seleccionar procedencia"
                  loading={loadingProvenance}
                  onChange={handleOnchange}
                  error={error.provenance}
                  items={provenances}
                />
              </FormElement>
              {lead.opportunity_type === "opf" && (
                <>
                  <FormElement label="Dirección">
                    <Select
                      name="address"
                      placeholder="Seleccionar dirección"
                      onChange={handleOnchange}
                      items={provinces}
                      notvalue
                      required
                    />
                  </FormElement>
                  <FormElement label="Interés *">
                    <select onChange={handleOnchange} name="interest">
                      <option value="Contratación Hipoteca">
                        Contratación Hipoteca
                      </option>
                      <option value="Hipoteca 100%">Hipoteca 100%</option>
                      <option value="Financiación Compra y Reforma">
                        Financiación Compra y Reforma
                      </option>
                      <option value="Subrogación de Hipoteca">
                        Subrogación de Hipoteca
                      </option>
                      <option value="Quiero Información">
                        Información
                      </option>
                      <option value="Cambia y Amplía">
                        Cambia y Amplía
                      </option>
                      <option value="Compra y Alquila">
                        Compra y Alquila
                      </option>
                    </select>
                  </FormElement>
                  <FormElement label="Búsqueda de inmueble *">
                    <select onChange={handleOnchange} name="home_recruitment">
                      <option value="Todavía no">Todavía no</option>
                      <option value="Estoy negociando la reserva">
                        Estoy negociando la reserva
                      </option>
                      <option value="La tengo reservada">
                        La tengo reservada
                      </option>
                    </select>
                  </FormElement>
                </>
              )}
              {((lead.type === "property" &&
                lead.opportunity_type === "ope-opc") ||
                lead.type === "rent") && (
                  <FormElement label="Dirección">
                    <Input
                      type="text"
                      name="address"
                      value={lead.address}
                      onChange={handleOnchange}
                    />
                  </FormElement>
                )}
              {lead.type === "property" && lead.opportunity_type === "oca" && (
                <FormElement
                  label="Referencia inmueble *"
                  error={error.property_reference}
                  errorMessage="Referencia no valida"
                >
                  <Input
                    type="text"
                    name="property_reference"
                    value={lead.property_reference}
                    loading={loadingReferent}
                    onChange={handleOnchange}
                    onBlur={validateRef}
                    error={error.property_reference}
                  />
                </FormElement>
              )}
              <FormElement label="Mensaje">
                <Textarea
                  name="message"
                  onChange={handleOnchange}
                  value={lead.message}
                  placeholder="Escriba aquí su mensaje"
                />
              </FormElement>
            </div>
            <StyledButtonContent size="0">
              <Button action={save}>Añadir</Button>
            </StyledButtonContent>
          </StyledFormContent>
        </Card>
      </div>
    </StyledModalContainer>
  );
}
