import React from 'react'
import styled from "styled-components";
import CardOther from '../../components/cardOther';
import { USEFULL_INFORMATION } from './dataCards';
import ItemCard from './ItemCard';

const StyledContainer = styled.div`
    display: flex;
    margin: 20px 0 0;

    .content-row {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;

        > p {
            margin: 0;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: #35374A;
        }
    }
`

const StyledContentCard = styled.div`
    display: flex;
    flex-wrap: wrap;
    //gap: 8px;
    margin: 20px 0 0;
`

const FourRow = () => {
    return (
        <StyledContainer>
            <CardOther title='Información útil a considerar'>
                <div className='content-row'>
                    <p>
                        Los stands se vuelven mucho más entretenidos y captan la atención de más personas cuando incluyen sorteos, y juegos que
                        fomentan la participación activa. Una excelente manera de lograr esto es a través de nuestra ruleta de premios, incluida
                        en el 'PACK FERIA'. Este elemento no solo es un imán para los niños, sino que también atrae a los padres y adultos, creando
                        un ambiente divertido y participativo.
                    </p>
                    <p>
                        Te ofrecemos una idea de los premios que podrías incluir en la ruleta, la cual tiene 14 lados y es completamente
                        personalizable. Esto significa que tienes la libertad de elegir los regalos que mejor se adapten a tu público. Aquí
                        te presentamos algunas propuestas de regalos.
                    </p>
                    <StyledContentCard>
                        {
                            USEFULL_INFORMATION.map((item, key) => {
                                return (
                                    <ItemCard key={key} data={item} hoverFalse/>
                                )
                            })
                        }

                    </StyledContentCard>
                </div>
            </CardOther>
        </StyledContainer>
    )
}

export default FourRow