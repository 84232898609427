import React from "react";

function TikTokSvg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="none"
            viewBox="0 0 25 25"
        >
            <g fill="#35374A" fillOpacity="0.35" clipPath="url(#clip0_35_1162)">
                <path d="M12.514 0C5.595 0 0 5.595 0 12.486 0 19.377 5.595 25 12.514 25 19.432 25 25 19.405 25 12.486 25 5.568 19.405 0 12.514 0zm0 23.622c-6.12 0-11.136-4.99-11.136-11.136 0-6.146 4.99-11.108 11.136-11.108 6.146 0 11.108 4.99 11.108 11.108 0 6.12-4.99 11.136-11.108 11.136z"></path>
                <path d="M15.353 5.706s-.028-.193-.028-.276v-.303h-2.453v9.73c0 1.13-.937 2.04-2.067 2.04-.331 0-.662-.083-.938-.221a2.083 2.083 0 01-1.13-1.847c0-1.13.91-2.067 2.068-2.067.22 0 .44.027.634.11v-2.48a4.52 4.52 0 00-.607-.056A4.494 4.494 0 006.34 14.83c0 1.571.8 2.922 1.985 3.749.717.468 1.571.771 2.508.771a4.494 4.494 0 004.493-4.492V9.895a5.772 5.772 0 003.39 1.075V8.545c-.634 0-1.268-.166-1.791-.524a3.371 3.371 0 01-1.544-2.288l-.027-.027z"></path>
            </g>
            <defs>
                <clipPath id="clip0_35_1162">
                    <path fill="#fff" d="M0 0H25V25H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default TikTokSvg;
