import axios from "axios";
import { getToken, removeToken } from "../libs/cookies";
import { messages } from "../libs/helpers/message";
import { apiURL } from "../libs/helpers/methods";
import { formatDateSend } from "../libs/helpers/methods";

export const tokenExpired = (res) => {
  if (
    res.response &&
    res.response.status &&
    parseInt(res.response.status) === 401
  ) {
    removeToken();
    window.location.href = "/login";
  } else if (
    res.response &&
    res.response.status &&
    parseInt(res.response.status) === 500
  ) {
    window.location.href = "/500";
  }
};

// Lead request
export const getApiLeads = ({
  limit,
  offset,
  type,
  team,
  user,
  opportunity_type,
  provenance,
  phone,
  email,
  from,
  to
}) => {
  return axios
    .get(
      `${apiURL()}/api/leads/list?limit=${limit}&offset=${offset}&opportunity_type=${opportunity_type}&type=${type}&team=${team}&user=${user}&provenance=${provenance}${phone ? `&phone=${phone}` : ''}${email ? `&email=${email}` : ''}${from ? `&from=${formatDateSend(from)}` : ''}${to ? `&to=${formatDateSend(to)}` : ''}`,
      { headers: { Authorization: `Bearer ${getToken()}` } }
    )
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      tokenExpired(err);
    });
};

export const getProvences = (data) => {
  return axios
    .get(
      `${apiURL()}/api/provenances/leads?type=${data.type}&opportunity_type=${data.opportunity_type}`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    )
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      tokenExpired(err);
    });
};

export const getApiFilters = ({ type, opportunity_type, team, user }) => {
  return axios
    .get(
      `${apiURL()}/api/leads/filters?type=${type}&opportunity_type=${opportunity_type}&team=${team}&user=${user}`,
      { headers: { Authorization: `Bearer ${getToken()}` } }
    )
    .then((res) => {
      if (res.data.meta.success) {
        return res.data.data;
      }
    })
    .catch((err) => {
      tokenExpired(err);
    });
};

// Dispatcher request

export const getApiDispatcher = ({ type, option }) => {
  return axios
    .get(`${apiURL()}/api/dispatcher/${type}/${option}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
    .then((res) => {
      if (res.data.meta.success) {
        if (option === "users") {
          return res.data.data.teams;
        } else {
          return res.data.data;
        }
      }
    })
    .catch((err) => {
      tokenExpired(err);
    });
};

export const updateApiDispatcherAdviser = ({ id, active, type }, openAlert) => {
  return axios
    .post(
      `${apiURL()}/api/dispatcher/${type}/user/${id}`,
      type === 'mortgages' ? active : { active },
      { headers: { Authorization: `Bearer ${getToken()}` } }
    )
    .then((res) => {
      if (res.data.meta.success) {
        openAlert(
          "success",
          `${type === "mortgages" ? "Hipotecas" : "Inmuebles"}`,
          messages.DISPATCHER_ASSES_UPDATE_SUCCESS
        );
      } else {
        openAlert(
          "error",
          `${type === "mortgages" ? "Hipotecas" : "Inmuebles"}`,
          messages.DISPATCHER_ASSES_UPDATE_ERROR
        );
      }
    })
    .catch((err) => {
      tokenExpired(err);
    });
};

export const updateApiDispatcherTeam = (
  { id, work_pressure, type },
  openAlert
) => {
  return axios
    .post(
      `${apiURL()}/api/dispatcher/${type}/team/${id}`,
      { work_pressure },
      { headers: { Authorization: `Bearer ${getToken()}` } }
    )
    .then((res) => {
      if (res.data.meta.success) {
        openAlert(
          "success",
          `${type === "mortgages" ? "Hipotecas" : "Inmuebles"}`,
          messages.DISPATCHER_TEAM_UPDATE_CHARGE_PRESSURE_SUCCESS
        );
      } else {
        openAlert(
          "error",
          `${type === "mortgages" ? "Hipotecas" : "Inmuebles"}`,
          messages.DISPATCHER_TEAM_UPDATE_ERROR
        );
      }
    })
    .catch((err) => {
      tokenExpired(err);
    });
};

export const updateApiAlgorithms = ({ active, type, id }, openAlert) => {
  axios
    .post(
      `${apiURL()}/api/dispatcher/${type}/algorithm/${id}`,
      { active },
      { headers: { Authorization: `Bearer ${getToken()}` } }
    )
    .then((res) => {
      if (res.data.meta.success) {
        openAlert(
          "success",
          `${type === "mortgages" ? "Hipotecas" : "Inmuebles"}`,
          messages.DISPATCHER_ALGORITMO_UPDATE_SUCCESS
        );
      } else {
        openAlert(
          "error",
          `${type === "mortgages" ? "Hipotecas" : "Inmuebles"}`,
          messages.DISPATCHER_ALGORITMO_UPDATE_ERROR
        );
      }
    })
    .catch((err) => {
      tokenExpired(err);
    });
};

//Onseginal
export const sendOnsignal = ({ user_id, country, language_code, device }) => {
  axios.post(
    `${apiURL()}/api/notifications-push/notification-push`,
    {
      user_id,
      country,
      language_code,
      device,
    },
    { headers: { Authorization: `Bearer ${getToken()}` } }
  );
};

//Rerefencia
export const validareRefencie = (property_reference) => {
  return axios
    .get(
      `${apiURL()}/api/properties/property/validate?property_reference=${property_reference}`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

//Rating
export const ratingTeam = (year) => {
  return axios
    .get(`${apiURL()}/api/rating/properties/offline/teams/${year}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
export const ratingUser = (year) => {
  return axios
    .get(`${apiURL()}/api/rating/properties/offline/users/${year}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const tokenCasafari = () => {
  return axios
    .get(`${apiURL()}/api/casafari/login`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getGroagendaTeamTypesAction = () => {
  return axios
    .get(`${apiURL()}/api/groagenda/teams/types`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      tokenExpired(err);
    });
};

export const getGroagendaTeamTypesTeamAction = (typeTeam) => {
  return axios
    .get(`${apiURL()}/api/groagenda/teams/type/${typeTeam}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      tokenExpired(err);
    });
};

export const getGroagendaTeamTypesUsersAction = (teamId) => {
  return axios
    .get(`${apiURL()}/api/groagenda/teams/${teamId}/users`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      tokenExpired(err);
    });
};


export const groagendaTeamTypesUserAction = (data) => {
  return axios
    .post(`${apiURL()}/api/groagenda/users`,
      data,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      tokenExpired(err);
    });
};

export const galleryPhotosAllAction = () => {
  return axios
    .get(`${apiURL()}/api/gallery/photos`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      tokenExpired(err);
    });
};

export const galleryPhotosSlugAction = (slug) => {
  return axios
    .get(`${apiURL()}/api/gallery/tag/${slug}/photos`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      tokenExpired(err);
    });
};

export const loadAgenciesAction = () => {
  return axios
    .get(`${apiURL()}/api/dispatcher/teams/type/inmobiliaria-offline`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      tokenExpired(err);
    });
};

export const loadOwnerAction = (teamId) => {
  return axios
    .get(`${apiURL()}/api/dispatcher/teams/${teamId}/users`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      tokenExpired(err);
    });
};

export const updateAssignLeadAction = (leadId, data) => {
  return axios
    .put(
      `${apiURL()}/api/leads/${leadId}`,
      data,
      { headers: { Authorization: `Bearer ${getToken()}` } }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      tokenExpired(err);
    });
};