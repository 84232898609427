import React from "react";

function DownLoadSvg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="25"
            fill="none"
            viewBox="0 0 28 25"
        >
            <g
                stroke="#FF671B"
                strokeLinecap="round"
                strokeLinejoin="round"
                clipPath="url(#clip0_554_3886)"
            >
                <path d="M26.759 15.991v5.992a2.364 2.364 0 01-2.365 2.364H3.587a2.364 2.364 0 01-2.364-2.364V15.99M13.992.652v18.803M7.979 14.7l6.013 4.755M20.004 14.7l-6.012 4.755"></path>
            </g>
            <defs>
                <clipPath id="clip0_554_3886">
                    <path
                        fill="#fff"
                        d="M0 0H26.843V25H0z"
                        transform="translate(.57)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default DownLoadSvg;
