import React from 'react';
import styled from 'styled-components';
import { pColor } from '../../styles/variables';

const StyledHeader = styled.h1`
    font-size: 19px;
    font-weight: 500;
    color: #788195;
    width: 100%;
    margin-right: 17px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);

    span {
        color: ${pColor};
    }

    &::before {
        content: '';
        width: 5px;
        height: 40px;
        background-color: #E4E6E9;
        display: flex;
        margin: 0 15px 0 0;
    }
    &::after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: #E4E6E9;
        display: flex;
        margin: 0 0 0 15px;
    }
`

const Title = ({text}) => {
    return <StyledHeader>{text}</StyledHeader>
};

export default Title;