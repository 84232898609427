import React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ direction }) => direction ? direction : 'row'};
  gap: 8px;
  margin-bottom: 15px;
  
  > div > div{
    border: ${({ error }) => error ? '1px solid #721c24' : 'none'};
    border-radius: 4px;
  }

  label {
    color: #595959;
    font-size: 14px;
    font-weight: ${({ labelweight }) => labelweight ? labelweight : '400'};;
    width: 260px;
    @media (max-width: 400px) {
      width: 130px;
    }
  }
  .form-element-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    p {
      margin: 0px;
      font-size: 12px;
      color: #721c24;
    }
  }
`;
const FormElement = ({ children, label, error, errorMessage, direction, labelweight }) => {
  return (
    <StyledContainer direction={direction} labelweight={labelweight} error={error ? 1 : 0}>
      <label>{label}</label>
      <div className="form-element-content">
        {children}
        {error && <p className="error">{errorMessage}</p>}
      </div>
    </StyledContainer>
  );
};

export default FormElement;
