import React from "react";

function Mp4Svg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="32"
            fill="none"
            viewBox="0 0 26 32"
        >
            <g clipPath="url(#clip0_553_3385)">
                <path
                    stroke="#9B59B6"
                    strokeLinejoin="round"
                    d="M24.683 11.091v16.467a3.1 3.1 0 01-3.1 3.1H4.44a3.1 3.1 0 01-3.1-3.1V4.442a3.1 3.1 0 013.1-3.1h10.493V7.99c0 1.712 1.388 3.1 3.102 3.1h6.648z"
                ></path>
                <path
                    stroke="#9B59B6"
                    strokeLinejoin="round"
                    d="M24.683 11.091h-6.647a3.101 3.101 0 01-3.102-3.1V1.34h0l9.75 9.75z"
                ></path>
                <path
                    fill="#9B59B6"
                    d="M10.354 18.272l-1.758 2.985-1.789-2.985H6.12v4.505h.798v-2.946l1.48 2.431h.374l1.48-2.47.007 2.985h.792l-.007-4.505h-.689zM15.146 18.465c-.286-.129-.628-.193-1.027-.193h-1.853v4.505h.836v-1.3h1.017c.399 0 .741-.065 1.027-.194.285-.128.505-.313.66-.553.155-.241.232-.526.232-.856 0-.33-.077-.616-.232-.856a1.489 1.489 0 00-.66-.554zm-.232 2.069c-.187.157-.464.235-.834.235h-.979v-1.79h.98c.368 0 .646.078.833.233.187.154.28.375.28.663 0 .287-.094.503-.28.66v-.001zM20.44 21.046h-.798v-.908h-.786v.907h-1.473l2.091-2.773h-.895l-2.22 2.902v.58h2.472v1.023h.811v-1.024h.798v-.707z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_553_3385">
                    <path fill="#fff" d="M0 0H26V32H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Mp4Svg;
