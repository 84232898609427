import React from 'react'
//COMPONENTS
import Layout from '../../components/layout'
import TitleAndDownload from '../../components/ui/TitleAndDownload'
//STYLES
import styled from "styled-components";

const StyledContainer = styled.div`    
    margin-top: 20px;
    img {
        max-width: 100%;
        width: 100%;
    }   
`

const FeePlan = () => {
  return (
    <Layout>
      <TitleAndDownload text={<>Plan de <b>Honorarios</b></>} link='/static/documents/plan-de-Honorarios.pdf' />
      <StyledContainer>
        <img src="/static/documents/fee-plan.webp" alt='fee plan' />
      </StyledContainer>
    </Layout>
  )
}

export default FeePlan