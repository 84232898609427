import React from 'react'
import Santander from '../../../components/svg/Santander'
import Grocasa from '../../../components/svg/Grocasa'
import ButtonLink from '../../../components/ui/ButtonLink'

const BenefitsPayroll = () => {
    return (
        <>
            <div className='icons bottom'>
                <div><Santander /></div>
                <div><Grocasa /></div>
            </div>
            <h2 className='top'>
                Benefíciate de nuestro acuerdo exclusivo con Santander recibiendo <b>hasta 400€ por ingresar tu nómina</b> con ellos
                a parte de múltiples descuentos y acceso a condiciones preferentes.
            </h2>
            <div className='top'>
                <ButtonLink
                    href='https://drive.google.com/file/d/1fNH5usVKGWKd4xapvlZnRgamQMbPCa1g/view?usp=drive_link'
                    target='_blank'
                >
                    Haz click para saber más
                </ButtonLink>
            </div>
        </>
    )
}

export default BenefitsPayroll