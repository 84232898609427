import React from "react";

function Store() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="43"
            height="36"
            fill="none"
            viewBox="0 0 43 36"
        >
            <path
                stroke="#FF671B"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3.028 8.92a4.844 4.844 0 00-1.159 3.157c0 2.702 2.196 4.89 4.907 4.89s4.907-2.188 4.907-4.89c0 2.702 2.202 4.89 4.907 4.89 2.706 0 4.907-2.188 4.907-4.89 0 2.702 2.202 4.89 4.907 4.89 2.706 0 4.907-2.188 4.907-4.89 0 2.702 2.208 4.89 4.913 4.89 2.706 0 4.907-2.188 4.907-4.89a4.864 4.864 0 00-1.182-3.18M3.028 8.92l4.704-7.054h27.536l4.681 7.03M3.028 8.92l-.006.005-.006.012m36.933-.04l.018.023m-1.947 7.706v17.508H5.357V16.759m0 6.62H38.02"
            ></path>
        </svg>
    );
}

export default Store;
