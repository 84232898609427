import React, { useEffect, useState } from "react";

//components
import Input from "../../components/ui/input";
import Button from "../../components/ui/button";
import Card from "../../components/card";
import Loading from "../../components/loading";

import {
    StyledModalContainer,
    StyledFormContent,
    StyledButtonContentOther,
} from "./styled";
import Textarea from "../../components/ui/textarea";
import Select from 'react-select';
import FormElement from "../../components/ui/FormElement";
import { loadAgenciesAction, loadOwnerAction, updateAssignLeadAction } from "../../actions";
import { messages } from "../../libs/helpers/message";
import { useAlert } from "../../hooks/context/alert";

const FormAssignLead = ({ modal, leadSelected, leads, setLeads }) => {
    const { openAlert } = useAlert();
    const [loading, setLoading] = useState(false);
    const [loadingOwner, setLoadingOwner] = useState(false);
    const [agencies, setAgencies] = useState(null);
    const [owners, setOwners] = useState(null);
    const [lead, setLead] = useState({
        address: leadSelected.address ?? '',
        message: leadSelected.message ?? '',
        team: "",
        owner: "",
    });
    const [error, setError] = useState({
        address: false,
        message: false,
        team: false,
        owner: false,
    });

    useEffect(() => {
        loadAgencies()
    }, []);

    const loadAgencies = () => {
        loadAgenciesAction()
            .then((res) => {
                if (res.teams?.length) {
                    setAgencies(res.teams.map((item) => { return { label: item.name, value: item.id } }))
                } else {
                    setAgencies([]);
                }
            })
            .catch(() => {
                setAgencies([]);
            })
    }

    const loadOwner = (value) => {
        setLoadingOwner(true)
        loadOwnerAction(value)
            .then((res) => {
                if (res.users?.length) {
                    setOwners(res.users.map((item) => { return { value: item.id, label: `${item.first_name} ${item.last_name}` } }))
                } else {
                    setOwners([]);
                }
            })
            .catch(() => {
                setOwners([]);
            })
            .finally(() => {
                setLoadingOwner(false)
            });
    }

    const handleOnchange = (e) => {
        const { value, name } = e.target;
        setLead({ ...lead, [name]: value });
        setError(prev => ({...prev, [name]: false}));

        if (name === 'team') {
            loadOwner(value)
        }
    };

    const validate = (send) => {
        let acum = 0
        if (!send) {
            setError({
                ...error,
                team: false,
                owner: false
            })
        }

        if (!lead.address) {
            setError(prev => ({
                ...prev,
                address: true
            }))
            acum++
        }
        if (!lead.team && send) {
            setError(prev => ({
                ...prev,
                team: true
            }))
            acum++
        }
        if (!lead.owner && send) {
            setError(prev => ({
                ...prev,
                owner: true
            }))
            acum++
        }

        if (acum > 0) {
            return false
        }

        return true
    }

    const updateLead = (e, send) => {
        e.preventDefault();
        const isValid = validate(send);
        if (isValid) {
            setLoading(true);
            let dataSend = send ?
                {
                    address: lead.address,
                    message: lead.message,
                    team: lead.team.value,
                    owner: lead.owner.value,
                    out_of_area: false
                }
                :
                {
                    address: lead.address,
                    message: lead.message,
                    out_of_area: true
                }
            updateAssignLeadAction(leadSelected.id, dataSend)
                .then((res) => {
                    if (res.data.data.lead) {
                        updateDataLead(res.data.data.lead, dataSend.out_of_area)
                    }
                    openAlert("success", "Candidatos", messages.LEAD_UPDATE_SUCCESS);
                })
                .catch(() => {
                    openAlert("error", "Candidatos", messages.LEAD_UPDATE_ERROR);
                })
                .finally(() => {
                    setLoading(false)
                    modal(null)
                });
        }
    }

    const updateDataLead = (data, outOfArea) => {
        const newData = leads.leads.map((item) => {
            if (data.id === item.id) {
                return {
                    ...item,
                    owner: data.owner,
                    team: data.team,
                    out_of_area: outOfArea
                }
            }
            return item
        })
        setLeads({ leads: newData, total: leads.total });
    }

    const handleChangeSelects = (value, name) => {
        if (value) {
            setLead({ ...lead, [name]: value });
            setError(prev => ({...prev, [name]: false}));
            if (name === 'team') {
                setLead(prev => ({ ...prev, owner: null }))
                loadOwner(value.value)
            }
        } else {
            if (name === 'team') {
                setLead(prev => ({ ...prev, team: null, owner: null }))
                setOwners([])
            } else {
                setLead(prev => ({ ...prev, owner: null }))
            }
        }
    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: state.isFocused ? '1px solid #052c65' : '1px solid #e9e9e9',
        }),
    };

    return (
        <StyledModalContainer>
            <div className="form-container">
                <Card
                    title="Asignar Candidato"
                    isClosed
                    size="20px"
                    close={modal}
                    fullweight
                    margin
                    heigth='fit-content'
                    modal
                >
                    {loading && <Loading background='#ffffff' />}
                    <StyledFormContent>
                        <div className="input-content">
                            <FormElement
                                label="Dirección"
                                error={error.address}
                                errorMessage={messages.REQUIRED_FIELD}
                                direction='column'
                                labelweight='500'
                            >
                                <Input
                                    type="text"
                                    name="address"
                                    value={lead.address}
                                    onChange={handleOnchange}
                                    error={error.address}
                                    required
                                    fullwidth
                                />
                            </FormElement>
                            <FormElement
                                label="Mensaje"
                                error={error.message}
                                errorMessage={messages.REQUIRED_FIELD}
                                direction='column'
                                labelweight='500'
                            >
                                <Textarea
                                    name="message"
                                    value={lead.message}
                                    onChange={handleOnchange}
                                    placeholder="Escriba aquí su mensaje"
                                    error={error.message}
                                />
                            </FormElement>
                            <FormElement
                                label="Oficina"
                                error={error.team}
                                errorMessage={messages.REQUIRED_FIELD}
                                direction='column'
                                labelweight='500'
                            >
                                <Select
                                    value={lead.team}
                                    onChange={(e) => handleChangeSelects(e, 'team')}
                                    options={agencies ?? []}
                                    placeholder='Selecciona una oficina'
                                    isSearchable={true}
                                    isClearable={true}
                                    isDisabled={agencies ? false : true}
                                    isLoading={agencies ? false : true}
                                    menuPosition="fixed"
                                    styles={customStyles}
                                />
                            </FormElement>
                            <FormElement
                                label="Responsable"
                                error={error.owner}
                                errorMessage={messages.REQUIRED_FIELD}
                                direction='column'
                                labelweight='500'
                            >
                                <Select
                                    value={lead.owner}
                                    onChange={(e) => handleChangeSelects(e, 'owner')}
                                    options={owners ?? []}
                                    placeholder="Selecciona un responsable"
                                    isSearchable={true}
                                    isClearable={true}
                                    isDisabled={(loadingOwner || !agencies) ? true : false}
                                    isLoading={(loadingOwner || !agencies) ? true : false}
                                    menuPosition="fixed"
                                    styles={customStyles}
                                />
                            </FormElement>
                        </div>
                        <StyledButtonContentOther size="0">
                            <Button action={(e) => updateLead(e, false)} styles='dark'>Fuera de área</Button>
                            <Button action={(e) => updateLead(e, true)} x>Añadir</Button>
                        </StyledButtonContentOther>
                    </StyledFormContent>
                </Card>
            </div>
        </StyledModalContainer>
    )
}

export default FormAssignLead