import React from 'react'
import styled from 'styled-components'

const StyledHeader = styled.div`
    ${({ backgroundDesktop }) => backgroundDesktop};        
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;  
    min-height: 270px;  
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
`

const Header = ({ backgroundDesktop }) => {
    return (
        <StyledHeader backgroundDesktop={backgroundDesktop} />
    )
}

export default Header