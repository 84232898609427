import styled from "styled-components";
import { pColor } from "../../styles/variables";

export const StyledContainer = styled.div`
    background: #ffffff;
    select {
        padding: 4px 10px;
        height: 25px;
        cursor: text;
        text-align: left;
        font-size: 13px;
        line-height: 1.5;
        color: #595959;
        background-color: #fff;
        background-image: none;
        border: 1px solid #e9e9e9;
        outline: none;
        border-radius: 4px;        
        height: 36px;
        cursor: pointer;
        width: 100%;
    }

    input {
        width: 100%;
    }

    .div-search {
        position: relative;

        > div {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(-50%, -50%);
        }
    }
`

export const StyledTeam = styled.div`
    background-image: url(/static/groagenda/background-team.webp);
    background-repeat: no-repeat;    
    background-position: center center;
    background-size: cover;
    margin: 20px 0;
    padding: 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #ffffff;
        margin: 0 0 10px;

        &.name {
            font-size: 24px;
            line-height: 30px;
            margin: 0;
        }

        &.top {
            margin-top: 20px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    img {
        max-width: 100%;
        max-height: 150px;
    }

    @media (max-width: 600px) {
        flex-direction: column;

        div:last-child {
            margin-top: 20px;
        }
    }
`

export const StyledCard = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    background: #fdfdfd;

    h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 25px;
        color: #35374A;
        margin: 0;
        text-align: center;

        &.name {
            font-size: 20px;
            line-height: 22px;
        }
        &.title-franchisee {
            color: #C79D46;   
        }
        &.title-no-franchisee {
            color: #35374A;   
        }
        &.team {
            color: #878787;
        }
    }

    .stages {
        position: absolute;
        left: 75%;
    }

    img {
        max-width: 100%;        
    }

    .header {
        background-image: ${({ franchisee }) => franchisee ? 'url(/static/groagenda/background-franchisee.webp)' : 'url(/static/groagenda/background-no-franchisee.webp)'};
        background-repeat: no-repeat;    
        background-position: center center;
        background-size: cover;
        display: flex;
        justify-content: center;

        img {            
            max-height: 120px;
        }

        .div-image {
            background: #ffffff;
            background-image: url(${({ background }) => background});
            background-repeat: no-repeat;    
            background-position: center center;
            background-size: cover;
            border-radius: 80px;
            border: ${({ franchisee }) => franchisee ? '#C79D46 5px outset' : '#f2f2f2 5px outset'};
            margin: 10px 0 -10px;
            z-index: 10;
            min-height: 120px;
            min-width: 120px;
        }              
    }

    .div-content, .div-email-phone, .div-contact {
        background: #FDFDFD;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;        
        padding: 15px;

        &.lineal {
            flex-direction: row;
        }

        svg {
            margin: 0 10px;
            cursor: pointer;            

            &:hover {
                color: ${pColor};

                g {
                    fill: ${pColor};
                    fill-opacity: 1;
                }
            }
        }
    }    

    .div-content {
        padding: 24px 15px 15px;
    }    
    
    hr {
        width: 100%;
        margin: 0;
    }

    

`

