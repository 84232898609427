import React from 'react'
//DATA
import { DATA } from '../../components/staff/data'
//COMPONENTS
import Layout from '../../components/layout'
import Title from '../../components/ui/title'
import Documents from '../../components/staff/Documents'

const DocsOfInterest = () => {
    const data = DATA['docsOfInterest']

    return (
        <Layout>
            <Title text={<span><b>Docs de interés</b></span>} />
            <Documents data={data.documents} notTitle />
        </Layout>
    )
}

export default DocsOfInterest