import React from 'react'
//COMPONENTS
import Store from '../../../components/svg/Store'
import Travel from '../../../components/svg/Travel'
import Home from '../../../components/svg/Home'
import Technology from '../../../components/svg/Technology'
import ButtonLink from '../../../components/ui/ButtonLink'
//STYLES
import styled from 'styled-components'
import Santander from '../../../components/svg/Santander'
import Grocasa from '../../../components/svg/Grocasa'

const StyledContainer = styled.div`
    // background: linear-gradient(122deg, rgba(255,255,255,1) 0%, rgba(238,105,113,1) 60%, rgba(227,6,19,1) 100%);
    background-image: url(/static/documents/benefits/santander-desktop.webp);
    background-repeat: no-repeat;    
    background-position: center center;
    background-size: cover;     
    min-height: 275px;
    display: flex;

    .content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
        align-items: center;
        padding: 20px;
        width: 100%;

        img {
            max-width: 100%;
        }        

        div:nth-child(2) {
            display: flex;
            justify-content: center;
        }

        h2, a {
            color: #35374A;
            margin: 0;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;

            &.top {
                margin-top: 10px;
            }
            &.bottom {
                margin-bottom: 10px;
            }       
        }

        a {            
            text-decoration: none;
            font-weight: 600;
            color: #fff;
        }
    }       

    .icons-div {
        display: flex;
        align-items: flex-end;
        gap: 20px;
    }

    @media (max-width: 1100px) {
        // background: linear-gradient(0deg, rgba(227,6,19,1) 0%, rgba(243,144,150,1) 86%, rgba(255,253,253,1) 100%);
        background-image: url(/static/documents/benefits/santander-mobile.webp);
        .content {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
        }
    }

    @media (max-width: 600px) {
        .icons-div {
            flex-direction: column;
            align-items: flex-start;
        }

        a {
            width: 100%;
        }
    }
`

const PayRollSantander = () => {
    return (
        <StyledContainer>
            <div className='content'>
                <div>
                    <div className='icons-div'>
                        <div><Santander /></div>
                        <div><Grocasa /></div>
                    </div>
                    <h2 style={{margin: '20px 0 40px'}}>
                        Benefíciate de nuestro acuerdo exclusivo con Santander recibiendo <b>hasta 400€ por ingresar tu nómina</b> con ellos
                        a parte de múltiples descuentos y acceso a condiciones preferentes.
                    </h2>
                    <div>
                        <ButtonLink
                            href='https://drive.google.com/file/d/1fNH5usVKGWKd4xapvlZnRgamQMbPCa1g/view?usp=drive_link'
                            target='_blank'
                        >
                            Haz click para saber más
                        </ButtonLink>
                    </div>
                </div>
                <div>
                    <img src="/static/documents/benefits/payroll.webp" alt="grofriends" />
                </div>
            </div>
        </StyledContainer>
    )
}

export default PayRollSantander