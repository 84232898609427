import React from 'react'
//COMPONENTS
import Store from '../../../components/svg/Store'
import Travel from '../../../components/svg/Travel'
import Home from '../../../components/svg/Home'
import Technology from '../../../components/svg/Technology'
import ButtonLink from '../../../components/ui/ButtonLink'
//STYLES
import styled from 'styled-components'

const StyledContainer = styled.div`
    background: linear-gradient(90deg, rgba(255,254,255,1) 0%, rgba(238,134,85,1) 40%, rgba(235,104,38,1) 100%);
    min-height: 275px;
    display: flex;

    .content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
        align-items: center;
        padding: 20px;
        width: 100%;

        img {
            max-width: 100%;
        }

        div:nth-child(2) {
            display: flex;
            justify-content: center;
        }

        h2, a {
            color: #35374A;
            margin: 0;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;

            &.top {
                margin-top: 10px;
            }
            &.bottom {
                margin-bottom: 10px;
            }       
        }

        a {            
            text-decoration: underline;
        }
    }

    @media (max-width: 1100px) {
        background: linear-gradient(0deg, rgba(235,104,40,1) 0%, rgba(255,254,253,1) 100%, rgba(255,254,254,1) 100%);
        .content {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
        }
    }
`

const GroFriend = () => {
    return (
        <StyledContainer>
            <div className='content'>
                <div>
                    <h2>
                        Gana dinero por trabajar con tus Groamigos
                    </h2>
                    <h2>
                        <b>
                            ¿Te gusta trabajar en Grocasa y crees que puedes tener un amigo o conocido al que le
                            interese nuestra oferta de empleo?
                        </b>
                    </h2>
                    <h2 className='top bottom'>
                        Si aportas un CV al Dpto. de RRHH que acaba siendo contratado, con su primera operación
                        facturada <b>tú podrás ganar 300€*</b>
                    </h2>
                    <h2>
                        Escribe a <a href='mailto:rrhh@grocasa.com'>rrhh@grocasa.com</a> para más información
                    </h2>
                </div>
                <div>
                    <img src="/static/gro-friends-big.webp" alt="grofriends" />
                </div>
            </div>
        </StyledContainer>
    )
}

export default GroFriend