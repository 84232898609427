export const permissionsMenu = {
  '/': { menu: 'dashboard' },
  '/casafari': { menu: 'casafari' },
  '/groagenda': { menu: 'groagenda' },
  '/documentos/beneficios': { menu: 'documents_offline', pos: 'benefits' },
  '/documentos/plan-carrera': { menu: 'documents_offline', pos: 'career_plan' },
  '/documentos/plan-honorarios': { menu: 'documents_offline', pos: 'fee_plan' },
  '/documentos/codigo-vestimenta': { menu: 'documents_offline', pos: 'dress_code' },
  '/documentos/plan-formacion': { menu: 'documents_offline', pos: 'training_plan' },
  '/staff/rrhh': { menu: 'staff', pos: 'rrhh' },
  '/staff/legal': { menu: 'staff', pos: 'legal' },
  '/staff/marketing-diseno': { menu: 'staff', pos: 'marketing-design' },
  '/staff/imagen-oficinas': { menu: 'staff', pos: 'office-image' },
  '/staff/it-crm': { menu: 'staff', pos: 'it-crm' },
  '/staff/formacion': { menu: 'staff', pos: 'formation' },
  '/staff/mentoring': { menu: 'staff', pos: 'mentoring' },
  '/staff/atencion-cliente': { menu: 'staff', pos: 'customer-service' },
  '/mis-herramientas/contenido-rrss': { menu: 'my_tools', pos: 'social_media_content' },
  '/mis-herramientas/dossier-servicios': { menu: 'my_tools', pos: 'services-dossier' },
  '/mis-herramientas/documentos-interes': { menu: 'my_tools', pos: 'documents_interest' },
  '/mis-herramientas/cartas-clientes': { menu: 'my_tools', pos: 'cartas_clientes' },
  '/grogames/grochampions': { menu: 'grogames', pos: 'champions' },
  '/grogames/ligas': { menu: 'grogames', pos: 'leagues' },
  '/grogames/catalogo-premios': { menu: 'grogames', pos: 'awards_catalog' },
  '/leads': { menu: 'leads' },
  '/contactos-sede': { menu: 'contacts_headquarters' },
  '/galeria-fotos': { menu: 'photos_gallery' },
  '/marketplace': { menu: 'marketplace' },
  '/perfil': { menu: 'users', pos: 'users.profile' },
  '/dispatcher/equipos-hipotecas': { menu: 'dispatcher', pos: 'dispatcher.mortgages.teams' },
  '/dispatcher/asesores-hipotecas': { menu: 'dispatcher', pos: 'dispatcher.mortgages.users' },
  '/dispatcher/equipos-inmuebles': { menu: 'dispatcher', pos: 'dispatcher.properties.teams' },
  '/dispatcher/asesores-inmuebles': { menu: 'dispatcher', pos: 'dispatcher.properties.users' },
  '/mis-recursos/ferias-y-fiestas': { menu: 'my_resources', pos: 'festivals-and-fairs' },
  '/notification': { menu: 'groagenda' },

}
