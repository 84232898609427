import React from 'react';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'
import styled from 'styled-components';

const StyledMenu = styled(CloseOutline)`
    color: #797979;
    width: 24px;
    height: 24px;
    cursor: pointer;
`
export default function Close({ close }) { return <StyledMenu onClick={close ? () => close() : () => { }} /> }
