import React from "react";
import ReactDOM from "react-dom/client";
import { StyleSheetManager } from "styled-components";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ModalProvider } from "./hooks/context/modal";
import TagManager from 'react-gtm-module'


//template
import App from "./App";

//styles
import "./index.css";

Sentry.init({
  dsn:
    "https://452cdc81f740462da1ca5473a9840106@o455531.ingest.sentry.io/5529006",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const tagManagerArgs = {
  gtmId: 'GTM-KBWNZ2X9'
}
if (process.env.REACT_APP_NODE_ENV === 'production') {
  TagManager.initialize(tagManagerArgs)
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <StyleSheetManager shouldForwardProp={() => true}>
      <ModalProvider>
        <App />
      </ModalProvider>
    </StyleSheetManager>
  </React.StrictMode>
);
