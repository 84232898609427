import React from 'react'
import ReactPlayer from 'react-player'
import styled from "styled-components";
import { useModal } from '../../hooks/context/modal';
import Button from '../../components/ui/button';
import ButtonLink from '../../components/ui/ButtonLink';

const StyledHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 40px;
    margin-bottom: 48px;

    .first {
        display: flex;

        img {
            max-width: 100%;
        }
    }

    .last {
        display: flex;
        flex-direction: column;
        gap: 32px;

        > div:nth-child(1) {
            display: flex;
            gap: 32px;
            justify-content: end;
        }

        > div:nth-child(2) {
            display: flex;
            flex-direction: column;
            gap: 24px;
            
            p {
                color: #35374A;
                margin: 0;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }
        }
    }

    @media (max-width: 1050px) {
        grid-template-columns: 1fr;
        margin-bottom: 32px;

        .first {
            justify-content: center;
        }

        .last {                
            > div:nth-child(1) {                
                justify-content: center;
            }
        }
    }

    @media (max-width: 600px) {
        .last {                
            > div:nth-child(1) {                
                flex-direction: column;
                gap: 24px;

                button, a {
                    width: 100%;
                }
            }
        }
    }
`

const StyledVideo = styled.div`
    position: relative;
    padding-top: 56.25%; 

    .play {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }    
    
    .react-player {
        position: absolute;
        top: 0;
        left: 0;
    }

    @media (max-width: 600px) {
        padding-top: 85.25%; 
    }
`

const Header = () => {
    const { setOpen, setComponent } = useModal()

    const selectComponent = () => {
        return (
            <StyledVideo data-cy="video-header-desktop-mortgage-assistant-0000">
                <ReactPlayer
                    url="/static/marketplace/video_marketplace.mp4"
                    controls={true}
                    playing={true}
                    muted
                    width='100%'
                    height='100%'
                    className="react-player"
                    loop={true}
                />
            </StyledVideo>
        )
    }

    return (
        <StyledHeader>
            <div className='first'>
                <img src="/static/marketplace/logo-marketplace.webp" alt="logo" />
            </div>
            <div className='last'>
                <div>
                    <Button action={() => {
                        setComponent(selectComponent());
                        setOpen(true)
                    }}
                    >
                        ¿Cómo funciona?
                    </Button>
                    <ButtonLink
                        href='/static/marketplace/pdf_marketplace.pdf'
                        target='_blank'
                    >
                        Guia proveedores PDF
                    </ButtonLink>
                </div>
                <div>
                    <p>Mas allá de la venta, nuestro compromiso con el cliente continúa.</p>
                    <p>
                        Ofreciéndoles estos servicios, <b>a parte de conseguir ingresos adicionales, podrás facilitarles
                            la transición del cambio de vivienda o mejorar el estado del inmueble para su venta.</b>
                    </p>
                </div>
            </div>
        </StyledHeader>
    )
}

export default Header