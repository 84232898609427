import React from "react";
import { Link } from "react-router-dom";
//components
import Button from "../../components/ui/button";
//hooks
import { useModal } from "../../hooks/context/modal";
//styles
import styled from "styled-components";

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1120px;

    img {
        max-width: 100%;
        max-height: 85px;
    }

    .header {
        background: ${({ background }) => background};
        display: flex;
        justify-content: center;
        padding: 20px 0;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 40px;

        h2 {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 23px;      
            text-align: center;
            margin: 0 0 20px;     
        }

        a {
            color: #212529;
            text-decoration: underline;
            font-weight: bold;
        }

        button {
            margin-top: 20px;
        }     
        
        @media (max-width: 600px) {
            margin: 20px;
        }
    }    
`

const ShowInfoGroLeagues = ({ img, content, textButton, link, externalLink, background }) => {
    const { setOpen } = useModal()
    return (
        <StyledContainer background={background}>
            <div className="header">
                <div>
                    <img className="card-img" src={img} alt="img-card" />
                </div>
            </div>
            <div className="content">
                <div dangerouslySetInnerHTML={{ __html: content }} />
                {
                    externalLink ?
                        <Button action={() => {
                            setOpen(false);
                            window.open(link, '_blank')
                        }}>
                            {textButton}
                        </Button>
                        :
                        <Link to={link}>
                            <Button action={() => setOpen(false)}>
                                {textButton}
                            </Button>
                        </Link>
                }
            </div>
        </StyledContainer>
    )
}

export default ShowInfoGroLeagues