import React from 'react'
//COMPONENTS
import SearchInput from '../../components/icons/SearchInput'
import { StyledContainer } from './styles'
import Input from '../../components/ui/input'
import LoadingSmall from '../../components/loadingSmall'

const Filters = ({ state, handleOnchangeSelect, handleEventInput }) => {
    return (
        <StyledContainer>
            <div className='row p-3'>
                <div className='col-md-4 py-2 py-md-0'>
                    <select className="form-select" value={state.teamsTypes} onChange={handleOnchangeSelect} name="teamsType">
                        <option value="">Selecciona...</option>
                        {
                            state.dataTeamsTypes.map(({ value, name }, key) => {
                                return (
                                    <option value={value} key={key}>{name}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className='col-md-4 py-2 py-md-0'>
                    {
                        state.loadingTeamsTypeTeam ?
                            <LoadingSmall />
                            :
                            <select className="form-select" value={state.teamsTypeTeam} onChange={handleOnchangeSelect} name="teamsTypeTeam">
                                <option value="">Selecciona...</option>
                                {
                                    state.dataTeamsTypeTeam.map(({ id, name }, key) => {
                                        return (
                                            <option value={id} key={key}>{name}</option>
                                        )
                                    })
                                }
                            </select>
                    }
                </div>
                <div className='col-md-4 py-2 py-md-0'>
                    <div className="div-search">
                        <Input
                            type="text"
                            name="searchUser"
                            value={state.searchInput}
                            onChange={handleEventInput}
                        />
                        <div>
                            <div>
                                {
                                    state.loadingSearchInput ?
                                        <LoadingSmall />
                                        :
                                        <SearchInput />
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledContainer>
    )
}

export default Filters