import React from "react";

function FacebookSvg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="none"
            viewBox="0 0 25 25"
        >
            <g clipPath="url(#clip0_35_1158)">
                <path
                    fill="#35374A"
                    fillOpacity="0.35"
                    d="M12.486 0C5.596 0 0 5.595 0 12.486 0 19.377 5.595 25 12.486 25 19.377 25 25 19.405 25 12.486 25 5.568 19.405 0 12.486 0zm1.847 23.429v-7.442h2.756l.524-3.418h-3.28v-2.205c0-.937.469-1.847 1.93-1.847h1.488V5.623s-1.35-.22-2.646-.22c-2.702 0-4.466 1.626-4.466 4.603v2.59H7.636v3.418h3.005v7.442c-5.265-.882-9.262-5.43-9.262-10.942 0-6.147 4.99-11.136 11.108-11.136 6.12 0 11.136 4.99 11.136 11.108 0 5.513-4.024 10.06-9.289 10.943z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_35_1158">
                    <path fill="#fff" d="M0 0H25V25H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default FacebookSvg;
