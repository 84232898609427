import React from "react";

function SettingsFranchisee() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 21 19"
        >
            <path
                stroke="#C79D46"
                strokeLinecap="round"
                strokeMiterlimit="10"
                d="M14.686 5.302a3.9 3.9 0 01-1.33 2.94 3.891 3.891 0 01-2.483.973l-.1.001h-.102a3.923 3.923 0 01-2.483-.974 3.9 3.9 0 01-1.33-2.94 3.91 3.91 0 013.914-3.914 3.91 3.91 0 013.914 3.914zM4.842 16.075a12.146 12.146 0 005.93 1.536c2.153 0 4.175-.557 5.93-1.536M1.423 13.399c1.042.439 2.157.737 3.322.87M16.8 14.268c1.165-.132 2.28-.43 3.321-.87M7.718 2.854a2.903 2.903 0 10-.001 4.897M13.826 2.854a2.903 2.903 0 11.001 4.897"
            ></path>
            <path
                stroke="#C79D46"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="0.777"
                d="M10.045 9.215a1.435 1.435 0 00-.082-.083"
            ></path>
            <path
                stroke="#C79D46"
                strokeLinecap="round"
                strokeMiterlimit="10"
                d="M8.188 8.242a3.194 3.194 0 00-.487-.036H4.615a3.226 3.226 0 00-3.226 3.225v1.501c0 .16.01.315.034.467"
            ></path>
            <path
                stroke="#C79D46"
                strokeLinecap="round"
                strokeMiterlimit="10"
                d="M16.703 16.075a4.06 4.06 0 00.096-.884v-1.899a4.077 4.077 0 00-4.075-4.077H8.82a4.077 4.077 0 00-4.076 4.077v1.9c0 .304.033.6.097.883"
            ></path>
            <path
                stroke="#C79D46"
                strokeLinecap="round"
                strokeMiterlimit="10"
                d="M20.121 13.399c.024-.152.035-.308.035-.467v-1.501a3.226 3.226 0 00-3.227-3.225h-3.086c-.165 0-.328.012-.486.036"
            ></path>
            <path
                stroke="#C79D46"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="0.777"
                d="M11.582 9.132a1.46 1.46 0 00-.082.083"
            ></path>
        </svg>
    );
}

export default SettingsFranchisee;
