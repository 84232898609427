import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import Arrow from '../icons/Arrow'
import styled from 'styled-components'
import { pColor } from '../../styles/variables'

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 50px 0 0;
    
    h2 {
        color: #FF671B;
        margin: 0;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0 40px 30px;
    }
`

const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;   
    padding: 5px 20px 20px;    
`

const StyledQuestion = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    cursor: pointer;

    p {
        color: #35374A;
        margin: 0;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }

    svg {
        transform: ${({ active }) => active ? 'rotate(0deg)' : 'rotate(90deg)'};
    }
`

const StyledAnswer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-bottom: ${({ active }) => active ? '1px solid rgba(0, 0, 0, 0.07);' : '0'};
    max-height: ${({ active }) => active ? '100vh' : '0'};
    overflow: hidden;        
    padding: ${({ active }) => active ? '20px' : '0 20px'};
    transition: ${({ active }) => active ? 'max-height 1s ease-in-out' : 'max-height, padding 0.5s ease-in-out'};        
    
    img {
        max-width: 100%;
    }

    ul, ol {
        display: flex;
        flex-direction: column;
        margin: 0;
        gap: 15px;
    }

    p, li {
        color: #35374A;
        margin: 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }

    a {
        text-decoration: none;
        color: ${pColor};
    }
`

const StyledVideo = styled.div`
    position: relative;
    padding-top: 56.25%; 

    .play {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }    
    
    .react-player {
        position: absolute;
        top: 0;
        left: 0;
    }

    @media (max-width: 600px) {
        padding-top: 85.25%; 
    }
`

const Faqs = ({ data }) => {
    const [state, setState] = useState(data);

    const updateData = (key) => {
        const newData = state.map((item, i) => {
            if (key === i) {
                return {
                    ...item,
                    active: !item.active
                }
            }
            return item
        })
        setState(newData)
    }

    return (
        <StyledContainer>
            <h2>Preguntas frecuentes</h2>
            <StyledContent>
                {
                    state.map((item, key) => {
                        return (
                            <div key={key}>
                                <StyledQuestion onClick={() => updateData(key)} active={item.active}>
                                    <p dangerouslySetInnerHTML={{ __html: item.question }} />
                                    <div>
                                        <Arrow />
                                    </div>
                                </StyledQuestion>
                                {
                                    item.type === 'video' ?
                                        item.active ?
                                            <StyledVideo data-cy="video-header-desktop-mortgage-assistant-0000">
                                                <ReactPlayer
                                                    url={item.answer}
                                                    controls={true}
                                                    playing={true}
                                                    muted
                                                    width='100%'
                                                    height='100%'
                                                    className="react-player"
                                                    loop={true}
                                                />
                                            </StyledVideo>
                                            :
                                            null
                                        :
                                        <StyledAnswer dangerouslySetInnerHTML={{ __html: item.answer }} active={item.active} />
                                }
                            </div>
                        )
                    })
                }
            </StyledContent>
        </StyledContainer>
    )
}

export default Faqs