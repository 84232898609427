import React from "react";
//COMPONENTs
import Close from "../icons/close";
//HOOKS
import { useModal } from "../../hooks/context/modal";
//STYLES
import styled from "styled-components";

const StyledContainer = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  background-color: rgba(147, 147, 149, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-container {
    &::-webkit-scrollbar{
      display: none;
    }
    border-radius: 5px;
    width: 1024px;
    height: 100%;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 16px;
    .modal-content {
      position: relative;
      background-color: #ffffff;
      height: auto;
      border: 0;
      margin: 24px 0px;      
      max-height: calc(100% - 48px);
      .form-close-modal {
        position: absolute;
        top: 10px;
        z-index: 20;
        right: 10px;
        cursor: pointer;        

        svg {
          color: #ffffff;
        }
      }
      .modal-header-text {
        position: relative;
        width: calc(100% - 40px);
        padding: 40px 20px 20px;
        p {
          margin: 0;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
`;

const Modal = ({
    pageForm,
    pathForm,
    type,
    typeForm,
    message,
    property_reference,
    insightSlug
}) => {
    const { setOpen, component } = useModal();

    function closeModalParent(e) {
        if (e.target === document.getElementById("modal-grocasa")) {
            setOpen(false);
        }
    }

    return (
        <StyledContainer
            id="modal-grocasa"
            onClick={(e) => closeModalParent(e)}
        >
            <div className="modal-container">
                <div className="modal-content" data-cy="moda-content-dsw32">
                    <span
                        className="form-close-modal"
                        onClick={() => setOpen(false)}
                        data-cy="modal-close-content-fd5"
                        id="modalClose"
                    >
                        <Close color={"#fff"} />
                    </span>                    
                    {component}
                </div>
            </div>
        </StyledContainer>
    );
};

export default Modal;
