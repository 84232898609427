import React from 'react'
import { StyledTeam } from './styles'
import { formatShowPhone } from '../../libs/helpers/methods'

const Team = ({ data }) => {
    return (
        <StyledTeam>
            <div>
                <h2 className='name'><b>{data.name}</b></h2>
                {
                    data.address ?
                        <h2 className='top'>{data.address}</h2> :
                        null
                }
                {
                    data.phone ?
                        <h2><b>{data.phone ? formatShowPhone(data.phone.toString()) : ''}</b></h2> :
                        null
                }
                {
                    data.email ?
                        <h2>{data.email}</h2> :
                        null
                }
            </div>
            <div>
                <img src={data.picture} alt='team' />
            </div>

        </StyledTeam>
    )
}

export default Team