import styled from 'styled-components';
import { pColor } from '../../styles/variables';

export const StyledContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin-top: 20px;

    .div-columns {
        display: flex;
        flex-direction: column;

        h2 {
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 30px
            margin: 0;
            color: #FF671B;
        }

        .div-contact {
            background: #FDFDFD;
            padding: 20px;
            margin-top: 10px;

            > div {
                display: grid;
                grid-template-columns: 40% 60%;
                align-items: center;
                border-bottom: 1px solid rgba(147, 147, 149, 0.2);    
                padding: 15px 0 10px;        

                svg {
                    width: 20px;
                    height: 20px;
                    margin-right: 20px;
                }

                h2 {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    margin: 0;
                    color: #35374A;

                    &.left {
                        margin-left: 40px;
                    }
                }

                .link-email {
                    display: flex;  
                    align-items: center;
                    text-decoration: none;

                    &:hover {
                        h2 {
                            color: ${pColor};
                        }
                        svg {   
                            cursor: pointer;                 
                            color: ${pColor};
                
                            g {
                                fill: ${pColor};
                                fill-opacity: 1;
                            }
                        }
                    }
                }                
                
                @media (max-width: 600px) {
                    grid-template-columns: 1fr;

                    h2 {      
                        &.left {
                            margin-left: 30px;
                        }
                    }

                    svg {
                        margin-right: 10px;
                    }

                    > div:last-child {
                        margin-top: 15px;
                    }   
                }
            }
        }
    }

    @media (max-width: 1100px) {
        grid-template-columns: repeat(1, 1fr);
    }
`
