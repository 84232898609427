import React from "react";

function BlogInternoSvg({ color }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            fill="none"
            viewBox="0 0 19 19"
        >
            <path
                fill={color}
                d="M7.24 17.933l-.3-.4-.003.001.302.399zm4.056-3.045l-.3-.4.3.4zM2.875 1v.5V1zm3.892 9.176a.5.5 0 00.714.7l-.714-.7zm5.466-4.145a.5.5 0 10-.714-.7l.714.7zm-4.875-.857a.5.5 0 000 1v-1zm4.518.5h.5a.5.5 0 00-.5-.5v.5zm-.5 4.607a.5.5 0 101 0h-1zM.5 12.889c0 1.323 1.054 2.412 2.375 2.412v-1c-.75 0-1.375-.623-1.375-1.412h-1zm2.375 2.412H6.37v-1H2.875v1zm3.495 0a.158.158 0 01-.161-.155h1a.842.842 0 00-.84-.845v1zm-.161-.155v2.514h1v-2.514h-1zm0 2.514c0 .67.766 1.1 1.332.671l-.604-.797a.178.178 0 01.184-.015c.05.026.088.081.088.14h-1zm1.33.673l4.057-3.045-.6-.8-4.057 3.045.6.8zm4.057-3.045a.058.058 0 01-.034.012v-1a.942.942 0 00-.566.188l.6.8zm-.034.012h4.563v-1h-4.563v1zm4.563 0c1.321 0 2.375-1.089 2.375-2.412h-1c0 .79-.624 1.412-1.375 1.412v1zM18.5 2.91C18.5 1.588 17.446.5 16.125.5v1c.75 0 1.375.622 1.375 1.411h1zM16.125.5H2.875v1h13.25v-1zM2.875.5C1.554.5.5 1.588.5 2.911h1c0-.789.624-1.411 1.375-1.411v-1zM7.48 10.876l4.752-4.845-.714-.7-4.752 4.845.714.7zm-.123-4.702h4.518v-1H7.358v1zm5.018 4.107V5.674h-1v4.607h1zM.5 2.91v9.979h1V2.91h-1zm18 9.977V2.911h-1v9.977h1z"
            ></path>
        </svg>
    );
}

export default BlogInternoSvg;
