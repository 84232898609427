import React from "react";

function GroagendaSvg({ color }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="18"
            fill="none"
            viewBox="0 0 19 18"
        >
            <path
                fill={color}
                d="M18.5 3.584a.5.5 0 00-1 0h1zm-1 2.224a.5.5 0 001 0h-1zm1 2.08a.5.5 0 00-1 0h1zm-1 2.223a.5.5 0 101 0h-1zm1 2.082a.5.5 0 10-1 0h1zm-1 2.223a.5.5 0 001 0h-1zM4.252 12.123a.5.5 0 101 0h-1zm7.027 0a.5.5 0 101 0h-1zM2.93 1.5h10.672v-1H2.93v1zm10.672 0c.854 0 1.43.593 1.43 1.192h1c0-1.27-1.152-2.192-2.43-2.192v1zm1.43 1.192v12.616h1V2.692h-1zm0 12.616c0 .598-.576 1.192-1.43 1.192v1c1.278 0 2.43-.922 2.43-2.192h-1zm-1.43 1.192H2.93v1h10.672v-1zm-10.672 0c-.854 0-1.43-.594-1.43-1.192h-1c0 1.27 1.152 2.192 2.43 2.192v-1zM1.5 15.308V2.692h-1v12.616h1zm0-12.616c0-.599.576-1.192 1.43-1.192v-1C1.652.5.5 1.422.5 2.692h1zm16 .892v2.224h1V3.584h-1zm0 4.304v2.223h1V7.888h-1zm0 4.305v2.223h1v-2.223h-1zM9.523 6.8c0 .514-.5 1.04-1.257 1.04v1c1.182 0 2.257-.854 2.257-2.04h-1zm-1.257 1.04c-.758 0-1.256-.526-1.256-1.04h-1c0 1.186 1.074 2.04 2.256 2.04v-1zM7.01 6.799c0-.515.498-1.04 1.256-1.04v-1c-1.182 0-2.256.853-2.256 2.04h1zm1.256-1.04c.758 0 1.257.525 1.257 1.04h1c0-1.187-1.075-2.04-2.257-2.04v1zm-3.014 6.364c0-.514.499-1.04 1.256-1.04v-1c-1.182 0-2.256.854-2.256 2.04h1zm1.256-1.04h3.515v-1H6.508v1zm3.515 0c.757 0 1.256.525 1.256 1.04h1c0-1.186-1.074-2.04-2.256-2.04v1z"
            ></path>
        </svg>
    );
}

export default GroagendaSvg;
