export const stateInitial = {
    loading: true,
    dataTeamsTypes: [],
    teamsTypes: '',
    dataTeamsTypeTeam: [],
    teamsTypeTeam: '',
    loadingTeamsTypeTeam: false,
    data: null,
    searchInput: '',
    loadingSearchInput: false,
}