import React from 'react'
//COMPONENTS
import Layout from '../../components/layout'
import Title from '../../components/ui/title'
//STYLES
import styled from 'styled-components'
import Button from '../../components/ui/button'

const StyledContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    @media (max-width: 1024px) {
        grid-template-columns: repeat(1, 1fr);
    }
`

const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
    padding: 20px;
    background: #FDFDFD;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);

    h2 {
        color: #FF671B;
        margin: 0;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    img {
        max-width: 100%;
    }

    .buttons {
        display: flex;
        justify-content: end;
        gap: 20px;
    }

    a {
        color: #fff;
        text-decoration: none;
        font-weight: 600;
    }

    @media (max-width: 1024px) {
        width: 100%;
    }

    @media (max-width: 600px) {
        .buttons {
            flex-direction: column;
            gap: 15px;

            button {
                width: 100%;
            }
        }
    }
`

const DossierServices = () => {

    return (
        <Layout>
            <Title text={<span><b>Dossier de servicios</b></span>} />
            <StyledContainer>
                <StyledContent>
                    <h2>Versión Castellano</h2>
                    <img src="/static/tools/dossier-servicios/portada-dossier-servicios-cast.webp" alt='dossier-cast' />
                    <div className='buttons'>
                        <Button>
                            <a href='/static/tools/dossier-servicios/dossier-servicios-cast.pdf' target='_blank'>
                                Ver online
                            </a>
                        </Button>
                        <Button>
                            <a href='/static/tools/dossier-servicios/dossier-servicios-cast.pdf' download>
                                Descargar PDF
                            </a>
                        </Button>
                    </div>
                </StyledContent>
                <StyledContent>
                    <h2>Versión Catalán</h2>
                    <img src="/static/tools/dossier-servicios/portada-dossier-servicios-cat.webp" alt='dossier-cast' />
                    <div className='buttons'>
                        <Button>
                            <a href='/static/tools/dossier-servicios/dossier-servicios-cat.pdf' target='_blank'>
                                Ver online
                            </a>
                        </Button>
                        <Button>
                            <a href='/static/tools/dossier-servicios/dossier-servicios-cat.pdf' download>
                                Descargar PDF
                            </a>
                        </Button>
                    </div>
                </StyledContent>
            </StyledContainer>
        </Layout>
    )
}

export default DossierServices