import React from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components';

const StyledLink = styled(Link)`
    display: block;
    font-size: 13px;
    color: #595959;
    line-height: 1.1;
    text-decoration: ${({leane}) => leane ? 'underline' : 'none' };
`

const LinkCustom = ({ route, text, leane }) => {
    return (
        <StyledLink to={route} leane={leane ? 1 : 0}>
            {text}
        </StyledLink>
    );
};

export default LinkCustom;