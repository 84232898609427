import React from 'react'
import styled from 'styled-components'
import { pColor } from '../../styles/variables';
import Email from '../svg/Email'

const StyledContainer = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin-top: 270px;    

    .text {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 30px 20px;
        background: #fff;
        margin-top: -180px;
        z-index: 2;
        max-height: 750px;
        overflow: auto;
        height: max-content;

        h2 {
            color: #FF671B;            
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0;
        }

        p, li {
            color: #35374A;
            margin: 0;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin: 0;
        }
    }

    .contatcs {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px 40px;

        h2 {
            color: #FF671B;
            margin: 0;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        img {
            max-width: 100%;
            width: 63px;
        }

        p {
            color: #35374A;
            margin: 0;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            &.sub-contac {               
                font-size: 12px;
                font-weight: 400;
                line-height: normal;
            }

            &.email {                
                font-size: 18px;
                font-weight: 400;
                line-height: 140%;
            }
        }

        > div, a {
            display: flex;
            align-items: center;
            gap: 20px
        }

        a {
            text-decoration: none;
            &:hover {
                p {
                    color: ${pColor};
                }
                svg {   
                    cursor: pointer;                 
                    color: ${pColor};
        
                    g {
                        fill: ${pColor};
                        fill-opacity: 1;
                    }
                }
            }
        }

        hr {    
            width: 100%;
            border: none;
            height: 1px;  
            color: rgba(147, 147, 149, 0.80);
            background-color: rgba(147, 147, 149, 0.80);              
        }
    }

    @media (max-width: 850px) {
        grid-template-columns: 1fr;

        .text {
            h2 {
                font-size: 18px;
                line-height: normal;
            }

            p, li {
                font-size: 12px;                
                line-height: 140%;
            }
        }

        .contatcs {
            p {
                font-size: 12px;

                &.sub-contac {               
                    font-size: 9px;                    
                }
    
                &.email {                
                    font-size: 12px;                    
                }
            }
        }
    }
`

const Information = ({ data }) => {
    return (
        <StyledContainer>
            <div className='text' dangerouslySetInnerHTML={{ __html: data.content }} />
            <div className='contatcs'>
                <h2>Equipo</h2>
                {
                    data.contactTeam.team.map((item, key) => {
                        return (
                            <div key={key}>
                                <div>
                                    <img src={item.img ? item.img : '/static/staff/list-circle.svg'} alt={item.name} />
                                </div>
                                <div>
                                    <p>{item.name}</p>
                                    <p className='sub-contac'>{item.position}</p>
                                </div>
                            </div>
                        )
                    })
                }
                <hr />
                <h2>Contacto</h2>
                {
                    data.contactTeam.contact.map((item, key) => {
                        return (
                            <a href={`mailto:${item.email}`} key={key}>
                                <Email />
                                <p className='email'>{item.email}</p>
                            </a>
                        )
                    })
                }
            </div>
        </StyledContainer>
    )
}

export default Information