import React from 'react'
import { DATA } from '../../components/staff/data'
//COMPONENTS
import Layout from '../../components/layout'
import Header from '../../components/staff/Header'
import Information from '../../components/staff/Information'
import Documents from '../../components/staff/Documents'
import Faqs from '../../components/staff/Faqs'


const Legal = () => {
    const data = DATA['legal']

    return (
        <Layout>
            <Header backgroundDesktop={`background-image: url(${data.image})`} />
            <Information data={data.information} />
            <Documents data={data.documents} />
            <Faqs data={data.faqs} />
        </Layout>
    )
}

export default Legal