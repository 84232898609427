import React from "react";
import styled from "styled-components";
import InstagramSvg from "../svg/InstagramSvg";

const StyledLink = styled.a`
  text-decoration: none;
  margin: 0;
  padding: 0;
`;

export default function IcontInstagram({ color }) {
  return (
    <StyledLink
      href="https://www.instagram.com/grocasa/"
      target="_blank"
      rel="nofollow"
      aria-label='instagram'
    >
      <InstagramSvg />
    </StyledLink>
  );
}
