import React from "react";

function Travel() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="32"
            fill="none"
            viewBox="0 0 44 32"
        >
            <path
                stroke="#FF671B"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 30.125h43M7.785 21.377h10.38c7.274-3.76 14.546-7.513 21.82-11.273.746-.507 2.085-1.585 2.676-3.375.207-.623.512-1.538.08-2.384-.091-.18-.419-.757-1.62-1.235-.959-.38-2.43-.682-4.624-.163-1.028.244-1.838.577-4.544 2.057a150.709 150.709 0 00-4.625 2.635c-5.107-1.918-10.22-3.842-15.327-5.76-1.54.904-3.079 1.814-4.624 2.717 3.326 2.331 6.652 4.663 9.973 6.994-2.27 1.428-4.544 2.85-6.814 4.279-1.867-.933-3.728-1.866-5.595-2.798-1 .77-2 1.539-2.999 2.302 1.948 2.005 3.895 4.005 5.837 6.01l.006-.006z"
            ></path>
        </svg>
    );
}

export default Travel;
