import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const StyledHeader = styled.header`
    position: fixed;
    display: flex;
    min-width: 100%;
    height: 70px;
    z-index: 40;
    transition: all 0.2s;
    &.desktop-menu-open {
        // min-width: calc(100% - 223px);
        padding-left: 225px;
    }
    &.desktop-menu-close {
        //min-width: calc(100% - 75px);
        padding-left: 75px;
    }
    &.phone-menu-close {
        padding: 0;
   }
   &.phone-menu-open {
        min-width: calc(100% - 223px);
        padding-left: 223px;
        @media (max-width: 430px) {
            min-width: 100%;
        }
   }
`
export const StyledNavbarContainer = styled.nav`
    position: relative;
    display: flex;
    padding: 0 20px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    transition: margin-left .3s ease-in-out;
    width: 100%;
    margin: 0;
    border: none;
    min-height: 50px;
    border-radius: 0;    
    /* &.desktop-big {
        padding: 0px 20px 0px 223px;
    }
    &.desktop-small {
        padding: 0px 20px 0px 70px;
    } */
`
export const StyledRight = styled.ul`
    display: flex;
    list-style: none;
    align-items: center;
    li:first-child { margin-right: 20px;}
`
export const StyledProfile = styled.div`
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    width: 160px;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: absolute;
    z-index: 1030;
    font-weight: normal;
    white-space: normal;
    text-align: left;
    cursor: auto;
    user-select: text;
    right: 30px;
    top: 59px;
    padding-top: 10px;
`
export const StyledTop = styled.div`
    position: absolute;
    display: block;
    width: 4.48528137px;
    height: 4.48528137px;
    background: transparent;
    border-style: solid;
    border-width: 4.24264069px;
    transform: rotate(45deg);
    ${(top) => top};
    right: 16px;
    border-top-color: #fff;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: #fff;
    box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
`
export const StyledBody = styled.div`
    background: #fff;
    padding: 20px;
    box-shadow: -2px -2px 5px rgba(0,0,0,0.06);
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            padding: 5px;
            background-color: transparent;
            transition: all 0.3s cubic-bezier(0.215,0.61,0.355,1);
            &:hover { background-color: #f5f6f8; }
            span {
                display: block;
                font-size: 13px;
                color: #595959;
                line-height: 1.1;
                cursor: pointer;
            }
        }
    }
`
export const StyledNotification = styled.div`
    position: absolute;
    background: #fff;
    width: 300px;
    right: 74px;
    top: 59px;
`

export const StyledNotContent = styled.div`
    background-clip: padding-box;
    border-radius: 2px;
    -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
`
export const StyledNotHeader = styled.div`
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 0px;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
        font-size: 14px;
        font-weight: 500;
        color: #323332;
        text-align: center;
        text-transform: uppercase;
        margin: 0;
    }
`
export const StyledNotBody = styled.div`
    width: 100%;
    max-height: 330px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    background-color: #F9F9F9;
    a {
        padding: 15px 30px;
        flex-shrink: 0;
        text-decoration: none;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        text-decoration: none;
        text-align: left;
        border-bottom: 1px solid #ebebeb;
        transition: all 0.3s cubic-bezier(0.215,0.61,0.355,1);
        &:hover {background-color: #f1f1f1;}
        .info {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
            h5 {
                font-size: 13px;
                font-weight: 500;
                color: #323332;
                margin-top: 0;
                margin: 0;
            }
            span {
                font-size: 11px;
                color: #c1c1c1;
                flex-shrink: 0;
            }
        }
        p {
            font-size: 12px;
            font-weight: 400;
            color: #979797;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin: 0;
        }
    }
`
export const StyledNotFooter = styled(Link)`
    font-size: 13px;
    font-weight: 500;
    color: #979797;
    padding: 10px 15px 20px;
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: all 0.3s cubic-bezier(0.215,0.61,0.355,1);
`