import { isEmpty } from "lodash";
import React from "react";

//component
import Layout from "../../components/layout";
import Title from "../../components/ui/title";
import { UseUser } from "../../hooks/context/user";
import Rating from "./Rating";
import ContainerHallFame from "./ContainerHallFame";

function Home() {
  const { user } = UseUser();

  return (
    <Layout>
      <Title text={<span>¡Hola <b>{user.profile.first_name}</b>!</span>} />
      {
        (
          user.menu &&
          user.menu.dashboard &&
          (user.menu.dashboard.news_last_month || user.menu.dashboard.hall_fame_last_month)) &&
        (
          <ContainerHallFame permits={user.menu.dashboard} />
        )
      }
      {
        !isEmpty(user.menu) &&
        !isEmpty(user.menu.dashboard) &&
        !isEmpty(user.menu.dashboard.leagues) &&
        user.menu.dashboard.leagues.length > 0 && (
          <Rating permitions={user.menu.dashboard.leagues} />
        )
      }
    </Layout>
  );
}
export default Home;
