import React, { useEffect, useState } from 'react'

import Layout from "../../components/layout";
import Title from "../../components/ui/title";
import Loading from '../../components/loading';

import { tokenCasafari } from '../../actions';

const Casafari = () => {
    const [token, setToken] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        tokenCasafari()
            .then(res => {
                if (!res.meta.success) {
                    setError(true)
                } else {
                    setToken(res.data.token)
                }
                setLoading(false)
            })
            .catch(() => {
                setToken(null)
                setLoading(false)
                setError(true)
            })
    }, [])

    return (
        <Layout>
            <Title text="Casafari" />
            {
                loading ?
                    <Loading /> :
                    error ?
                        <div className="col-12">
                            <div className="alert alert-danger">No se ha podido obtener el token para acceder a Casafari</div>
                        </div>
                        :
                        <iframe
                            src={`https://www.casafari.com/metasearch?loginToken=${token}`}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            scrolling="no"
                        />
            }
        </Layout>
    )
}

export default Casafari