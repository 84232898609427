import React from "react";
import { Link } from "react-router-dom";
import { useModal } from "../../hooks/context/modal";
import Close from "../icons/close";
import Button from "../ui/button";
import ShowInfoGroLeagues from "../../pages/home/ShowInfoGroLeagues";
import { StyledCard, StyledCardHeader, StyledCardBody, StyledCardActions } from "./styled";
import { backgroundOffices, backgroundAdvisers } from "../../styles/variables";

const textContentOffices = `
  <h2>
    Se trata de una carrera anual donde todas las oficinas compiten entre ellas.
    
  </h2>
  <h2>
    <b>Los puntos se extraen de las firmas confirmadas en el PAD con reserva a partir del 1 de Enero de este año.</b>
  </h2>
  <h2>
      Cada 1.000€ generados corresponde a 1 punto en la clasificación.
  </h2>
  <h2>
      Si tu oficina es la ganadora, recibiréis el mayor reconocimiento de la red en la Convención del año que viene.
  </h2>
  <h2>
      ¡Mucha suerte!
  </h2>`

const textContentAdvisers = `
  <h2>
    Se trata de una carrera anual donde todos los asesores compiten entre ellos, independientemente de su etapa en la carrera.
  </h2>
  <h2>
    <b>Los puntos se extraen de las firmas confirmadas en el PAD con reserva a partir del 1 de Enero de este año.</b>
  </h2>
  <h2>
    Cada 1.000€ generados corresponde a 1 punto en la clasificación.
  </h2>
  <h2>
    Si eres el ganador/a, recogerás tu trofeo en la Convención del año que viene como el Mejor Asesor/a de la red del año.
  </h2>
  <h2>
    ¡Mucha suerte!
  </h2>`


const Card = ({
  isClosed,
  title,
  close,
  children,
  margin,
  heigth,
  textHeader,
  fullweight,
  img,
  background,
  footer,
  type,
  modal
}) => {
  const { setOpen, setComponent } = useModal()

  return (
    <StyledCard margin={margin ? 1 : 0} height={heigth} fullweight={fullweight ? 1 : 0} background={background}>
      <div>
        <StyledCardHeader className={modal ? 'modal-header' : ''}>
          {img ? (
            <div className="card-league-header-container">
              <img className="card-img" src={img} alt="img-card" />
            </div>
          ) : (
            <h1>{title}</h1>
          )}
          {isClosed && <Close close={close} />}
        </StyledCardHeader>
        <StyledCardBody>{children}</StyledCardBody>
      </div>
      {
        footer && (
          <StyledCardActions>
            <Button
              fullweight
              styles='card-gro'
              action={() => {
                setComponent(
                  <ShowInfoGroLeagues
                    img={type === 'properties.offline.teams' ? '/static/leagues/teams.webp' : '/static/leagues/users.webp'}
                    content={type === 'properties.offline.teams' ? textContentOffices : textContentAdvisers}
                    textButton='Ver clasificación'
                    link='/grogames/ligas'
                    background={type === 'properties.offline.teams' ? backgroundOffices : backgroundAdvisers}
                  />
                );
                setOpen(true)
              }}
            >
              Información
            </Button>
            <Link to="/grogames/ligas">
              <Button
                fullweight
                styles='card-gro'
              >
                Ver clasificación
              </Button>
            </Link>
          </StyledCardActions>
        )
      }
    </StyledCard>
  );
};

export default Card;
