import React from "react";

function Email() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="none"
            viewBox="0 0 20 20"
        >
            <g fill="#35374A" fillOpacity="0.35" clipPath="url(#clip0_40_995)">
                <path d="M9.998 1.522c4.676 0 8.48 3.803 8.48 8.477 0 4.675-3.804 8.478-8.48 8.478-4.675 0-8.476-3.802-8.476-8.478 0-4.675 3.802-8.477 8.476-8.477zm0-1.522C4.476 0 0 4.478 0 10c0 5.521 4.476 9.999 9.998 9.999 5.523 0 10.001-4.478 10.001-10 0-5.521-4.477-9.999-10-9.999z"></path>
                <path d="M14.482 8.014a4.185 4.185 0 00-.99-1.414 4.54 4.54 0 00-1.516-.93 5.42 5.42 0 00-1.93-.33c-.701 0-1.392.115-1.99.346a4.596 4.596 0 00-1.547.97 4.24 4.24 0 00-.995 1.47 4.856 4.856 0 00-.347 1.857c0 .674.116 1.295.347 1.863a4.37 4.37 0 00.99 1.485c.43.422.94.749 1.531.98a5.375 5.375 0 001.97.347c.375 0 .766-.04 1.174-.123.408-.081.77-.2 1.083-.357l-.276-.817c-.314.157-.643.271-.99.342a4.945 4.945 0 01-.99.108 4.342 4.342 0 01-1.604-.281 3.625 3.625 0 01-1.23-.786 3.403 3.403 0 01-.79-1.21 4.227 4.227 0 01-.277-1.55c0-.565.094-1.06.281-1.526.187-.466.455-.87.802-1.21.347-.34.762-.603 1.246-.791a4.442 4.442 0 011.613-.281c.592 0 1.108.087 1.588.26.48.174.895.423 1.246.746.35.324.619.71.806 1.159.187.45.281.945.281 1.49 0 .395-.032.732-.097 1.01-.065.28-.161.49-.291.634a.61.61 0 01-.47.214.51.51 0 01-.372-.159c-.106-.105-.159-.287-.159-.545v-3.47h-1.133v.674a1.977 1.977 0 00-.51-.435c-.34-.201-.739-.302-1.194-.302-.456 0-.89.109-1.261.327a2.302 2.302 0 00-.873.914 2.784 2.784 0 00-.317 1.342c0 .503.108.962.322 1.352.214.392.505.698.873.92.367.22.786.331 1.256.331s.874-.1 1.214-.301a1.91 1.91 0 00.62-.59c.062.204.158.377.294.514.249.251.584.377 1.006.377.422 0 .767-.113 1.056-.337.289-.224.512-.55.668-.975.157-.425.235-.93.235-1.515 0-.653-.118-1.252-.352-1.797h-.001zm-3.215 2.89c-.133.244-.312.43-.536.555a1.56 1.56 0 01-.776.189 1.56 1.56 0 01-.792-.199 1.367 1.367 0 01-.53-.556 1.816 1.816 0 01-.189-.857c0-.333.063-.609.189-.847a1.33 1.33 0 01.53-.55c.229-.13.492-.195.792-.195.299 0 .55.063.776.189.224.126.403.306.536.54.132.235.198.523.198.863 0 .34-.066.623-.198.867z"></path>
            </g>
            <defs>
                <clipPath id="clip0_40_995">
                    <path fill="#fff" d="M0 0H20V20H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Email;
