import React from 'react'
import ButtonLink from './ButtonLink'
import styled from 'styled-components'
import { pColor } from '../../styles/variables'

const StyledContainer = styled.div`
    display: flex;
    justify-content: space-between;

    h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
        color: ${pColor};
        margin: 0;
    }

    @media (max-width: 600px) {
        flex-direction: column;

        a {
            width: 100%;
        }

        h2 {
            margin-bottom: 20px;
        }
    }    
`

const TitleAndDownload = ({ text, link, titleButton }) => {    
    return (
        <StyledContainer>
            <h2>{text}</h2>
            <ButtonLink
                href={link}
                target="_blank"
            >
                {titleButton ? titleButton : 'Descargar PDF'}
            </ButtonLink>
        </StyledContainer>
    )
}

export default TitleAndDownload