import React from 'react'
import styled from 'styled-components';

const StyledContainer = styled.div`
    display: flex;
    background: #fff;
    height: 100%;

    .content {
        display: flex;
        flex-direction: column;
        gap: 32px;
        max-width: 300px;
        width: calc(100% - 40px);
        margin: auto;
        padding: 32px 0;

        > div:nth-child(1) {
            display: flex;
            gap: 15px;

            h2 {
                color: #FF671B;
                margin: 0;
                font-size: 16px;
                font-style: normal;
                font-weight: 900;
                line-height: 140%;
            }
        }

        > div:nth-child(2) {
            display: flex;
            padding: 8px;
            border: 1px solid #FF671B;
            background: #FFF;

            a {
                color: #FF671B;
                text-align: center;
                margin: 0;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-decoration: none;
                padding: 8px;                
            }

            &:hover {
                background-color: #FF8A38;
                border: 1px solid #FF8A38;

                a {
                    color: #ffffff;
                }
            }
        }
    }


`

const NewsLastMonth = () => {
    return (
        <StyledContainer>
            <div className='content'>
                <div>
                    <img src="static/home/news-last.svg" alt="news-last" />
                    <h2>¿Sabes qué ha pasado en Grocasa?</h2>
                </div>
                <div>
                    <a href='https://qrco.de/beQo2q' target='_blank'>¡Consulta aquí todas las novedades!</a>
                </div>
            </div>
        </StyledContainer>
    )
}

export default NewsLastMonth