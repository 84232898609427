import React from "react";

function PdfSvg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="32"
            fill="none"
            viewBox="0 0 27 32"
        >
            <path
                stroke="#E54C3C"
                strokeLinejoin="round"
                d="M25.253 11.092v16.467a3.1 3.1 0 01-3.1 3.1H5.01a3.1 3.1 0 01-3.1-3.1V4.442a3.1 3.1 0 013.1-3.1h10.492v6.65c0 1.711 1.389 3.1 3.103 3.1h6.647z"
            ></path>
            <path
                stroke="#E54C3C"
                strokeLinejoin="round"
                d="M25.254 11.092h-6.648a3.101 3.101 0 01-3.102-3.1v-6.65h0l9.75 9.75z"
            ></path>
            <path
                fill="#E54C3C"
                d="M10.056 18.465c-.286-.13-.628-.194-1.027-.194H7.176v4.506h.836v-1.3H9.03c.399 0 .741-.065 1.027-.194.286-.129.505-.313.66-.554a1.55 1.55 0 00.232-.856c0-.33-.077-.615-.232-.856a1.489 1.489 0 00-.66-.553zm-.232 2.068c-.186.157-.464.235-.834.235h-.978V18.98h.978c.369 0 .647.077.834.232.186.154.28.376.28.663 0 .288-.094.503-.28.66zM15.042 18.554c-.37-.189-.799-.282-1.287-.282h-1.97v4.505h1.97c.489 0 .918-.095 1.287-.283.368-.189.656-.451.862-.789.206-.336.31-.73.31-1.18 0-.45-.104-.85-.31-1.185a2.117 2.117 0 00-.862-.785zm.125 2.78a1.396 1.396 0 01-.573.542c-.247.129-.54.193-.878.193h-1.094v-3.09h1.094c.339 0 .631.065.878.193.247.13.438.308.573.538.136.229.203.5.203.814 0 .313-.067.579-.203.81zM20.39 18.973v-.701h-3.288v4.505h.836v-1.725h2.176v-.709h-2.176v-1.37h2.452z"
            ></path>
        </svg>
    );
}

export default PdfSvg;