import React from "react";

function Phone() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="none"
            viewBox="0 0 20 20"
        >
            <g fill="#35374A" fillOpacity="0.35" clipPath="url(#clip0_40_994)">
                <path d="M9.998 1.522c4.676 0 8.48 3.803 8.48 8.477 0 4.675-3.804 8.478-8.48 8.478-4.675 0-8.476-3.802-8.476-8.478 0-4.675 3.802-8.477 8.476-8.477zm0-1.522C4.476 0 0 4.478 0 10c0 5.521 4.476 9.999 9.998 9.999 5.523 0 10.001-4.478 10.001-10 0-5.521-4.477-9.999-10-9.999z"></path>
                <path d="M13.686 15.16c-.636.134-1.224.053-1.839-.107-2.666-.7-4.68-2.28-6.228-4.518-.517-.745-1.02-1.491-1.203-2.4-.227-1.137.05-2.132.88-2.954.34-.336.793-.351 1.226-.404.277-.033.47.092.575.33a95.2 95.2 0 011 2.338c.12.298-.067.545-.23.771-.15.207-.326.4-.502.586-.172.183-.204.365-.072.58.926 1.538 2.208 2.647 3.89 3.289.213.08.377.03.514-.14.28-.341.57-.675.832-1.028.158-.213.349-.301.582-.196.793.36 1.582.726 2.357 1.12.11.057.19.285.189.433-.015 1.149-.833 2.058-1.972 2.298v.002z"></path>
            </g>
            <defs>
                <clipPath id="clip0_40_994">
                    <path fill="#fff" d="M0 0H20V20H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Phone;
