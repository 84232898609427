import React from "react";
import YoutubeSvg from "../svg/YoutubeSvg";
import styled from "styled-components";

const StyledLink = styled.a`
  text-decoration: none;
  margin: 0;
  padding: 0;
`;

export default function IconYoutube({ color, link }) {
  return (
    <StyledLink
      href={link ? link : 'https://www.youtube.com/@grocasa'}
      target="_blank"
      rel="nofollow"
      aria-label='youtube'
    >
      <YoutubeSvg color={color} />
    </StyledLink>
  );
}
