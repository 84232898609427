import React from 'react'
import styled from "styled-components";
import ButtonLink from '../../components/ui/ButtonLink';

const StyledCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 260px;
    width: 100%;
    gap: 8px;
    padding: 20px;
    cursor: pointer;
    border: 1px solid #ffffff;

    img {
        max-width: 100%;
    }

    .title {
        margin: 0;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 140%;
        color: #35374A;
    }

    .text {
        margin: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 9px;
        line-height: 140%;
        color: #35374A;
    }

    > div {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &:hover {
        border: ${({ hoverFalse }) => hoverFalse ? '1px solid #FFFFFF' : '1px solid #FF671B'};
        border-radius: 10px;
    }

    @media (max-width: 600px) {
        max-width: 100%;

        > div > div {
            text-align: center;
        }
        
        a {
            width: 100%;
        }
    }
`

const ItemCard = ({ data, hoverFalse }) => {
    return (
        <StyledCard hoverFalse={hoverFalse}>
            <div>
                <div>
                    <img src={data.image} alt={data.title} />
                </div>
                <p className='title'>
                    {data.title}
                </p>
                {
                    data.text && (
                        <p className='text'>
                            {data.text}
                        </p>
                    )
                }
            </div>
            {
                data.link && (
                    <div>
                        <ButtonLink
                            href={data.link}
                            target="_blank"
                        >
                            Ver tienda
                        </ButtonLink>
                    </div>
                )
            }
        </StyledCard>
    )
}

export default ItemCard