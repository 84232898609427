import React from "react";

function SettingsSvg({ color }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 18 18"
        >
            <path
                stroke={color}
                strokeLinecap="round"
                strokeMiterlimit="10"
                d="M6.198 8.68l-4.587 4.682a2.163 2.163 0 000 3.014 2.058 2.058 0 002.953 0l3.476-3.549"
            ></path>
            <path
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.764 9.96a4.867 4.867 0 001.74-6.237L14.115 6.11l-1.52.408-1.115-1.114.408-1.521 2.386-2.387a4.866 4.866 0 00-6.236 1.74M9.96 9.96l-3.84-3.2M17 14.513l-4.405-4.038c-.748-.687-1.964-.687-2.713 0a1.658 1.658 0 000 2.488L14.287 17 17 14.513zM1.734 1.827L1 2.653S3.452 6.535 3.61 6.76h2.51V3.936C5.92 3.76 2.47 1 2.47 1l-.735.826v.001zM3.24 15.08a.32.32 0 100-.64.32.32 0 000 .64z"
            ></path>
        </svg>
    );
}

export default SettingsSvg;
