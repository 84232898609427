import React from "react";

function YoutubeSvg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="none"
            viewBox="0 0 25 25"
        >
            <g fill="#35374A" fillOpacity="0.35" clipPath="url(#clip0_35_1161)">
                <path d="M12.486 0C5.596 0 0 5.595 0 12.486 0 19.377 5.595 25 12.486 25 19.377 25 25 19.405 25 12.486 25 5.568 19.405 0 12.486 0zm0 23.622c-6.119 0-11.108-4.99-11.108-11.136 0-6.146 4.99-11.108 11.108-11.108 6.12 0 11.136 4.99 11.136 11.108 0 6.12-4.99 11.136-11.136 11.136z"></path>
                <path d="M15.987 7.966h-7.25a2.835 2.835 0 00-2.839 2.839v3.363a2.835 2.835 0 002.84 2.839h7.249a2.835 2.835 0 002.839-2.84v-3.362a2.835 2.835 0 00-2.84-2.84zm-1.434 4.768l-3.252 1.599s-.551 0-.551-.165V10.86c0-.138.44-.248.55-.165l3.115 1.681s.276.276.138.33v.028z"></path>
            </g>
            <defs>
                <clipPath id="clip0_35_1161">
                    <path fill="#fff" d="M0 0H25V25H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default YoutubeSvg;
