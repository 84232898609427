import styled from "styled-components";

export const StyledRatingContainer = styled.div`
  .rating-data-container {
    width: calc(100% - 20px);
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 20px;
    @media (max-width: 1200px) {
      width: 100%;
      grid-template-columns: 100%;
    }
  }
  .rating-card-content {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0px;
    }
    .rating-card-position-content {
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      background-color: #f3f3f3;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid #e9e9e9;
      margin-right: 5px;
      &.gold-color {
        background-color: #efb810;
        border: 1px solid #efb810;
      }
      &.silver-color {
        background-color: #b4b6b9;
        border: 1px solid #b4b6b9;
      }
      &.bronze-color {
        background-color: #cd7f32;
        border: 1px solid #cd7f32;
      }
      &.user-color {
        box-shadow: 0 1px 10px 0 #fe5000;
        animation: mymove 1s infinite;
      }
      h1 {
        margin: 0;
        font-size: 25px;
        color: #788195;
      }
    }
    .rating-card-body-content {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 10px 20px;
      background-color: #f3f3f3;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid #e9e9e9;

      &.position {
        background: #FFD803;
      }

      &.gold-color {
        background-color: #efb810;
        border: 1px solid #efb810;
      }
      &.silver-color {
        background-color: #b4b6b9;
        border: 1px solid #b4b6b9;
      }
      &.bronze-color {
        background-color: #cd7f32;
        border: 1px solid #cd7f32;
      }
      &.user-color {
        box-shadow: 0 1px 10px 0 #ffffff;
        animation: mymove 1s infinite;
      }
      @keyframes mymove {
        from {
          box-shadow: 0 1px 10px 0 #ffffff;
        }
        to {
          box-shadow: 0 1px 5px 0 #ffffff;
        }
      }
      .avatar {
        width: 50px;
        max-height: 50px;
        border-radius: 80%;
        max-width: 100%;
      }
      .text-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;        

        > div {
          display: grid;
          grid-template-columns: 10% 10% auto;
          grid-gap: 10px;
          justify-items: flex-start;
          align-items: center;
          width: 100%;      
          margin-right: 5px;
        }
        
        h1 {
          font-style: normal;
          font-weight: 700;
          font-size: 26px;
          line-height: 32px;      
        }
        h2 {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;          
        }
        h3 {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;          
        }
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;          
        }
        h1,
        h3,
        h2,
        p {
          margin: 0;          
          color: #35374A;          
        }

        @media (max-width: 480px) {
          > div {
            width: auto;
            grid-template-columns: 20% 80%;
            margin-right: 0;

            > div:last-child {
              grid-column-start: 1;
              grid-column-end: 4;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
  .rating-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .rating-card-all-container {
      width: 100%;
      height: 340px;
      overflow-x: auto;
    }
    .raring-card-users-container {
      margin-top: 20px;
      width: 100%;
      .rating-card-content-header {
        padding: 5px 20px;
        color: rgba(0, 0, 0, 0.85);
        border-radius: 2px 2px 0 0;
        border-bottom: 1px solid #e9e9e9;
        background-color: #f3f3f3;
        margin-bottom: 10px;
        h3 {
          margin: 0;
          font-size: 11px;
          color: #788195;
        }
      }
    }
    .not-data {
      padding: 0;
      margin: 0;
      font-size: 20px;      
      text-align: center;
      color: #ffffff;
    }
  }

  .container-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      color: #35374A;
      margin: 0;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
    }

    .top {
      margin-top: 20px;
    }

    .bottom {
      margin-bottom: 20px;
    }
  }
`;

export const StyledFilterContainer = styled.div`
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  margin: ${({ margin }) => margin};
  display: flex;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const StyledButtonContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  @media (max-width: 500px) {
    width: 100%;
    margin: 20px 0px 0px;
    button {
      width: 100%;
    }
  }
`;

export const StyledFilterContent = styled.div`
  display: flex;
  width: 100%;
  .filter-content {
    display: flex;
    width: 50%;
    select {
      width: calc(100% - 20px);
      padding: 4px 10px;
      height: 25px;
      cursor: text;
      text-align: left;
      font-size: 13px;
      line-height: 1.5;
      color: #595959;
      background-color: #fff;
      background-image: none;
      border: 1px solid #e9e9e9;
      outline: none;
      border-radius: 4px;
      margin-bottom: 15px;
      margin: 0 0px 0 20px;
      height: 36px;
      cursor: pointer;
      &:first-child {
        margin: 0;
      }
    }
    margin: 0 0px 0 20px;
    &:first-child {
      margin: 0;
    }
  }
  @media (max-width: 1300px) {
    flex-direction: column;
    .filter-content {
      width: auto;
      margin: 20px 0px 0px 0px;
      select {
        width: 100%;
      }
      @media (max-width: 470px) {
        flex-direction: column;
        select {
          margin: 20px 0px 0px 0px;
        }
      }
    }
  }
`;
