import React from "react";

function EyeSvg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="19"
            fill="none"
            viewBox="0 0 28 19"
        >
            <path
                stroke="#FF671B"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M26.57 9.5c-3.033 5.178-7.728 8.5-13 8.5-5.273 0-9.967-3.324-13-8.5C3.603 4.325 8.3 1 13.57 1c5.27 0 9.967 3.322 13 8.5v0z"
            ></path>
            <path
                stroke="#FF671B"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.571 13.95c2.547 0 4.612-1.992 4.612-4.45 0-2.457-2.065-4.45-4.612-4.45S8.96 7.044 8.96 9.5c0 2.458 2.065 4.45 4.612 4.45z"
            ></path>
        </svg>
    );
}

export default EyeSvg;
