export const MATERIALS_RED = [
    {
        image: '/static/resources/fairs-and-parties/pack-feria.webp',
        title: 'Pack feria',
        text: 'Incluye 2 telas naranjas,    2 mesas altas de 80 cm de diámetro, 4 taburetes, 1 atril, 1 ruleta y 2 rolls up',
        link: 'https://grotienda.myshopify.com/collections/top-ventas/products/pack-feria?_pos=1&_sid=953acff19&_ss=r',
    },
    {
        image: '/static/resources/fairs-and-parties/globos.webp',
        title: 'Globos',
        text: 'Globos Látex Pastel de 28 cm personalizados: 1 cara 1 tinta + varillas blancas completas (150 uds)',
        link: 'https://grotienda.myshopify.com/collections/top-ventas/products/globos-latex-pastel-de-28-cm?_pos=1&_sid=e2ccea74f&_ss=r',
    },
    {
        image: '/static/resources/fairs-and-parties/llavero.webp',
        title: 'Llaveros personalizados',
        text: 'Llaveros de Tela Eco. Contiene 50 uds',
        link: 'https://grotienda.myshopify.com/collections/top-ventas/products/copia-de-llaveros-personalizados-ecofriendly-de-madera?_pos=1&_sid=2690ca687&_ss=r&variant=46365415735643',
    },
    {
        image: '/static/resources/fairs-and-parties/caramelos.webp',
        title: 'Caramelos',
        text: 'Bolsa de 1 Kilo de Caramelos Personalizados Grocasa',
        link: 'https://grotienda.myshopify.com/products/caramelos?_pos=1&_sid=3ed6ad0ab&_ss=r',
    },
]

export const USEFULL_INFORMATION = [
    {
        image: '/static/resources/fairs-and-parties/puzzle-de-madera.webp',
        title: 'Puzzle de madera'
    },
    {
        image: '/static/resources/fairs-and-parties/cucurucho.webp',
        title: 'Cucurucho de chuches'
    },
    {
        image: '/static/resources/fairs-and-parties/pack-de-goma.webp',
        title: 'Pack de gomas de borrar con dinosaurios'
    },
    {
        image: '/static/resources/fairs-and-parties/estuche.webp',
        title: 'Estuche para colorear'
    },
]