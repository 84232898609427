import React from 'react'
import { Link } from "react-router-dom";
//COMPONENTS
import Button from '../../../components/ui/button'

const TrainingPlan = () => {
    return (
        <>
            <h2>
                Conviértete en la mejor versión de ti a través del <b>Plan de Formación</b> que ofrece Grocasa.
            </h2>
            <h2 className='top bottom'>
                Nuestros formadores guiarán tu desarrollo profesional para ayudarte a alcanzar tus metas profesionales.
            </h2>
            <div className='top'>
                <Link to='/documentos/plan-formacion'>
                    <Button>
                        Conoce el plan de formación
                    </Button>
                </Link>
            </div>
        </>
    )
}

export default TrainingPlan