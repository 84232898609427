import React from 'react'
import styled from "styled-components";
import CardOther from '../../components/cardOther';
import ButtonLink from '../../components/ui/ButtonLink';

const StyledContainer = styled.div`    
    display: flex;    
    margin: 40px 0 0;

    .third-content {
        display: flex;
        flex-direction: column;
        width: 100%;        
        gap: 10px;
    }

    p {
        margin: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #35374A;

        span {
            font-weight: bold;
        }

        a {
            text-decoration: underline;
            color: #35374A;
        }
    }   
    
    @media (max-width: 600px) {
        a {
            width: 100%;
        }
    }
`

const ThirdRow = () => {
    return (
        <StyledContainer>
            <CardOther title='¿Cómo puedo acceder al Pack feria?'>
                <div className='third-content'>
                    <p>
                        Para alquilar el 'PACK FERIA', sigue estos pasos:
                    </p>
                    <p>
                        <span>1. </span>Contacta con nuestro departamento de marketing o escribe a redmarketing@grocasa.com para verificar la disponibilidad de los materiales.
                    </p>
                    <p>
                        <span>2. </span>Tras la confirmación, recibirás un correo con un contrato de cesión de material. Firma y devuelve este contrato y/o accede en el siguiente enlace para descargarlo.
                    </p>
                    <div style={{ margin: '10px 0' }}>
                        <ButtonLink
                            href='/static/resources/docs/CONTRATO_DE_CESION_DE_USO_DE_MATERIAL.docx'
                            download
                        >
                            Descargar contrato
                        </ButtonLink>
                    </div>
                    <p>
                        <span>3. </span>Finalmente, accede a la grotienda y procede al alquiler del "Pack feria".
                    </p>
                    <div style={{ width: '100%', overflow: 'auto' }}>
                        <p>Puedes hacerlo siguiendo este enlace: <a href='https://grotienda.myshopify.com/collections/herramientas-de-venta/products/pack-feria' target='_blank'>https://grotienda.myshopify.com/collections/herramientas-de-venta/products/pack-feria</a></p>
                    </div>
                </div>
            </CardOther>
        </StyledContainer>
    )
}

export default ThirdRow