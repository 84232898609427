import Bowser from "bowser";
import { sendOnsignal } from "../../actions";

let init = false;
export const apiURL = () => {
  return process.env.REACT_APP_API_URL;
};

export const oneSignal = () => {
  const oneSignal = window.OneSignal || [];
  oneSignal.push(function () {
    if (!init) {
      oneSignal.init({ appId: process.env.REACT_APP_ONESIGNAL_APP_ID });
      oneSignal.isPushNotificationsEnabled(function (isEnabled) {
        if (!isEnabled) {
          oneSignal.on("subscriptionChange", function (isSubscribed) {
            if (isSubscribed) {
              oneSignal.getUserId().then(function (user_id) {
                let userLang = navigator.language || navigator.userLanguage;
                let split = userLang.split("-");
                let language_code = split[0];
                let country = split[1];
                const browser = Bowser.getParser(window.navigator.userAgent);
                sendOnsignal({
                  user_id,
                  country,
                  language_code,
                  device: browser.getBrowser(),
                });
              });
            }
          });
        }
      });
      init = true;
    }
  });
};

export const provinces = [
  "Álava",
  "Albacete",
  "Alicante/Alacant",
  "Almería",
  "Asturias",
  "Ávila",
  "Badajoz",
  "Baleares",
  "Barcelona",
  "Burgos",
  "Cáceres",
  "Cádiz",
  "Cantabria",
  "Castellón/Castelló",
  "Ceuta",
  "Ciudad Real",
  "Córdoba",
  "Cuenca",
  "Girona",
  "Granada",
  "Guadalajara",
  "Guipúzcoa",
  "Huelva",
  "Huesca",
  "Jaén",
  "La Coruña",
  "La Rioja",
  "Las Palmas",
  "León",
  "Lleida",
  "Lugo",
  "Madrid",
  "Málaga",
  "Melilla",
  "Murcia",
  "Navarra",
  "Ourense",
  "Palencia",
  "Pontevedra",
  "Salamanca",
  "Santa Cruz de Tenerife",
  "Segovia",
  "Sevilla",
  "Soria",
  "Tarragona",
  "Teruel",
  "Toledo",
  "Valencia",
  "Valladolid",
  "Vizcaya",
  "Zamora",
  "Zaragoza",
];

export const formatShowPhone = (phone) => {
  return phone.replace(/\W/gi, '').replace(/(.{3})/g, '$1 ').replace(/\s$/, '')
}

export const formatDateSend = (value) => {
  let date = new Date(value)
  return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
}

export const convertToSlug = (str) => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  let from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;";
  let to = "AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------";

  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}
