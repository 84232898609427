import React, { useEffect, useState } from 'react'
//ACTIONS
import {
    getGroagendaTeamTypesAction,
    getGroagendaTeamTypesTeamAction,
    getGroagendaTeamTypesUsersAction,
    groagendaTeamTypesUserAction
} from '../../actions';
//COMPONENTS
import Layout from '../../components/layout'
import Title from '../../components/ui/title'
import Loading from '../../components/loading';
import Filters from './Filters';
//STATE
import { stateInitial } from './state';
import Team from './Team';
import Users from './Users';

const GroAgenda = () => {
    const [state, setState] = useState(stateInitial);

    useEffect(() => {
        getTeamsTypes()

    }, []);

    async function getTeamsTypes() {
        const res = await getGroagendaTeamTypesAction();
        if (res) {
            if (res.types && res.types.length) {
                setState(prev => ({ ...prev, dataTeamsTypes: res.types }))
            }
        }
        setState(prev => ({ ...prev, loading: false }))
    }

    async function getTeamsTypesTeams(typeTeam) {
        const res = await getGroagendaTeamTypesTeamAction(typeTeam);
        if (res) {
            if (res.teams && res.teams.length) {
                setState(prev => ({ ...prev, dataTeamsTypeTeam: res.teams }))
            }
        }
        setState(prev => ({ ...prev, loadingTeamsTypeTeam: false }))
    }

    async function getGroagendaTeamTypesUsers(idTeam) {
        const res = await getGroagendaTeamTypesUsersAction(idTeam);
        if (res) {
            setState(prev => ({ ...prev, data: res }))
        }
        setState(prev => ({ ...prev, loading: false }))
    }

    async function groagendaTeamTypesUser(data) {
        const res = await groagendaTeamTypesUserAction(data);
        if (res) {
            setState(prev => ({ ...prev, data: res }))
        }
        setState(prev => ({ ...prev, loadingSearchInput: false }))
    }

    const handleOnchangeSelect = (e) => {
        let { name, value } = e.target
        if (value) {
            if (name === 'teamsType') {
                setState(prev => ({
                    ...prev,
                    teamsTypes: value,
                    teamsTypeTeam: '',
                    searchInput: '',
                    data: null,
                    loadingTeamsTypeTeam: true,
                    dataTeamsTypeTeam: [],
                }))
                getTeamsTypesTeams(value)
            } else {
                setState(prev => ({ ...prev, teamsTypeTeam: value, loading: true }))
                getGroagendaTeamTypesUsers(value)
            }
        }
    }

    const handleEventInput = (e) => {
        let { value } = e.target
        setState(prev => ({ ...prev, searchInput: value }))
        if (value.length >= 3) {
            setState(prev => ({ ...prev, loadingSearchInput: true, teamsTypes: '', teamsTypeTeam: '', dataTeamsTypeTeam: [] }))
            groagendaTeamTypesUser({ text: value })
        } else {
            setState(prev => ({ ...prev, data: null, teamsTypes: '', teamsTypeTeam: '', dataTeamsTypeTeam: [] }))
        }
    }

    return (
        <Layout>
            <Title text={<span><b>Groagenda</b></span>} />
            {
                state.loading ?
                    <Loading />
                    :
                    <>
                        <Filters
                            state={state}
                            handleOnchangeSelect={handleOnchangeSelect}
                            handleEventInput={handleEventInput}
                        />
                        {
                            (state.data && state.data.team) ?
                                <Team data={state.data.team} /> :
                                null
                        }
                        {
                            (state.data && state.data.users && state.data.users.length) ? <Users data={state.data.users} /> : null
                        }
                    </>
            }

        </Layout>
    )
}

export default GroAgenda