import React from "react";

function MarketPlaceSvg({color}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            fill="none"
            viewBox="0 0 17 17"
        >
            <g
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                clipPath="url(#clip0_1182_11002)"
            >
                <path d="M10.02 4.72l3.64-3.64s.07-.03.1 0l2.06 2.06s.03.07 0 .1l-3.74 3.74"></path>
                <path d="M6.98 6.98V.57S7.01.5 7.05.5h2.91s.07.03.07.07v6.4"></path>
                <path d="M10.02 6.97h6.41s.07.03.07.07v2.91s-.03.07-.07.07h-6.42M4.71 6.98L1.08 3.36s-.03-.07 0-.1L3.13 1.2s.07-.03.1 0l3.75 3.74M12.05 10.02l3.75 3.74s.03.07 0 .1l-2.06 2.06s-.07.03-.1 0l-3.63-3.63"></path>
                <path
                    fill={color}
                    d="M10.05 16.45s-.03.05-.07.05H7.07S7 16.47 7 16.43v-4.15l-3.64 3.64s-.07.03-.1 0L1.2 13.86s-.03-.07 0-.1l3.73-3.73h.01l-4.38-.01c-.04 0-.07-.03-.07-.07V7.06s.03-.07.07-.07h9.45l.03 9.45.01.01z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_1182_11002">
                    <path fill="#fff" d="M0 0H17V17H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default MarketPlaceSvg;
