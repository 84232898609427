import React from 'react'
import {CheckCircle} from '@styled-icons/boxicons-regular/CheckCircle'
import styled from 'styled-components'

const StyledCheck = styled(CheckCircle)`
    color: #2ea44f;
    width: 24px;
    height: 24px;
    cursor: pointer;
`
export default function Done () { return <StyledCheck /> }