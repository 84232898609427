import React from 'react'
import {ErrorCircle} from '@styled-icons/boxicons-regular/ErrorCircle'
import styled from 'styled-components'

const StyledError = styled(ErrorCircle)`
    color: #ff0033;
    width: 24px;
    height: 24px;
    cursor: pointer;
`
export default function NotDone () { return <StyledError /> }