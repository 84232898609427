import React from 'react'
import styled from "styled-components";
import CardOther from '../../components/cardOther';
import { MATERIALS_RED } from './dataCards';
import ItemCard from './ItemCard';

const StyledContainer = styled.div`
    display: flex;
    margin: 20px 0 0;

    .content-row {
        display: flex;
        flex-direction: column;
        width: 100%;

        > p {
            margin: 0;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: #35374A;
        }
    }
`

const StyledContentCard = styled.div`
    display: flex;
    flex-wrap: wrap;
    //gap: 8px;
    margin: 20px 0 0;

    @media (max-width: 600px) {
        flex-direction: column;
    }
`

const SecondRow = () => {
    return (
        <StyledContainer>
            <CardOther title='¿Qué materiales te puede facilitar la RED?'>
                <div className='content-row'>
                    <p>
                        En nuestra grotienda te facilitamos una selección de productos en alquiler para que tu stand sea el centro de atención.
                    </p>
                    <StyledContentCard>
                        {
                            MATERIALS_RED.map((item, key) => {
                                return (
                                    <ItemCard key={key} data={item} />
                                )
                            })
                        }

                    </StyledContentCard>
                </div>
            </CardOther>
        </StyledContainer>
    )
}

export default SecondRow