import React from "react";

function Add() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
        >
            <path stroke="#FF671B" strokeWidth="2.5" d="M0 10h20M10 20V0"></path>
        </svg>
    );
}

export default Add;
