import React from 'react'
import styled from "styled-components";
import CardOther from '../../components/cardOther';

const StyledContainer = styled.div`    
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 16px;
    margin: 40px 0 0;

    p {
        margin: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #35374A;
    }

    @media (max-width: 950px) {
        grid-template-columns: 1fr;
    }
`

const FirstRow = () => {
    return (
        <StyledContainer>
            <CardOther title='Objetivo'>
                <p>
                    Utilizar las ferias y fiestas de barrio como una plataforma para generar red de contactos locales, tanto con 
                    potenciales vendedores como compradores de inmuebles. A través de la interacción directa en estas ferias, se 
                    busca identificar y captar nuevos inmuebles, fortaleciendo así su presencia en el barrio.
                </p>
            </CardOther>
            <CardOther title='¿Cómo participar?'>
                <p>
                    Deberás de ponerte en contacto con los organizadores con antelación, ya que las plazas suelen ser limitadas.
                </p>
            </CardOther>
        </StyledContainer>
    )
}

export default FirstRow