import React from 'react'
import styled from 'styled-components'
import ButtonLink from '../../components/ui/ButtonLink';

const StyledCard = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);    
    background: #FDFDFD;

    img {
        max-width: 100%;
    }

    .header {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        > div:nth-child(1) {
            display: flex;
            justify-content: center;
            align-items: center;
            background: ${({ background }) => background};

            img {
                padding: 5px;
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        padding: 20px;

        p, a {
            color: #35374A;
            margin: 0;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
        }

        .font-600 {
          font-weight: 600;
        }

        a {
            text-decoration: underline;
            font-weight: 700;
        }
        

        .margin-y {
            margin: 10px 0;
        }

        .margin-top {
            margin-top: 10px;
        }

        .text {
            max-height: 0;
            overflow-y: hidden;
            transition: max-height 0.5s ease-in-out;       

            &.active {
                // overflow-y: auto;
                transition: max-height 1s ease-in-out;    
                max-height: 1000vh;
            }
        }
    }

    .see-more {
        display: flex;
        margin-top: 24px;
        justify-content: flex-end;
        cursor: pointer;

        p {
            color: #FF671B;
            margin: 0;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            text-decoration-line: underline;
        }
    }

    .footer {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        border-top: 2px solid #EDEDED;
    }    
`

const Cards = ({ data, number, openCollapse, linkPintorist }) => {

  return (
    <StyledCard background={data.background}>
      <div className='header'>
        <div><img src={data.logo} alt={`${data.id}-logo`} /></div>
        <div>
          <img src={data.img} alt={data.id} />
        </div>
      </div>
      <div className='content'>
        <div dangerouslySetInnerHTML={{ __html: data.textShow }} />
        <div
          className={'text ' + (data.active ? 'active' : '')}
          dangerouslySetInnerHTML={{ __html: data.textHide }}
        />
        <div className='see-more' onClick={() => openCollapse(number)}>
          <p>{!data.active ? 'Ver más' : 'Ver menos'}</p>
        </div>
      </div>
      <div className='footer'>
        <ButtonLink
          href={data.id === 'pintorist' ? linkPintorist : data.link}
          target="_blank"
        >
          Envio cliente
        </ButtonLink>
      </div>
    </StyledCard>
  )
}

export default Cards