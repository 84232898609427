import React from 'react'
import styled from "styled-components";

const StyledContainer = styled.div`    
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #FDFDFD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

    .header {
        display: flex;
        padding: 30px 20px;
        
        p {
            margin: 0;
            font-weight: 700;
            font-size: 18px;
            line-height: 140%;
            color: #35374A;
        }
    }

    .content {
        display: flex;
        padding: 20px;
    }

    hr {    
        width: 100%;
        border: none;
        height: 1px;  
        color: rgba(147, 147, 149, 0.80);
        background: rgba(0, 0, 0, 0.07);        
        margin: 0;
    }

`

const CardOther = ({ children, title }) => {
    return (
        <StyledContainer>
            <div className='header'>
                <p>{title}</p>
            </div>
            <hr />
            <div className='content'>
                {children}
            </div>
        </StyledContainer>
    )
}

export default CardOther