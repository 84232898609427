import React from "react";

function GroAcademySvg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="none"
            viewBox="0 0 22 18"
        >
            <path
                stroke="#35374A"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="0.9"
                d="M6.673 15.596H20.07c.411 0 .745-.334.745-.744V1.745A.745.745 0 0020.071 1H3.02a.745.745 0 00-.745.745v10.153M19.29 2.616H2.274"
            ></path>
            <path
                fill="#FF671B"
                d="M.746 13.372v1.294c0 .017.013.03.03.03h1.95l-1.667 1.667a.03.03 0 000 .043l.916.916a.03.03 0 00.043 0l1.62-1.622v1.847c0 .017.014.03.032.03h1.294a.03.03 0 00.03-.03V13.37a.03.03 0 00-.03-.03H.776a.03.03 0 00-.03.03M12.186 8.61l-.024-.255h-3.1v1.18h1.72a1.086 1.086 0 01-.116.463c-.08.158-.192.294-.335.411a1.56 1.56 0 01-.498.269 2.157 2.157 0 01-2.134-.524 2.083 2.083 0 01-.459-.681 2.098 2.098 0 01-.167-.836c0-.297.053-.582.157-.841a1.98 1.98 0 01.445-.671 2.091 2.091 0 011.493-.605c.21 0 .417.03.617.09.201.06.39.145.57.255.178.111.35.245.517.402l.89-.926a2.71 2.71 0 00-.67-.567 3.671 3.671 0 00-1.915-.529c-.516 0-.991.085-1.425.255-.434.17-.812.408-1.134.714a3.191 3.191 0 00-.745 1.077 3.394 3.394 0 00-.263 1.346c0 .484.084.935.253 1.35.169.416.41.779.723 1.087.312.309.684.55 1.114.723.43.174.904.26 1.421.26.44 0 .848-.07 1.225-.212.376-.142.704-.34.981-.6.277-.258.494-.567.65-.925.157-.36.236-.753.236-1.18a6.377 6.377 0 00-.024-.53h-.003zM17.288 11.964h.52l-2.44-6.41h-.63l-2.468 6.41h.482l.737-1.932h3.066l.732 1.932h.001zm-3.624-2.39l.758-1.987c.074-.195.14-.367.195-.517.055-.15.11-.293.162-.43a13.112 13.112 0 00.252-.72 22.716 22.716 0 00.235.688c.05.134.1.274.153.421.053.147.116.315.19.504l.774 2.042h-2.718l-.001-.001z"
            ></path>
        </svg>
    );
}

export default GroAcademySvg;
