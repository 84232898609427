import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UseUser } from "../../hooks/context/user";
import es from 'date-fns/locale/es';
//components
import RefreshPage from "../../components/icons/refresh";
import Button from "../../components/ui/button";
import LoadingSmall from "../../components/loadingSmall";

//styles
import {
  StyledContainer,
  StyledButtonContent,
  StyledFilterContainer,
  StyledOthersFilterContainer
} from "./styled";
import { isEmpty } from "lodash";
import Input from "../../components/ui/input";

registerLocale('es', es);

const SectionHeader = ({
  filters,
  filtersValue,
  setFilters,
  filtersOthersValue,
  setFiltersOthers,
  modal,
  refresh,
  lookFor,
  errorFiltersOthers,
  setErrorFiltersOthers
}) => {
  const { user } = UseUser();
  const [selectOption, setSelectOption] = useState({
    types: false,
    opportunity_types: false,
    teams: false,
    users: false,
    provenances: false,
  });

  const handleOnChange = async (e) => {
    const { name, value } = e.target;
    if (name === "type") {
      setSelectOption({
        ...selectOption,
        opportunity_types: true,
        teams: true,
        users: true,
        provenances: true,
      });
      setFilters({
        ...filtersValue,
        [name]: value,
        opportunity_type: "",
        team: "",
        user: "",
        provenance: "",
      });
    } else if (name === "opportunity_type") {
      setSelectOption({
        ...selectOption,
        teams: true,
        users: true,
        provenances: true,
      });
      setFilters({
        ...filtersValue,
        [name]: value,
        team: "",
        user: "",
        provenance: "",
      });
    } else if (name === "team") {
      setSelectOption({ ...selectOption, users: true, provenances: true });
      setFilters({ ...filtersValue, [name]: value, user: "", provenance: "" });
    } else if (name === "users") {
      setSelectOption({ ...selectOption, provenances: true });
      setFilters({ ...filtersValue, [name]: value, provenance: "" });
    } else {
      setFilters({ ...filtersValue, [name]: value });
    }

    setTimeout(() => {
      setSelectOption({
        types: false,
        opportunity_types: false,
        teams: false,
        users: false,
        provenances: false,
      });
    }, 1000);
  };

  return (
    <StyledContainer margin="0px 0px 20px 0px">
      <StyledButtonContent size="20px">
        <Button action={refresh}>
          <RefreshPage />
        </Button>
        {!isEmpty(user.menu) &&
          !isEmpty(user.menu.leads) &&
          user.menu.leads.length > 0 && (
            <>
              {user.menu.leads.map((item, index) => {
                return item === "lead.add" ? (
                  <Button action={modal} key={index}>
                    Añadir nuevo
                  </Button>
                ) : (
                  ""
                );
              })}
            </>
          )}
      </StyledButtonContent>
      <StyledFilterContainer>
        <div className="filter-content left">
          {filters.types && (
            <Filters
              title="Todos los tipos"
              items={filters.types}
              loading={selectOption.types}
              name="type"
              onChange={handleOnChange}
            />
          )}
          {filters.opportunity_types && (
            <Filters
              title="Todas las oportunidades"
              items={filters.opportunity_types}
              loading={selectOption.opportunity_types}
              name="opportunity_type"
              onChange={handleOnChange}
            />
          )}
        </div>
        <div className="filter-content right">
          {filters.teams && (
            <Filters
              title="Todos los equipos"
              items={filters.teams}
              name="team"
              loading={selectOption.teams}
              onChange={handleOnChange}
            />
          )}
          {filters.users && (
            <Filters
              title="Todos los usuarios"
              items={filters.users}
              loading={selectOption.users}
              name="user"
              onChange={handleOnChange}
            />
          )}
          {filters.provenances && (
            <Filters
              title="Todas la procedencias"
              items={filters.provenances}
              loading={selectOption.provenances}
              name="provenance"
              onChange={handleOnChange}
            />
          )}
        </div>
      </StyledFilterContainer>
      <StyledOthersFilterContainer>
        <div>
          <Input
            type="number"
            name="phone"
            value={filtersOthersValue.phone}
            onChange={(e) => setFiltersOthers({ ...filtersOthersValue, phone: e.target.value })}
            placeholder='Teléfono'
            error={errorFiltersOthers.phone ? true : false}
          />
          {errorFiltersOthers.phone && <p className="error-message">{errorFiltersOthers.phone}</p>}
        </div>
        <div>
          <Input
            type="text"
            name="email"
            value={filtersOthersValue.email}
            onChange={(e) => setFiltersOthers({ ...filtersOthersValue, email: e.target.value })}
            placeholder='Email'
            error={errorFiltersOthers.email ? true : false}
          />
          {errorFiltersOthers.email && <p className="error-message">{errorFiltersOthers.email}</p>}
        </div>
        <div>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            locale="es"
            selected={filtersOthersValue.from}
            onChange={(date) => {
              setFiltersOthers({ ...filtersOthersValue, from: date });
              setErrorFiltersOthers({ ...errorFiltersOthers, from: '' })
            }}
            className={errorFiltersOthers.from ? "red-border" : ""}
            isClearable={true}
            placeholderText={"Fecha inicio"}
          />
          {errorFiltersOthers.from && <p className="error-message">{errorFiltersOthers.from}</p>}
        </div>
        <div>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            locale="es"
            selected={filtersOthersValue.to}
            onChange={(date) => {
              setFiltersOthers({ ...filtersOthersValue, to: date });
              setErrorFiltersOthers({ ...errorFiltersOthers, to: '' })
            }}
            className={errorFiltersOthers.to ? "red-border" : ""}
            isClearable={true}
            placeholderText={"Fecha fin"}
          />
          {errorFiltersOthers.to && <p className="error-message">{errorFiltersOthers.to}</p>}
        </div>
        <div>
          <Button action={() => lookFor()} fullweight disabled={selectOption.provenances ? true : false}>
            Buscar
          </Button>
        </div>
      </StyledOthersFilterContainer>
    </StyledContainer>
  );
};

export default SectionHeader;

const Filters = ({ items, name, onChange, title, loading }) => {
  return loading ? (
    <LoadingSmall />
  ) : (
    <select onChange={onChange} name={name}>
      <option value="">{title}</option>
      {items.map((item, index) => (
        <option key={index} value={item.id}>
          {item.name}
        </option>
      ))}
    </select>
  );
};
