import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import { useAlert } from "../../hooks/context/alert";
import { pColor, sColor } from "../../styles/variables";
import 'bootstrap/dist/css/bootstrap.min.css';
//components
import Title from "../../components/ui/title";
import Loading from "../../components/loading";
//styles
import NotData from "../../components/notData";
import { getApiDispatcher, updateApiDispatcherAdviser } from "../../actions";

function AdvisersMortgage({ type, typeName }) {
    const [render, setRender] = useState(false)
    const [advisers, setAdvisers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [widthScreen, setWithScreen] = useState(window.screen.width);
    const { openAlert } = useAlert();

    const getAdvisers = async () => {
        setLoading(true);
        const data = await getApiDispatcher({ type, option: "users" });
        setAdvisers(validateTeams(data));
        setLoading(false);
    };

    const update = async (active, id) => {
        setLoading(true);
        await updateApiDispatcherAdviser({ type, id, active }, openAlert);
        setLoading(false);
    };

    useEffect(() => {
        getAdvisers();
    }, [type]);

    const validateTeams = (data) => {
        let dataTeam = [];
        if (data && data.length) {
            data.map(team => {
                let showTeam = 0;
                team.types.map(type => {
                    if (type.users.length) {
                        showTeam++;
                    }
                })
                if (showTeam) {
                    dataTeam.push(team);
                }
            })
        }
        return dataTeam;
    }

    const validate = (data) => {
        let acum = "";
        if (data.value === '' && data.execute) {
            openAlert(
                "error",
                "Hipotecas",
                "El número de leads no puede estar vacío"
            );
            acum = 1;
        }
        if (data.value && data.value > 100) {
            openAlert(
                "error",
                "Hipotecas",
                "El número de leads no puede ser mayor a 100"
            );
            acum = 1;
        }
        if (data.name === 'active' &&
            data.value &&
            advisers[data.keyTeam].types[data.keyType].users[data.keyUser]['dispatcher']['number_leads'] === 0
        ) {
            openAlert(
                "error",
                "Hipotecas",
                "Un asesor no puede estar activado y el número de leads 0"
            );
            acum = 1;
        }
        if (data.name === 'active' &&
            !data.value &&
            advisers[data.keyTeam].types[data.keyType].users[data.keyUser]['dispatcher']['number_leads'] !== 0 &&
            advisers[data.keyTeam].types[data.keyType].users.filter(user => user.dispatcher.active).length === 1
        ) {
            openAlert(
                "error",
                "Hipotecas",
                "Debe haber al menos un usuario activo"
            );
            acum = 1;
        }
        if (data.name === 'number_leads' &&
            data.value === 0 &&
            advisers[data.keyTeam].types[data.keyType].users[data.keyUser]['dispatcher']['active']
        ) {
            openAlert(
                "error",
                "Hipotecas",
                "Un asesor no puede estar activado y el número de leads 0"
            );
            acum = 1;
        }
        if (data.name === 'unlimited_leads' &&
            !data.value &&
            advisers[data.keyTeam].types[data.keyType].users.filter(user => user.dispatcher.unlimited_leads).length === 1
        ) {
            openAlert(
                "error",
                "Hipotecas",
                "Debe haber al menos un usuario con leads ilimitados"
            );
            acum = 1;
        }
        if (acum > 0) {
            return false;
        }
        return true;

    }



    const updateData = (data) => {        
        const isValid = validate(data);
        if (isValid) {
            let newData = advisers;
            newData[data.keyTeam].types[data.keyType].users[data.keyUser]['dispatcher'][data.name] = data.value
            setRender(!render)
            setAdvisers(newData)
            if (data.execute) {
                update(
                    {
                        "type": data.type,
                        "active": data.name === 'active' ? data.value :
                            newData[data.keyTeam].types[data.keyType].users[data.keyUser]['dispatcher']['active'],
                        "number_leads": data.name === 'number_leads' ? data.value :
                            newData[data.keyTeam].types[data.keyType].users[data.keyUser]['dispatcher']['number_leads'],
                        "unlimited_leads": data.name === 'unlimited_leads' ? data.value :
                            newData[data.keyTeam].types[data.keyType].users[data.keyUser]['dispatcher']['unlimited_leads']
                    }, data.id
                )
            }
        }
    }

    window.addEventListener("resize", () => {
        setWithScreen(window.innerWidth);
    });

    return (
        <>
            <Title text={`Asesores ${typeName} online`} />
            {loading && <Loading />}
            {advisers && !advisers.length ? (
                <NotData />
            ) : (
                <>
                    {
                        advisers.map((item, key) => {
                            return (
                                <div className="container-fluid" key={key}>
                                    <div
                                        className="row"
                                        style={{ backgroundColor: '#fff', border: '1px solid rgba(0,0,0,.125)' }}
                                    >
                                        <div
                                            className=" col-12 p-3"
                                            style={{ backgroundColor: '#00000008' }}
                                        >
                                            {item.name}
                                        </div>
                                    </div>
                                    <div
                                        className="row mb-3 border-top-0"
                                        style={{ backgroundColor: '#fff', border: '1px solid rgba(0,0,0,.125)' }}
                                    >
                                        {
                                            item.types.map((type, keyType) => {
                                                return (
                                                    <TeamTypes
                                                        team={item}
                                                        type={type}
                                                        key={keyType}
                                                        keyTeam={key}
                                                        keyType={keyType}
                                                        updateData={updateData}
                                                        loading={loading}
                                                        widthScreen={widthScreen}
                                                    />
                                                )
                                            })
                                        }


                                    </div>
                                </div>
                            );
                        })
                    }
                </>
            )}
        </>
    );
}

export default AdvisersMortgage;

const TeamTypes = ({ team, type, keyTeam, keyType, updateData, loading, widthScreen }) => (

    <div className={"col-12 p-2 " + (widthScreen > 1600 ? "col-lg-4" : "col-lg-6")}>
        <div className="card">
            <div className="card-header">
                Procedencia: {type.name}
            </div>
            <div className="card-body">
                <TeamUsers
                    team={team}
                    type={type}
                    users={type.users}
                    updateData={updateData}
                    keyTeam={keyTeam}
                    keyType={keyType}
                    loading={loading}
                />
            </div>
        </div>
    </div>
)

const TeamUsers = ({ team, type, users, updateData, keyTeam, keyType, loading }) => {

    const handleOnchange = (data) => (e) => {
        updateData({ ...data, value: e });

    };

    const handleOnchangeInput = (e, data) => {
        let value = e.target.value ? parseInt(e.target.value) : e.target.value;        
        updateData({ ...data, value: value });        
    }

    return (
        <div className="table-responsive">
            <table className="table table-bordered">
                <thead style={{ backgroundColor: '#00000008', border: '1px solid rgba(0,0,0,.125)' }}>
                    <tr>
                        <th scope="col">Nombre</th>
                        <th scope="col">Activo</th>
                        <th scope="col">Número leads</th>
                        <th scope="col">Leads Ilimitados</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        users.map((user, key) => {
                            return (
                                <tr key={key}>
                                    <td style={{ verticalAlign: 'middle' }}>{user.first_name} {user.last_name}</td>
                                    <td style={{ verticalAlign: 'middle' }}>
                                        <Switch
                                            onChange={handleOnchange({
                                                execute: true,
                                                team: team.name,
                                                type: type.id,
                                                id: user.id,
                                                name: 'active',
                                                keyTeam: keyTeam,
                                                keyType: keyType,
                                                keyUser: key
                                            })}
                                            checked={user.dispatcher.active}
                                            onColor={pColor}
                                            offColor={sColor}
                                            disabled={loading}
                                        />
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }}>
                                        <input
                                            value={user.dispatcher.number_leads}
                                            type="number"
                                            inputMode="numeric"
                                            pattern="\d*"
                                            className="form-control"
                                            onChange={(e) => handleOnchangeInput(e, {
                                                execute: false,
                                                team: team.name,
                                                type: type.id,
                                                id: user.id,
                                                name: 'number_leads',
                                                keyTeam: keyTeam,
                                                keyType: keyType,
                                                keyUser: key
                                            })}
                                            onBlur={(e) => handleOnchangeInput(e, {
                                                execute: true,
                                                team: team.name,
                                                type: type.id,
                                                id: user.id,
                                                name: 'number_leads',
                                                keyTeam: keyTeam,
                                                keyType: keyType,
                                                keyUser: key
                                            })}
                                            min={0}
                                            max={100}
                                            name={`numberLeads_${key}`}
                                            disabled={loading}
                                        />
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }}>
                                        <Switch
                                            onChange={handleOnchange({
                                                execute: true,
                                                team: team.name,
                                                type: type.id,
                                                id: user.id,
                                                name: 'unlimited_leads',
                                                keyTeam: keyTeam,
                                                keyType: keyType,
                                                keyUser: key
                                            })}
                                            checked={user.dispatcher.unlimited_leads}
                                            onColor={pColor}
                                            offColor={sColor}
                                            disabled={loading}
                                        />
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}